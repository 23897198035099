<template>
	<div :class="containerClass" @click="onWrapperClick">
        <div ref="topbar" class="topbar">
            <AppTopBar @menu-toggle="onMenuToggle" :layoutMode="layoutMode" @layout-change="onLayoutChange" />
            <div v-if="PAGE?.notification?.visible" class="notification-workspace" :class="{ 
                'notification-warning': PAGE.notification.status=='warning',
                'notification-error': PAGE.notification.status=='error', 
                'notification-critical': PAGE.notification.status=='critical', 
            }">
                {{ PAGE.notification.header }} {{ PAGE.notification.text }}
            </div>
        </div>
        <div class="layout-sidebar" 
            @click="onSidebarClick"
            @mouseleave="handleMouseLeave"
        >
            <AppMenu :model="menuTree_model" @menuitem-click="onMenuItemClick" />
        </div>

        <div :class="mainContainer">
            <router-view v-slot="{ Component }">
                <transition>
                    <keep-alive>
                        <component :is="Component" :key="$route.fullPath" />
                    </keep-alive>
                </transition>
            </router-view>
        </div>

        <transition name="layout-mask">
            <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
        </transition>

        <div v-if="store?.notification.visible" class="notification" :class="{ 
                'notification-fade-out': store.notification.fading, 
                'notification-warning': store.notification.status=='warning',
                'notification-error': store.notification.status=='error', 
                'notification-critical': store.notification.status=='critical', 
            }" 
            @mouseover="store.restore_notification" @mouseout="store.fadeOut_notification">
            <!-- <div class="notification-header">{{ store?.truncateText(store.notification.header,100) }}</div> <br> -->
            {{ store?.truncateText(store.notification.text, 150) }}
            <div class="button ml-2 btn-hover" title="Close notification" @click="store.close_notification">
                <i class="pi pi-times"></i>
            </div>
        </div>

        <Dropdown ref="dropdown" />
    </div>
</template>

<script>
import AppTopBar from './AppTopbar.vue';
// import ControlPanel from './ControlPanel.vue';
import AppMenu from './AppMenu.vue';
import { useMainStore } from './stores/mainStore'
// import AppFooter from './AppFooter.vue';
import { Tooltip } from '@mescius/wijmo'

const originalShow = Tooltip.prototype.show
Tooltip.prototype.show = function(...args) {
    const store = useMainStore(),
    content = args[1],
    result = originalShow.apply(this, args)

    if (this.showDelay) { // not is AttrChart
        this.gap = 0
    }
    this.hideDelay = 0
    this.showDelay = 1500
    this.showAtMouse = false
    store.tooltip_attachListeners(content)
    
    this.cssClass = 'wj-tooltip-my'

    return result
}

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'overlay',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,
            resizeTimeout: null,
            scrollTimeout: null,
        }
    },
    setup() {
        const store = useMainStore()
        
        let menuTree_model = store.menuTree_model

        return {
            menuTree_model,
            store,
        }
    },
    created() {
        this.store.App = this
    },
    mounted() {
        this.store.div_topbar = this.$refs.topbar

        window.addEventListener('resize', this.resize)
        window.addEventListener('scroll', this.scroll)

        this.resize()
        this.store.dropdown = this.$refs.dropdown // this.store.WIDGETS.dropdown
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.scroll)
        if (this.resizeTimeout) {
            clearTimeout(this.resizeTimeout)
        }
        window.removeEventListener('scroll', this.scroll)
        if (this.scrollTimeout) {
            clearTimeout(this.scrollTimeout)
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }]
        },
        mainContainer() {
            return ['main-container', {
                'main-container-with-notification': this.PAGE?.notification?.visible,
            }]
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        },
        viewKey() {
            // <router-view v-slot="{ Component }" :key="viewKey" >
            return `${this.$route.params.workspace || ''}-${this.$route.params.page || ''}`
        },
		PAGE() {
			return this.store.activeWORKSPACE?.activePAGE
		},
    },
    methods: {
        handleMouseLeave() {
            // console.log('mouseleave')
            // if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            // }

            this.menuClick = false;
        },
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
		onLayoutChange(layoutMode) {
			this.layoutMode = layoutMode;
		},
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        },
        resize() {
            if (this.resizeTimeout) {
                clearTimeout(this.resizeTimeout);
            }
            this.resizeTimeout = setTimeout(this.resize_afterTimer, 200);
        },
        resize_afterTimer() {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout)
            }
            this.scroll_afterTimer()
        },
        scroll() {
            if (this.scrollTimeout) {
                clearTimeout(this.scrollTimeout)
            }
            this.scrollTimeout = setTimeout(this.scroll_afterTimer, 200)
        },
        scroll_afterTimer() {
            this.store?.activeWORKSPACE?.activePAGE?.mainArea?.vueObj.checkWorkspaceScroll()
        },
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    components: {
        'AppTopBar': AppTopBar,
        // 'ControlPanel': ControlPanel,
        'AppMenu': AppMenu,
        // 'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
@import './scm_core.scss';
@import '../node_modules/@mescius/wijmo.styles/themes/material/wijmo.theme.material.indigo-amber.css';

.wj-control * {
    font-size: 0.875rem;
}
.wj-control a {
    font-size: 0.875rem;
}
.widget-15 {
    display: flex;
    height: 100%;
    width: 100%;
    padding-bottom: 3px;
}
.widget-12 {
    height: 100%;
    width: 100%;
}

.mr-0 {
    margin-right: 0px !important;
}
*, *::before, *::after {
    box-sizing: border-box;
}

.layout-sidebar::-webkit-scrollbar {
    width: 0px;
}
.layout-sidebar::-webkit-scrollbar-track {
    border-radius: 0.25rem;
    background: rgba(0, 0, 0, 0);
}

*::-webkit-scrollbar {
    width: 9px;
    height: 9px;
}

*::-webkit-scrollbar-track {
    border-radius: 0.25rem;
    background: rgba(0, 0, 0, 0.1);
}
*::-webkit-scrollbar-thumb {
    border-radius: 0.25rem;
    background: rgba(0, 0, 0, 0.2);
}

*::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.4);
}
*::-webkit-scrollbar-thumb:active {
    background: rgba(0, 0, 0, 0.9);
}

html,
body {
    height: 100%;
}
body {
    background-color: #f7faff;
    font-size: 0.875rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Ubuntu, 'Helvetica Neue', sans-serif;
    padding: 0;
    margin: 0;
    flex-direction: column;
}

.button {
    cursor: pointer;
}

#app {
    flex: 1 1 auto;
    overflow: hidden;
}

.hr {
    width: 1px;
    height: 100%;
    position: relative;
    background: #e4ecfb;
    z-index: 1;
}
.hr::before {
    content: '';
    display: block;
    width: 1px;
    height: 100%;
    background-color: #f6f9fe;
}

.menu {
    display: flex;
    flex-direction: column;
    font-size: 0.85rem;
    justify-content: flex-start;
    flex: 0 0 auto;
    padding: 0;
}
@media only screen and (max-width: 840px), (max-height: 840px) {
    .menu {
        overflow: auto;
        font: 0.75rem;
    }
}
.menu.menu--open .menu-item-name {
    display: block;
}

.menu-toggle {
    transition: all 500ms;
    padding: 1rem;
    text-align: center;
    border-bottom: 1px solid #e6ecf1;
    cursor: pointer;
}

.menu-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 1rem;
    cursor: pointer;
    user-select: none;
    position: relative;
    text-align: left;
}
.menu-item:hover {
    background: #f7faff;
}
.menu-item:hover:before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    bottom: 2rem;
    left: 2.5rem;
    position: absolute;
    background: linear-gradient(#fff, #fff), linear-gradient(#fff, #fff), #0085c7;
    background-position: center;
    background-size: 50% 2px, 2px 50%; /*thickness = 2px, length = 50% (25px)*/
    background-repeat: no-repeat;
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
    0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07);
}
.menu-item-name {
    margin: 0 0.5rem 0 1rem;
    white-space: nowrap;
    display: none;
}
@media only screen and (max-width: 840px), (max-height: 840px) {
    .menu-toggle {
        padding: 1rem 0;
    }
    .menu-item {
        padding: 0.5rem;
    }
    .menu-item:hover:before {
        bottom: 1rem;
        left: 1rem;
    }
    .menu-item svg {
        width: 32px;
        height: 32px;
    }
}

.menu .menu-toggle svg {
    transition: all 250ms ease-out 50ms;
    transform-origin: center center;
    transform: scaleX(1);
}
.menu.menu--open .menu-toggle svg {
    transform: scaleX(-1);
}

.blank {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
}
.blank svg {
    width: 3rem;
    height: 3rem;
    margin-bottom: 1rem;
}

.widget_workspace {
    border-style: dashed;
    border-color: lightgray;
    box-shadow: none;
}

.WIDGET {
    position: absolute;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    page-break-inside: avoid; /* important when printing the WORKSPACE */
    border-radius: 0.5rem;
    box-sizing: border-box;
    transition: all 500ms;

    background: white;
    box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
    0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07);
}
.widget-docs {
    z-index: 900;
    cursor: pointer;
}
.widget-docs * {
    pointer-events: none;
}
.widget-docs:hover {
    filter: drop-shadow(2px 4px 6px black);
}
  
.WIDGET-2024-10 {
    position: relative;
    resize: both;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: 7px 0px 0px 7px;
    height: 47vh;
    overflow: hidden;
    page-break-inside: avoid; /* important when printing the WORKSPACE */
    transition: all 1250ms;
    border-radius: 0.5rem;
    box-sizing: border-box;
    max-width: -webkit-fill-available;
    max-height: -webkit-fill-available;

    background: white;
    box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
    0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07);
}
.popup-tab {
    resize: both;
    margin: 3px 0px 0px 3px;
    height: 47vh;
    border-radius: 0.5rem;

    background: white;
    box-shadow: 0 1px 2px rgba(55, 63, 66, 0.07), 0 2px 4px rgba(55, 63, 66, 0.07), 0 4px 8px rgba(55, 63, 66, 0.07),
    0 8px 16px rgba(55, 63, 66, 0.07), 0 16px 24px rgba(55, 63, 66, 0.07), 0 24px 32px rgba(55, 63, 66, 0.07);
}
.wj-tooltip-my {
    background:#F6F9FC;
    border-radius: 0.5rem;
    opacity: .9;
    pointer-events: auto !important;
}
.tooltip-header {
    color: #647eff;
    font-weight: bold;
}
.tooltip-header-1 {
    color: #647eff;
    font-weight: bold;
    font-size: xx-small;
}
.tooltip-text {
    color: grey;
    // line-height: 2;
}
.json-array-key {
    color: #12826c !important;
    font-weight: bold;
    text-align: end;
    padding-right: 20px !important;
}
.json-key {
    color: #12826c !important;
    font-weight: bold;
}
.json-key2 {
    color: #1d75b3 !important;
}
.json-value {
    color: #b76622;
}
.json-mask {
    color: #2e383c;
}
.pi-doc {
    color: #647eff !important;
    font-weight: bold;
}
.row-header {
    position: absolute;
    top: 5px;
}
.row-header .attr-doc-button {
    transition: all 250ms;
    opacity: 0;
    color: lightslategray !important;
}
.row-header:hover .attr-doc-button  {
    opacity: 1;
    background: transparent;
}
.p-button-white {
    color: #64748B !important;
    background: #ffffff !important;
}
.p-button {
    transition: background-color 0.2s ease, box-shadow 0.2s ease;
}
/*.p-button:active {
    box-shadow: inset 2px 2px 2px rgb(255 204 103) !important;
}*/
.p-button-active {
    background: #ffe5b5 !important
}
.p-menu-white {
    color: #64748B !important;
}
.p-button-blue {
    color: #647eff !important;
    background: #ffffff !important;
    border: 1px solid #647eff !important;
}
.my-text-shadow {
    text-shadow:
        /* many shadows blur out the area around the text */
        0 0 1px white,
        0 0 2px white,
        0 0 3px white,
        0 0 4px white,
        0 0 5px white,
        0 0 6px white,
        0 0 7px white,
        0 0 8px white,
        0 0 9px white,
        0 0 10px white
}

@media only screen and (max-width: 10000px) {
    .WIDGET-2024-10 {
        width: calc(25% - 1rem);
    }
}
@media only screen and (max-width: 1599px) {
    .WIDGET-2024-10 {
        width: calc(33.33% - 1rem);
    }
}
@media only screen and (max-width: 1079px) {
    .WIDGET-2024-10 {
        width: calc(50% - 1rem);
    }
}
@media only screen and (max-width: 1023px) {
    .WIDGET-2024-10 {
        width: calc(100% - 1rem);
    }
}
// @media only screen and (max-height: 800px) {
//     .WIDGET-2024-10 {
//         max-height: 500px;
//     }
// }
.WIDGET-2024-10:only-child {
    height: 93vh;
}
.one-row-child .WIDGET-2024-10 {
    height: 93vh;
}
.WIDGET-2024-10:last-child {
    flex: 1 1 auto;
}
.widget-w1 {
    min-width: 20%;
}
.widget-w2 {
    min-width: 40%;
}
.widget-w3 {
    min-width: 65%;
}
.widget-w4 {
    min-width: -webkit-fill-available;
}
.widget-h0 {
    height: 40px;
}
.widget-h1 {
    height: 25vh;
}
.widget-h2 {
    height: 47vh;
}
.widget-h3 {
    height: 70vh;
}
.widget-h4 {
    height: 95vh;
}
.WIDGET:hover {
    border-color: #adb7bd;
}
.WIDGET.drag-over {
    border: 2px dashed #000;
}
.WIDGET .buttons {
    transition: all 250ms;
    opacity: 0;
    display: flex;
    align-items: center;
}
.WIDGET:hover .buttons {
    opacity: 1;
}
.widget-active .buttons {
    opacity: 1;
}
@media (hover: none) and (pointer: coarse) {
    .WIDGET .buttons {
        opacity: 1;
    }
}
.WIDGET .buttons > span {
    padding: 0 0.5rem;
    cursor: pointer;
}
.WIDGET.drag-over {
    border: 2px dashed #000;
    background-color: rgba(0, 0, 0, 0.1);
    transition: all 250ms;
}
.WIDGET.drag-source {
    opacity: 0.4;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    background-color: rgba(145, 200, 248, 0.75);
    transform: scale(0.9);
    transition: all 250ms;
}
/*
.WIDGET.enter {
    opacity: 0;
    transform: scale(0.9);
}
.WIDGET.enter-active {
    transition: opacity 0.5s, transform 0.5s;
    opacity: 1;
    transform: scale(1);
}
.WIDGET.leave-active {
    transition: opacity 0.5s, transform 0.5s;
    opacity: 0;
    transform: scale(0.9);
}
*/
.widget_workspace .widget-container {
    padding: 0.10rem 1rem;
    display: flex;
    align-items: center;
    cursor: move;
}
.WIDGET .widget-container {
    border-bottom: 1px solid #e0e0e0;
    padding: 0.30rem 1rem;
    display: flex;
    align-items: center;
    cursor: move;
}
.WIDGET .widget-header {
    flex-grow: 1;
    // font-size: 0.875rem;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0.125rem;
    opacity: 0.75;
    // background-color: lightslategray;
    // color: whitesmoke;
}
.single-line-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.WIDGET .widget-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
}
.WIDGET .blank-WIDGET {
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.commandPanel-buttons {
    background-color: lightslategray;
}
.commandPanel {
    padding: 2px;
}
.wj-radialgauge {
    width: 60%;
    height: 60%;
    max-width: 300px;
    padding: 1rem;
    overflow: hidden;
}
.wj-radialgauge .wj-value {
    font-size: 0.75rem;
    font-weight: 500;
}
.wj-gauge .wj-face path {
    stroke: none;
}

.wj-flexchart {
    height: -webkit-fill-available;
    background: transparent;
    width: 100%;
    border: none;
    padding: 1rem;
    margin: 0;
    overflow: hidden;
}
.wj-ranges {
    opacity: 0.15;
}

h3 {
    font-size: 0.875rem;
    font-weight: 500;
    text-align: center;
}
h4 {
    font-size: 0.875rem;
    font-size: 0.75rem;
    font-weight: 400;
    min-width: 7rem;
}
.wj-lineargauge {
    max-height: 1rem;
    width: 100%;
    overflow: hidden;
}
.line-gauge-WIDGET {
    width: 100%;
    padding: 0 1rem;
}
.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 1rem;
}
.wj-ranges {
    opacity: 0.15;
}

.wj-flexgrid {
    border: none;
    height: 100%;
}

.bulet-graph-WIDGET {
    width: 100%;
    padding: 0 1rem;
    overflow: hidden;
}
.table {
    margin: 0;
    table-layout: fixed;
    width: 100%;
}
.table td {
    padding: 0.15rem 0.5rem;
    font-size: 0.75rem;
    white-space: nowrap;
    width: 1.5rem;
}
.table td:first-child {
    width: 4rem;
}
.table td:last-child {
    width: auto;
}
:fullscreen {
   background-color: white;
}
:-webkit-full-screen {
   background-color: white;
}

.notification {
    position: fixed;
    bottom: 40px;
    right: 30px;
    background-color: #daecda;
    color: #778899;
    padding: 10px;
    border-radius: 0.5rem;
    opacity: 1;
    transition: opacity 1s ease-in-out;
    z-index: 1001;
    
    /*border: 1px solid #26852b;*/
    border: 1px solid rgba(0, 0, 0, .2);
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    word-break: break-word;

    display: flex;
    align-items: center;
}
.notification-widget {
    padding: 10px 20px;
    background-color: #daecda;
    color: #778899;
}
.notification-header {
    font-style: italic;
    font-size: 0.5rem;
}
.notification-workspace {
    padding: 10px 20px;
    background-color: #daecda;
    color: #778899;
    width: -webkit-fill-available;
    line-height: 1.2em;
}
.notification-error {
    background-color: #ffcbc8;
}
.notification-critical {
    background-color: #ffcbc8;
}
.notification-warning {
    background-color: #fffbea;
}
.notification-fade-out {
    opacity: 0;
}
.btn-hover:hover {
    color: #26852b;
}

.my-body {
    opacity: 0;
}
.no-scroll {
    overflow: hidden;
}
</style>
