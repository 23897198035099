<template>
	<div id="AppTopBar" class="layout-topbar">
		<button class="p-link layout-menu-button layout-topbar-button" 
			@click="onMenuToggle"
			@mouseenter="handleMouseEnter"
		>
			<i class="pi pi-bars"></i>
		</button>

		<button class="p-link layout-topbar-menu-button layout-topbar-button"
			v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
			leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
			<i class="pi pi-ellipsis-v"></i>
		</button>

		<div>
			<span><div class="workspace-title" v-if="store.activeWORKSPACE">{{ titleTopBar }}</div></span>
		</div>

		<div class="workspace-menu">
			<wj-menu
				v-for="(menu, index) in menuTree"
				:key="index"
				:id="'menu_' + index"
				:header="menu.title"
				:displayMemberPath="'title'"
				:subItemsPath="'attrs'"
				:showDropDownButton="false"
				:openOnHover="true"
				:isAnimated="false"
				:itemsSource="menu.attrs"
				:initialized="initMenu"
				:itemClicked="menuNodeClicked"
			/>
		</div>

		<div style="width: 21px;"/>
		<div class="widget_menu">
			<wj-menu id="ContextWidgetMenuId" :header="'Context Menu'" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false" style="display:none"
				v-if="store.activeWIDGET && 'groupMenu' in store.activeWIDGET && 'contextWidgetMenu' in store.activeWIDGET.groupMenu"
				:openOnHover="true" :isAnimated="false" :itemsSource="store.activeWIDGET.groupMenu.contextWidgetMenu" :initialized="initMenu" :itemClicked="menuNodeClicked"
			/>

		</div>
		
		<ul class="layout-topbar-menu hidden lg:flex origin-top">
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-database"></i> <!--@click="frmEditAccountPopup.show(true)"-->
				<wj-menu id="menu_1" :header="store.user.database.title" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
					v-if="store.user.database && 'title' in store.user.database"
					:openOnHover="true" :isAnimated="false" :itemsSource="store.database_attrs" :initialized="initMenu" :itemClicked="menuNodeClicked"
				/>
			</li>
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-sitemap"></i>
				<!-- <img src="images/icon-process-model.png" height="20" class="mr-2"> filter: invert(1); -->
				<span><div class="process-model-title">{{ store.process_model_doc?.title||'' }}</div></span>
			</li>
			<!-- <li>
				<button class="logo_pi p-link layout-topbar-button" @click="onClick({'command': 'onWidgetInfo__Click'})">
					<i class="pi pi-info-circle"></i>
					<span>Form info</span>
				</button>
			</li> -->
			<li class="layout-flex-center">
				<i class="logo_pi pi pi-user"></i>
				<wj-menu id="menu_1" :header="store.user.title" :displayMemberPath="'title'" :subItemsPath="'attrs'" :showDropDownButton="false"
					:openOnHover="true" :isAnimated="false" :itemsSource="store.user_attrs" :initialized="initMenu" :itemClicked="menuNodeClicked"
				/>
			</li>
		</ul>

	</div>
</template>

<script>
// import 'bootstrap.css';
import { useMainStore } from './stores/mainStore'
import * as input from '@mescius/wijmo.input';

export default {
	data: () => ({ 
		frmEditAccountPopup: {},
		pw3: '',
		pw4: '',
	}),
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		},
		menuTree() {
			return this.store.activeWORKSPACE?.activePAGE?.attrs||[]
		},
		titleTopBar() {
			const titlePage = Object.keys(this.store.activeWORKSPACE?.PAGES||'').length > 1 ? ` / ${this.store.activeWORKSPACE.activePAGE?.title||this.store.activeWORKSPACE.activePAGE?.tooltip||''}` : ''
			const titleWorkspace = this.store.activeWORKSPACE?.titleTopBar||this.store.activeWORKSPACE?.title
			return titleWorkspace + titlePage
		}
	},
    setup() {
        const store = useMainStore();
        return {
			// workspace,
            store,
        }
    },
    created() {
        this.store.AppTopbar = this
    },
	mounted() {
		console.log('mounted')
		// this.mdMenu.command = this.menuCommand();
		// if ('openMenu' in this) this.openMenu.command = this.menuCommand();
		// if ('moreMenu' in this) this.moreMenu.command = this.menuCommand();
		// if ('actionMenu' in this) this.actionMenu.command = this.menuCommand();

		// createElement
		// let parent = document.querySelector(`#workspaceTopMenu`)
		// let inputgroup1 = this.store.createElementFromHTML(`
		// 	<div id="workspaceTopMenuId"
		// 	v-if:false,
		// 	/>
		// `)
		// parent.appendChild(inputgroup1)

		// this.store.activeWIDGET = {groupMenu:{actionMenu:[]}}
		// let s = this.store.activeWIDGET.groupMenu.actionMenu
		
		// this.store.workspaceTopMenu[0] = {
		// 	header:'Actions',
		// 	displayMemberPath:'title',
		// 	subItemsPath:'attrs',
		// 	showDropDownButton:false,
		// 	openOnHover:true,
		// 	isAnimated:false,
		// 	itemsSource:[1],
		// 	itemClicked:this.menuNodeClicked
		// }

		// this.store.workspaceTopMenu[0] = new input.Menu('#workspaceTopMenu_0', this.store.workspaceTopMenu[0])
		// this.initMenu(this.store.workspaceTopMenu[0])
	},
    methods: {
        handleMouseEnter(event) {
            this.$emit('menu-toggle', event);
        },
        onMenuToggle(event) {
            this.$emit('menu-toggle', event);
        },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		},
		menuInitialized(menu){
			// this.menu = menu;
		},
		initMenu(menu) {
			this[menu.hostElement.id] = menu;
		},
		// menuCommand(param) {
			// 	let this_ = this
			// 	return {
				// 		executeStoreMethod: function(param){
					// 			let vueObj = this_.store.activeWIDGET.vueObj
					// 			vueObj.store.executeStoreMethod(vueObj, param)
					// 		},
		// 		canExecuteCommand: function(param){
			// 			return true;
			// 		}
			// 	}
		// },
		menuNodeClicked(menu) {
			let actionMenu = null
			if (menu.selectedIndex==-1 && this.store.activeWORKSPACE.groupMenu) {
				actionMenu = this.menuTree[menu.hostElement.id.slice(-1)]
			} else {
				actionMenu = menu.itemsSource[menu.selectedIndex]
			}
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			this.store.executeStoreMethod(vueObj, actionMenu)
		},
		onShowSettings__Click(event) {
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			this.store.executeStoreMethod(vueObj, {command: "onShowSettings__Click"})
		},
		onClick: function(actionMenu) {
			let vueObj = this
			if (this.store.activeWIDGET) {
				vueObj = this.store.activeWIDGET.vueObj 
			}
			vueObj.store.executeStoreMethod(vueObj, actionMenu)
		},
		initWidget: function(popup){
			this[popup.hostElement.id] = popup;
		},
		onSubmit: function(popup){
			alert('WIDGET submitted');
			popup.hide();
		},
    },
	watch: {
		pw4: function(){
			this.cnfrmPswdFrmEditValid = this.pw4 !== this.pw3 ? false : true;
		}
	},
	components: {
	},
}
</script>

<style scoped>
* {
  --surface-card:lightslategray;
  --text-color: rgba(255, 255, 255, 0.87);
  --text-color-secondary: rgba(255, 255, 255, 0.6);
}
/* * {
	color: rgba(255, 255, 255, 0.6);
}
.topbar-menu {
	color: white !important;
} */
.logo {
	background: -webkit-linear-gradient(315deg,#42d392 25%,#647eff);
	-webkit-background-clip:text;
	-webkit-text-fill-color:transparent;
}
.workspace-title {
	font-size: 1.5rem;
	font-weight: 500;
	padding: 0 25px 0 5px;
	color: var(--text-color-secondary);
}
.process-model-title {
    padding: 0 5px;
    color: var(--text-color);
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.logo3 {
	font-size: 0.875rem;
	font-style: normal;
	/* font-weight: 500; */
	/* padding: 0 25px 0 5px; */
	color: var(--text-color-secondary);
}
.logo_pi {
	font-size: 1.5rem;
	color: var(--text-color-secondary);
	margin-left: 0rem !important;
}
.layout-flex-center {
	display: flex;
	align-items: center;
}
.widget_modal {
	background: transparent !important;
}
.wj-control {
  background: var(--surface-card);
  color: var(--text-color);
  border: none;
}
.wj-cell {
  background: var(--surface-card);
  color: var(--text-color);
}
.wj-cell.wj-alt {
  background: var(--surface-card);
}
.wj-cell.wj-header {
  background: var(--surface-card);
  color: var(--text-color);
}
/* .widget_menu {
	transition: all 250ms;
	opacity: 0;
}
.widget_menu:hover {
	opacity: 1;
} */

</style>