<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-text" :style="attr.cssStyle||'max-width: 25rem !important;'" v-wjTooltip="tooltip()">
            <wj-combo-box
                :class="'link-control attr-element '+attr.cssClass"
                :id="attr.html_id"
                :isEditable="true"
                :isReadOnly="isReadOnly"
                :type="attr['input-type']"
                v-model:text="(doc[binding]||[])[1]"
                :textChanged="attr_set"
                v-wjContextMenu="'ContextWidgetMenuId'"
            >
                <div class="link-buttons">
                    <span wj-part="btn" class="wj-input-group-btn" v-if="attr.relation_segment && (!('showOpenButton' in attr)||attr.showOpenButton)">
                        <button class="wj-btn wj-btn-default attr-doc-button" @click="onButtonOpen__Click" tabindex="-1" type="button" aria-label="Internal link">
                            <i class="pi pi-doc pi-arrow-right"></i>
                        </button>
                    </span>
                </div>
            </wj-combo-box>
                <!-- autocomplete="name"  -->
                <!-- required -->
            <label v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <div v-if="attr['wj-error']" class="wj-error" tabindex="-1">{{ attr['wj-error'] }}</div>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            // this.text = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding
        },
        attr_set(input) {
            if (this.doc[this.binding] !== input.text) {
                this.store.attr_set(this.WIDGET, this.attr, input.text)
            }
        },
        onButtonOpen__Click() {
            // this.input.listBox.showSelection(true)
            let item = this.store.attr_get(this.WIDGET, this.attr)
            if (!item) {
                this.onButtonСhoiceStart__Click()
            } else {
                this.store.widget_open({
                    widget: 'widget-DOC-DataHub-raw',
                    doc: {
                        id: this.doc[this.binding][0],
                        segment: this.attr.relation_segment,
                        relation_binding: this.attr.relation_binding,
                    },
                   
                    parentActionMenu: { 'action_id':'action-front-2024-01-23', method: 'AttrLinkOdoo.onButtonOpen__Click' },
                    params_onOpen: {
                        parentSelectedItem: this.doc[this.binding],
                        attr: this.attr, 
                    },
                }, this)
            }
        },
        tooltip() {
            const tooltip2 = `</br><b class='tooltip-header'>id:</b> <b class='tooltip-text'> ${this.doc[this.binding]?.[0]}</b>`
            return this.store.tooltip_attr(this, tooltip2)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
}
</style>