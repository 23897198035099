<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- <div class="attr-containerResize" :id="'containerResize'+attr.html_id"> -->
            <!-- <Button class="p-button p-component p-button-secondary p-button-outlined mr-2 mb-2" @click="attrResize">Set height</Button> -->
            <div class="attr-chart" ref="attr_container" v-wjTooltip="attr.tooltip" :id="'vueObj'+attr_id" >
                <label class="label-attr" v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
                
                <wj-flex-chart 
                    class="attr-chart-1 custom-gridlines"
                    ref="attr_container"
                    :id="attr.html_id"
                    :chart-type="'Column'" 
                    :legendToggle="true"
                    :initialized="initializeChart" 
                    :rendered="chartRendered"
                    v-wjContextMenu="'ContextWidgetMenuId'"
                    v-visible="handleVisibility"
                    
                    :selectionMode="'Point'"
                    :tooltipContent="tooltipContent"
                    @click="chart_onClick"

                    stacking="Stacked"
                    :seriesVisibilityChanged="seriesVisibilityChanged"
                >
                    <!-- :chartType="'Area'"  -->
                    <!-- :palette="palette" -->
                    <wj-flex-chart-axis wjProperty="axisX" :position="attr?.['axisX-position']" :majorGrid="attr?.['axisX-majorGrid']" :minorGrid="attr?.['axisX-minorGrid']" />
                    <wj-flex-chart-axis wjProperty="axisY" :position="attr?.['axisY-position']" :majorGrid="attr?.['axisY-majorGrid']" :minorGrid="attr?.['axisY-minorGrid']" />
                    <!-- :format="'MMM yy'" -->
                    <!-- <wj-flex-chart-series :name="'Sales'" :binding="'measure__fact_sales'"></wj-flex-chart-series> -->
                    <!-- <wj-flex-chart-series :name="'Expenses'" :binding="'expenses'"></wj-flex-chart-series>
                    <wj-flex-chart-series :name="'Profit'" :binding="'profit'" :chartType="'LineSymbols'"></wj-flex-chart-series> -->
                    <wj-flex-chart-legend :position="attr?.['legend-position']" />
                    <wj-flex-chart-animation v-if="attr?.animation_axisAnimation"
                        :initialized="initializeAnimation" 
                        :easing="easing" 
                        :animationMode="animationMode" 
                        :duration="attr?.animation_duration||400">
                    </wj-flex-chart-animation>
                    <!-- :axisAnimation="axisAnimation"  -->

                    <wj-flex-chart-line-marker :lines="'Both'" :interaction="'Move'" :isVisible="mouseSelecting.lmVisible" :content="markerContent"/>
                </wj-flex-chart>
                
                <!-- SELECTOR -->
                <wj-flex-chart
                    v-if="(flex && flex.bindingX||'date_parse')=='date_parse' && attr['chart-selector-use']"
                    class="chart-selector"
                    :id="`${attr.html_id}-selector`"
                    :itemsSource="doc[binding]"
                    bindingX="date_parse"
                    chartType="Area"
                    tooltipContent=""
                    plotMargin="NaN 60 NaN 60"
                >
                    <wj-flex-chart-series :binding="binding_forSelector" />
                    <wj-flex-chart-legend position="None" />
                    <wj-flex-chart-axis wjProperty="axisX" position="None" />
                    <wj-flex-chart-axis wjProperty="axisY" position="None" />
                    <wj-flex-chart-range-selector :seamless="true" :rangeChanged="rangeChanged" />
                </wj-flex-chart>
                <!-- <div class='buttons-chart' v-if="!attr.buttonsChart">
                    <div class="button mr-2" v-wjTooltip="'Clear visibility'" @click="onClick({'command':'set_visibleChart'})">
                        <i class="pi pi-stop"></i>
                    </div>
                </div> -->
                <div class='buttons-chart' v-if="attr.buttonsChart">
                    <div class="button mr-2" v-wjTooltip="'Clear visibility'" @click="onClick({'command':'set_visibleChart'})">
                        <i class="pi pi-stop"></i>
                    </div>
                    <div class="button mr-2" v-wjTooltip="'Default visibility'" @click="onClick({'command':'set_visibleChart', 'defaultSeriesVisibility':true})">
                        <i class="pi pi-check-square"></i>
                    </div>
                    <div class="button mr-2" v-wjTooltip="'Minimize chart'" @click="onClick({'command':'changeAttrState', 'attrs_newState':[
                        {'name':'dataSheet', 'size':80},
                    ]})">
                        <i class="pi pi-window-minimize"></i>
                    </div>
                    <div class="button mr-2" v-wjTooltip="'Maximize chart'" @click="onClick({'command':'changeAttrState', 'attrs_newState':[
                        {'name':'dataSheet', 'size':10},
                    ]})">
                        <i class="pi pi-window-maximize"></i>
                    </div>
                    <!-- <div class="button mr-2" title="Full screen" @click="onClick({'command': 'onFullscreen__Click', 'currentVueObj':true})">
                        <i class="pi pi-window-maximize"></i>
                    </div> -->
                    <!-- <div class="button mr-2" v-wjTooltip="'Export SVG'" @click="onClick({'command':'onExportChart__Click'})">
                        <i class="pi pi-file-export"></i>
                    </div> -->
                    <div class="button mr-2" v-wjTooltip="'Close chart'" @click="onClick({'command':'changeAttrState', 'attrs_newState':[
                        {'name':'dataSheet', 'size':100},
                        {'name':'tabChart', 'visible':False},
                    ]})">
                        <i class="pi pi-times"></i>
                    </div>
                </div>
            </div>
        <!-- </div> -->
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as wjChart from '@mescius/wijmo.chart';
import { CollectionView, PropertyGroupDescription, SortDescription } from "@mescius/wijmo";
import { AxisGroupsDisplay } from "@mescius/wijmo.chart";

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        data: [], // doc[binding]
        binding: '',
        binding_forSelector: '',
        flex: null,

        easing: 'Linear',
        animationMode: 'All', // Point Series

        attrs_by_binding: {},
        mouseSelecting:{
            lmVisible:false,
            pointIndex:0,
            isSelecting: false,
            i1:null,
            i2:null,
            rect:null,
        },
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            // this.text = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding

            if(this.flex) {
                this.load_data()
                this.set_itemsSource()
            }
        },
        load_data() {
            this.attrResize()
            let chart_data = this.doc[this.binding],
                attrs = this.attr.attrs

            if (!chart_data) return

            this.flex.bindingX = this.attr.chart_bindingX = this.attr.chart_bindingX||'date_parse'
            if (this.flex.bindingX=='date_parse' || this.flex.bindingX=='date') {
                this.flex.bindingX='date_parse'

                // parseDate
                this.formatX = 'yyyy-MM'
                if (chart_data.length && ! wijmo.Globalize.parseDate(chart_data[0].date, this.formatX)) {
                    this.formatX = 'yyyy-MM-dd'
                }
                if (chart_data.length && ! wijmo.Globalize.parseDate(chart_data[0].date, this.formatX)) {
                    this.formatX = 'yyyy-MM-dd/ffff-ff-ff'
                }
                this.flex.axisX.itemsSource = []
                this.bindingX_type = '' // 'wijmo.Globalize.parseDate'

                this.freq_doc = {}
                for (let j = 0; j < chart_data.length; j++) {
                    if (chart_data[j].date) {
                        if (this.attr.data_schedule?.[j]) {
                            let rowDS = this.attr.data_schedule[j]
                            this.freq_doc[rowDS.freq] = true
                        }
                    }
                }

                this.freq = this.attr.freq
                if ( this.freq === 'G') {
                    if (this.freq_doc['S'] || this.freq_doc['D']) {
                        this.freq = 'D'
                    } else if (this.freq_doc['W']) {
                        this.freq = 'W'
                    } else if (this.freq_doc['M']) {
                        this.freq = 'M'
                    } else if (this.freq_doc['A']) {
                        this.freq = 'A'
                    }
                }
                this.freq_text = this.freq
                if ((this.attr['axisX-legend']||'Auto') !== 'Auto') {
                    if (this.freq !== this.attr['axisX-legend']) {
                        this.freq_text = ''
                    }
                    this.freq = this.attr['axisX-legend']
                }

                for (let j = 0; j < chart_data.length; j++) {
                    if (chart_data[j].date) {
                        if (this.bindingX_type === 'wijmo.Globalize.parseDate') {
                            chart_data[j].date_parse = wijmo.Globalize.parseDate(chart_data[j].date, this.formatX) // .getTime()
                        } else {
                            chart_data[j].date_parse = j
                        }
                        
                        let text = chart_data[j].date
                        if (this.attr.data_schedule?.[j]) { // this.attr.freq === 'G' && 
                            let rowDS = this.attr.data_schedule[j]

                            chart_data[j].date_Week = rowDS.date_Week
                            chart_data[j].date_Month = rowDS.date_Month
                            chart_data[j].date_Year = rowDS.date_Year

                            if (this.freq_text === 'D') {
                                text = rowDS.date_Day
                            } else if (this.freq_text === 'W') {
                                text = rowDS.date_Week
                            } else if (this.freq_text === 'M') {
                                text = rowDS.date_Month
                            } else if (this.freq_text === 'A') {
                                text = rowDS.date_Year
                            } else if (this.freq_text === '') {
                                text = ''
                            } else {
                                text = rowDS.date_Day
                            }
                        }
                        this.flex.axisX.itemsSource.push({
                            value: chart_data[j].date_parse,
                            text:  text,
                        })
                    }
                }

                this.flex.axisX.groupsOptions = { display:AxisGroupsDisplay.Show }
            } else {
                if (!this.attr.chart_bindingX.endsWith('.title')) {
                    for (let attr of this.attr.attrs) {
                        if (attr['binding'] === this.attr.chart_bindingX) {
                            this.store.get_itemsSource(this, this.doc[this.binding], attr, 'data')
                            this.flex.bindingX = `${attr['binding']}.title`
                            break
                        }
                    }
                }
            }

            if (!this.attr?.animation_axisAnimation || !this.data.length || this.data.length!=chart_data.length) {
                this.data = chart_data
            } else {
                this.data.length = 0
                for (let i=0; i < chart_data.length; i++) {
                    this.data.push(chart_data[i])
                    // for (let attr of attrs) {
                    //     if (this.data[i][attr.binding] !== chart_data[i][attr.binding]) {
                    //         this.data[i][attr.binding] = chart_data[i][attr.binding]
                    //     }
                    // }
                }
                // this.data.unshift({})
                // this.data.shift()
            }

            // cache visibility
            let cacheVisibility = {}
            for (let i=0; i < this.flex.series.length; i++) {
                cacheVisibility[this.flex.series[i].binding] = {visibility: this.flex.series[i].visibility}
            }

            // series
            // if (!this.flex.series.length) {
                this.flex.series.clear()
                let axisY = {}
                let attrsByOrder = []
                for (let attr of attrs) if (attr.stacked) attrsByOrder.push(attr)
                for (let attr of attrs) if (!attr.stacked) attrsByOrder.push(attr)

                for (let attr of attrsByOrder) {
                    if (attr.component=='AttrNumber') { // && !attr.chartGroup
                    // && ['MEASURE_minStock','MEASURE_optStock'].includes(attr.binding)
                        let series = new wjChart.Series()
                        series.binding = attr.binding
                        if (this.binding_forSelector=='' && attr.forSelector) {
                            this.binding_forSelector = attr.binding
                        }
                        series.name = attr.title
                        if ('chartType' in attr) {
                            if (attr.stacked) { // attr.chartType=='Area' && 
                                // pass for stacking
                                if (!this.flex.chartType) {
                                    this.flex.chartType = attr.chartType
                                }
                            } else {
                                series.chartType = attr.chartType
                            }
                        } else { // default
                            series.chartType ='Column'
                        }
                        // series.chartType="LineSymbols"
                        // series.symbolStyle={fill:'gold',stroke:'gold'}
                        // series.chartType = "Spline"
                        if (attr.cssStyleChart && attr.cssStyleChart!=''){
                            // console.log(attr.cssStyleChart)
                            series.style = attr.cssStyleChart
                        }
                        // series['visible'] = true // add 'visible' property for binding
                        if (attr.chartGroup){
                            series.visibility = 3 // Hidden
                        } else if (series.binding in cacheVisibility){
                            series.visibility = cacheVisibility[series.binding].visibility
                        } else if (attr.visibleChart==false){
                            series.visibility = 2 // Legend
                        }
                        if (attr.axisY){
                            if (!axisY[attr.axisY]) {
                                let axisY2 = new wjChart.Axis();
                                axisY2.position = wjChart.Position.None; //2023 10 look - an empty field appears, if Right
                                axisY2.majorGrid = false
                                axisY2.minorGrid = false
                                // axisY2.title = 'Downloads (k)';
                                // axisY2.format = 'n0,';
                                axisY2.min = 0;
                                
                                // if (attr.axisY.setMax) {
                                    let max = 0
                                    for (let attr2 of attrs) {
                                        if (attr2.axisY==attr.axisY) {
                                            for (let j = 0; j < chart_data.length; j++) {
                                                if (chart_data[j][attr2.binding] > max) {
                                                    max = chart_data[j][attr2.binding]
                                                }
                                            }
                                        }
                                    }
                                    axisY2.max = max
                                // }

                                axisY2.axisLine = false;
                                axisY2.labels = false;
                                axisY[attr.axisY] = axisY2
                            }
                            series.axisY = axisY[attr.axisY]; 
                        }
                        this.flex.series.push(series)
                        this.attrs_by_binding[attr.binding] = attr
                    }
                }
                if (this.attr.axisY) {
                    for (let key in this.attr.axisY) {
                        this.flex.axisY[key] = this.attr.axisY[key]
                        // this.flex.axisY.majorGrid = false
                        // this.flex.axisY.minorGrid = false
                    }
                }
                if (this.binding_forSelector=='') {
                    for (let attr of attrs) {
                        if (attr.component=='AttrNumber' && attr.visibleChart) {
                            this.binding_forSelector = attr.binding
                            break
                        }
                    }
                    if (this.binding_forSelector=='') {
                        for (let attr of attrs) {
                            if (attr.component=='AttrNumber') {
                                this.binding_forSelector = attr.binding
                                break
                            }
                        }
                    }
                }
                this.seriesVisibilityChanged(false)
            // }
        },
        set_itemsSource() {
            if ((this.flex.bindingX=='date_parse' || this.flex.bindingX=='date') && this.attr.data_schedule) { //  && this.attr.freq === 'G'
                let groupDescriptions = [],
                freq = this.freq
                
                if (freq === 'D') {
                    groupDescriptions.push(new PropertyGroupDescription("date_Year"))
                    groupDescriptions.push(new PropertyGroupDescription("date_Month"))
                    groupDescriptions.push(new PropertyGroupDescription("date_Week"))
                } else if (freq === 'W') {
                    groupDescriptions.push(new PropertyGroupDescription("date_Year"))
                    groupDescriptions.push(new PropertyGroupDescription("date_Month"))
                } else if (freq === 'M') {
                    groupDescriptions.push(new PropertyGroupDescription("date_Year"))
                } else if (freq === 'A') {
                    1
                } else {
                    groupDescriptions.push(new PropertyGroupDescription("date_Year"))
                    groupDescriptions.push(new PropertyGroupDescription("date_Month"))
                    groupDescriptions.push(new PropertyGroupDescription("date_Week"))
                }

                this.flex.itemsSource = new CollectionView(this.doc[this.binding], {
                    // sortDescriptions: [new SortDescription("date_month", false)],
                    groupDescriptions
                })
            } else {
                this.flex.itemsSource = []
                this.flex.itemsSource = this.doc[this.binding]
            }
        },
        initializeChart(flex) {
            this.flex = flex
            this.content_Changed()
            this.setSelectoinListener()
        },
        initializeAnimation(animation){
            this.chartAnimation = animation;
        },
        rangeChanged: function (sender) {
            this.flex.beginUpdate();
            this.flex.axisX.min = sender.min;
            this.flex.axisX.max = sender.max;
            this.flex.endUpdate();
        },
        attrResize() {
            if (!this.isVisible) return
            // console.log('attrResize')
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = document.getElementById(this.attr.html_id); // this.$refs.attr_container
            let elementFlex_selector = null
            if ((this.flex.bindingX||'date_parse')=='date_parse') {
                elementFlex_selector = document.getElementById(`${this.attr.html_id}-selector`)
            }
            if (!elementForm || !elementFlex) {
                return
            }
            if (this.attr.height) {
                elementFlex.style.height = `${this.attr.height-60}px`
            } else {
                const elementWidget_Rect = elementForm.getBoundingClientRect()
                const elementFlex_Rect = elementFlex.getBoundingClientRect()
                let elementFlex_selector_Rect = {height:0}
                if (elementFlex_selector) {
                    elementFlex_selector_Rect = elementFlex_selector.getBoundingClientRect()
                }
                if (elementFlex_Rect.height) {
                    let newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) - 2
                    if (newHeight>200) {
                        newHeight -= elementFlex_selector_Rect.height
                        // this.flex.axisX.labels = true
                        // elementFlex_selector.style.display = ``
                    } else {
                        // newHeight += 25
                        // this.flex.axisX.labels = false
                        // elementFlex.style.padding = `0px`
                        // elementFlex_selector.style.display = `none`
                    }
                    if (this.attr.size) {
                        newHeight = Math.round(newHeight * this.attr.size / 100)
                    }
                    if (elementFlex_Rect.height !== newHeight) {
                        console.log('attrResize' +(elementFlex_Rect.height - newHeight))
                        elementFlex.style.height = `${newHeight}px`
                    }
                }
            }
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        chartRendered(s, e) {
            if (this.attr.chartRendered || this.attr.drawAlarmZone) {
                let xMin = s.axisX.actualMin.valueOf(),
                    xMax = s.axisX.actualMax.valueOf(),
                    yMin = s.axisY.actualMin,
                    yMax = s.axisY.actualMax;
                // console.log(s)
                if(isNaN(xMin) && isNaN(xMax)) {
                    return;
                }
                if (this.attr.chartRendered) {
                    this.drawAlarmZone(s, e.engine, xMin, 0, xMax, yMax, 'green-zone');
                    // this.drawAlarmZone(s, e.engine, xMin, yMin, xMax, 0, 'red-zone');
                }

                if (this.attr.drawAlarmZone) {
                    for (let zone of this.attr.drawAlarmZone) {
                        // if (this.formatX) {
                        //     zone.xMin = wijmo.Globalize.parseDate(zone.xMin, this.formatX)
                        //     zone.xMax = wijmo.Globalize.parseDate(zone.xMax, this.formatX)
                        // }
                        this.drawAlarmZone(s, e.engine, zone.xMin||xMin, zone.yMin||yMin, zone.xMax||xMax, zone.yMax||yMax, zone.className)
                    }
                }
            }
            this.getSelectionWorkspace_date()
        },

        //---------------------------------   SELECTION  ---------------------------------
        setSelectoinListener() {
            const flex = this.flex
            flex.addEventListener(flex.hostElement, 'mouseenter', () => {
                this.mouseSelecting.lmVisible = true
            })
            flex.addEventListener(flex.hostElement, 'mouseleave', () => {
                this.mouseSelecting.lmVisible = false
            })
            
            let isSelecting = false,
            x1, y1, x2, y2, pt1, pt2, i1, i2,
            padding = 5
            // flex_rect = flex.hostElement.getBoundingClientRect(),
            
            flex.addEventListener(flex.hostElement, 'mousedown', (event) => {
                x1 = event.clientX
                y1 = event.clientY
                let plotArea_rect = flex.hostElement.querySelector('.wj-plot-area').getBoundingClientRect()

                if (plotArea_rect.left-padding <= x1 && x1 <= plotArea_rect.right+padding
                 && plotArea_rect.top-padding <= y1 && y1 <= plotArea_rect.bottom+padding
                ) {
                    isSelecting = true
                    if (event.shiftKey) {
                        i2 = this.mouseSelecting.pointIndex
                        if (this.mouseSelecting.i2 !== i2) {
                            this.mouseSelecting.i2 = i2
                            this.setSelection(this.mouseSelecting.i1||0, i2, i2)
                        }
                    } else {
                        i1 = this.mouseSelecting.i1 = this.mouseSelecting.i2 = this.mouseSelecting.pointIndex
                        this.setSelection(i1, i1, i1)
                    }
                    // pt1 = flex.pointToData(x1, y1)
                    // console.log(pt1.x)
                    // i1 = this.dateToDataIndex(pt1.x1)
                }
            })
            flex.addEventListener(flex.hostElement, 'mouseup', (event) => {
                if (isSelecting) {
                    isSelecting = false
                    x2 = event.clientX
                    y2 = event.clientY

                    i2 = this.mouseSelecting.pointIndex
                    if (this.mouseSelecting.i2 !== i2) {
                        this.mouseSelecting.i2 = i2
                        this.setSelection(this.mouseSelecting.i1, i2, i2)
                    }
                }
            })
            flex.addEventListener(flex.hostElement, 'mousemove', (event) => {
                if (isSelecting) {
                    x2 = event.clientX
                    y2 = event.clientY

                    i2 = this.mouseSelecting.pointIndex
                    if (this.mouseSelecting.i2 !== i2) {
                        this.mouseSelecting.i2 = i2
                        this.setSelection(this.mouseSelecting.i1, i2, i2)
                    }
                }
            })
        },
        getSelectionWorkspace_date() {
            if (!this.isVisible || !this.data?.length) return
            let WORKSPACE = this.store.activeWORKSPACE,
                selectedDates = WORKSPACE.selectionWorkspace?.selectedDates
            if (selectedDates && this.attr.getSelectionWorkspace_date) {
                const flex = this.flex
                if (!flex?.axisX?.actualMax) {
                    return
                }
                    
                let date1, date2, date3
                if (selectedDates.col1?.date <= selectedDates.col2?.date) {
                    date1 = selectedDates.col1?.date
                    date2 = selectedDates.col2?.date
                    date3 = selectedDates.col2?.date_next || selectedDates.col2?.date
                } else {
                    date1 = selectedDates.col2?.date
                    date2 = selectedDates.col1?.date
                    date3 = selectedDates.col1?.date_next || selectedDates.col1?.date
                }

                let i1 = this.dateToDataIndex(date1),
                    i2 = this.dateToDataIndex(date2, i1),
                    i3 = this.dateToDataIndex(date3, i2)
                
                this.setSelection(i1, i2, i3, true, 1)
            }
        },
        setSelection(i1, i2, i3, isFrom_selectionWorkspace=false, timeout=20) {
            if (this.setSelection_timeout) {
                clearTimeout(this.setSelection_timeout)
            }
            this.setSelection_timeout = setTimeout(() => {
                this.setSelection_afterTimeout(i1, i2, i3, isFrom_selectionWorkspace)
                this.setSelection_timeout = null
            }, timeout)
        },
        setSelection_afterTimeout(i1, i2, i3, isFrom_selectionWorkspace) {
            const flex = this.flex,
                padding = 20

            let xMin = flex.axisX.actualMin.valueOf(),
                xMax = flex.axisX.actualMax.valueOf(),
                yMin = flex.axisY.actualMin,
                yMax = flex.axisY.actualMax,
                
                // if axisY is hidden
                flex_rect = flex.hostElement.getBoundingClientRect(),
                plotArea_rect = flex.hostElement.querySelector('.wj-plot-area').getBoundingClientRect(),
                ptMin0 = flex.pageToControl(plotArea_rect.left, plotArea_rect.top),
                ptMax0 = flex.pageToControl(plotArea_rect.right, plotArea_rect.bottom)

            let ptMin = flex.dataToPoint(xMin, yMin),
                ptMax = flex.dataToPoint(xMax, yMax)

            if (i1 > i2) {
                let temp = i1
                i1 = i2
                i2 = i3 = temp
            }
            
            let x1
            if (i1 === null || i1 < 0) {
                x1 = ptMin.x - padding
            } else if (i1 >= this.data.length) {
                x1 = ptMax.x + padding
            } else if (i1 - 1 < 0) {
                let pt1 = flex.dataToPoint(this.dataIndexToDate(i1), yMin)
                x1 = pt1.x
            } else {
                // x1 = middle(i1-1, i1)
                let pt10 = flex.dataToPoint(this.dataIndexToDate(i1-1), yMin),
                    pt11 = flex.dataToPoint(this.dataIndexToDate(i1), yMin)
                    
                x1 = (pt10.x + pt11.x) / 2
            }
            
            let x2
            if (i2 === null || i2 < 0) {
                x2 = ptMin.x - padding
            } else if (i2 >= this.data.length) {
                x2 = ptMax.x + padding
            } else if (i2 === this.data.length-1) {
                let pt2 = flex.dataToPoint(this.dataIndexToDate(i2), yMin)
                x2 = pt2.x
            } else if (i3 >= this.data.length) {
                x2 = ptMax.x + padding
            } else if (i2 === i3) {
                // x2 = middle(i2, i2+1)
                let pt20 = flex.dataToPoint(this.dataIndexToDate(i2), yMin),
                    pt21 = flex.dataToPoint(this.dataIndexToDate(i2+1), yMin)
                    
                x2 = (pt20.x + pt21.x) / 2
            } else  {
                // x2 = middle(i3-1, i3)
                let pt30 = flex.dataToPoint(this.dataIndexToDate(i3-1), yMin),
                    pt31 = flex.dataToPoint(this.dataIndexToDate(i3), yMin)
                    
                x2 = (pt30.x + pt31.x) / 2
            }
            
            this.drawSelection(x1, x2, ptMin0.y, ptMax0.y)
            this.selectionChanged(this.data[i1], this.data[i2], isFrom_selectionWorkspace)
        },
        drawSelection(x1, x2, y1, y2) {
            const marging = 10
            
            this.removeSelection()

            this.mouseSelecting.rect = this.flex.renderEngine.drawRect(
                Math.min(x1, x2) - marging, Math.min(y1, y2) - marging, 
                Math.abs(x1 - x2) + 2*marging, Math.abs(y1 - y2) + 2*marging,
                'chart-zone-selected' // className
            )
            this.mouseSelecting.rect.setAttribute("rx", 5)
            this.mouseSelecting.rect.setAttribute("ry", 5)
        },
        removeSelection() {
            if (this.mouseSelecting.rect?.parentNode) {
                this.mouseSelecting.rect.parentNode.removeChild(this.mouseSelecting.rect)
            }
            this.mouseSelecting.rect = null
        },
        selectionChanged(data1, data2, isFrom_selectionWorkspace) {
            if (!isFrom_selectionWorkspace && this.attr.setSelectionWorkspace_date) {
                this.store.setSelectionWorkspace_date(this.WIDGET,{
                    selectedDates: {
                        col1: data1, 
                        col2: data2, 
                    }
                })
            }
        },

        //---------------------------------
        dataIndexToDate(i) {
            if (this.bindingX_type === 'wijmo.Globalize.parseDate') {
                const date = new Date(this.data[i].date)
                return date.getTime()
            } else {
                return i
            }
        },
        dateToDataIndex(date, i0=0) {
            if (!this.data?.length || !this.data[0].date) {
                return null
            }

            for (let i = Math.max(0, i0); i < this.data.length; i++) {
                if (date < this.data[i].date) {
                    return i - 1
                } else if (date === this.data[i].date) {
                    return i
                }
            }

            return this.data.length + 1
        },
        drawAlarmZone(flex, engine, x1, y1, x2, y2, className) {
            if (!isNaN(x1) && !isNaN(y1) && !isNaN(x2) && !isNaN(y2)) {
                let pt1 = flex.dataToPoint(x1, y1),
                    pt2 = flex.dataToPoint(x2, y2),
                    marging = 10,
                    rect = engine.drawRect(
                        Math.min(pt1.x, pt2.x) - marging, Math.min(pt1.y, pt2.y) - marging, 
                        Math.abs(pt2.x - pt1.x) + 2*marging, Math.abs(pt2.y - pt1.y) + 2*marging,
                        className
                    )
                rect.setAttribute("rx", 5)
                rect.setAttribute("ry", 5)
                /*stroke-linejoin: round;*/
            }
        },
        tooltipContent(hti) {
            let tooltip_attr = {},
                html = ''
            if (hti.item) {
                if ('date' in hti.item) {
                    let value = hti.item['date'],
                    text = value,
                    j = hti.pointIndex

                    if (this.attr.data_schedule?.[j]) {
                        let rowDS = this.attr.data_schedule[j]
                        if (rowDS.freq === 'D') {
                            text = `${rowDS.date_Year} ${rowDS.date_Month} ${rowDS.date_Day}  ${rowDS.date_Week}`
                        } else if (rowDS.freq === 'W') {
                            text = `${rowDS.date_Year} ${rowDS.date_Month} ${rowDS.date_Week}`
                        } else if (rowDS.freq === 'M') {
                            text = `${rowDS.date_Year} ${rowDS.date_Month}`
                        } else if (rowDS.freq === 'A') {
                            text = `${rowDS.date_Year}`
                        }
                    }

                    tooltip_attr['date'] = value
                    html += `<div style="display: flex; justify-content: space-between;">
                            <b class='tooltip-text'>Date:</b>
                            <b class='tooltip-text'> ${this.store.formatCount(text)}</b>
                        </div>
                    `
                }
                for (let series of this.flex.series) {
                    if (series.visibility==0) { // Visible
                        let key = series['name']||series['binding'],
                            value = hti.item[series['binding']]
                        
                        if (value||0 !== 0) {
                            tooltip_attr[key] = value

                            if (html) {
                                // html += '</br>';
                                html += '<div style="height: 5px;"></div>';
                            }
                            let style_stroke = 'white'
                            if (series.style && series.style.stroke) {
                                style_stroke = series.style.stroke
                            }
                            // style='line-height: 1.5;'
                            html += `<div style="display: flex; justify-content: space-between;">
                                    <b class='tooltip-header' style='color:${style_stroke};'><span class='tooltip-text' style='border-bottom: 2px solid ${style_stroke};'> ${key}:&nbsp;&nbsp;</span></b>
                                    <b class='tooltip-text' style='border-bottom: 2px solid ${style_stroke};'> ${this.store.formatCount(value)} <b class='tooltip-header' style='color:${style_stroke};'>■</b></b>
                                </div>
                            `;
                        }
                    }
                }
            }

            // for (let attr of this.attr.attrs) {
            //     tooltip_attr[attr['title']||attr['binding']] = hti.item[attr['binding']]
            // }
            return html // this.store.tooltip_html(tooltip_attr)
        },
        chart_onClick() {
            // if (this.dateInChart) {
            //     if (this.attr.setSelectionInAttr_AfterSelectionChanged) {
            //         let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.setSelectionInAttr_AfterSelectionChanged)
            //         attr_vueObj.setSelection(this.dateInChart)
            //     }
            // }
        },
        seriesVisibilityChanged(set_selectedMeasures=true) {
            if (this.WIDGET.doc?.params?.use_selectorMeasures) {
                if (set_selectedMeasures) {
                    let selectedMeasures = {}
                    for (let series of this.flex.series) {
                        selectedMeasures[series['binding']] = (series.visibility===0 || series.visibility===1) // Visible
                    }
                    this.store.use_selectorMeasures(this.WIDGET, selectedMeasures)
                } else {
                    this.store.use_selectorMeasures(this.WIDGET)
                }
            }
            for (let series of this.flex.series) {
                let attr = this.attrs_by_binding[series['binding']]
                if (attr.setVisibility_chartGroup) {
                    for (let series2 of this.flex.series) {
                        let attr2 = this.attrs_by_binding[series2['binding']]
                        if (attr2.chartGroup === attr.setVisibility_chartGroup) {
                            let visibility = 3 // Hidden
                            if (series.visibility==0) { // Visible
                                visibility = 1 // Plot
                            }
                            if (series2.visibility!=visibility) {
                                console.log(`seriesVisibilityChanged: ${series2['binding']}, ${visibility}`)
                                series2.visibility=visibility
                            }
                        }
                    }
                }
            }
        },
        markerContent (ht) {
            if (ht.item) {
                this.mouseSelecting.pointIndex = ht._pointIndex
            }
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                if (this.attr.getSelectionWorkspace_date){
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                }
            }
        },
    },
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            if (this.flex.bindingX!='date_parse' && !this.attr.chart_bindingX.endsWith('.title')) {
                for (let attr of this.attr.attrs) {
                    if (attr['binding'] === this.attr.chart_bindingX) {
                        if (attr.relation_segment && attr.relation_segment==this.store.itemsSource.change_type) {
                            // console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                            this.store.get_itemsSource(this, this.doc[this.binding], attr, 'data')
                            this.flex.bindingX = `${attr['binding']}.title`
                            this.set_itemsSource()
                            break
                        }
                    }
                }
            }
        }
    },
}
</script>

<style>
.attr-chart {
    width:-webkit-fill-available !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding-bottom: 3px; */
    /* position: relative; */
}
.custom-gridlines.wj-flexchart .wj-axis-x .wj-tick,
.custom-gridlines.wj-flexchart .wj-axis-y .wj-tick {
    stroke: darkgreen;
}

.custom-gridlines.wj-flexchart .wj-axis-x .wj-gridline,
.custom-gridlines.wj-flexchart .wj-axis-y .wj-gridline {
    opacity: .25;
    stroke: darkgreen;
    stroke-width: 1px;
}

.custom-gridlines.wj-flexchart .wj-axis-x .wj-gridline-minor,
.custom-gridlines.wj-flexchart .wj-axis-y .wj-gridline-minor {
    opacity: .25;
    stroke: darkgreen;
    stroke-dasharray: 0;
    stroke-width: .25px;
}
.chart-selector.wj-flexchart {
    /* position: absolute; */
    bottom: 0px;
    height: 60px;
}
.buttons-chart{
    transition: all 250ms;
    opacity: 0;
    display: flex;
    align-items: center;

    position: absolute;
    padding: 24px;
    /* top: 24px; */
    right: 0px;
    color: lightslategray;
    /* visibility: hidden; */
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white
}
.attr-chart:hover .buttons-chart {
    opacity: 1;
}
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-hline,
.wj-flexchart .wj-chart-linemarker .wj-chart-linemarker-vline {
    height: 1px;
    width: 1px;
    opacity: .9;
    background: rgba(255, 150, 30, 0.75)
}
</style>