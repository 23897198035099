<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="widget_menu">
            <wj-menu 
                :id="attr.html_id"
                :class="menuClass"
                :displayMemberPath="'title'" 
                :subItemsPath="'attrs'" 
                :showDropDownButton="false"
                v-if="attr.visible !== false && attr.hide !== true && (!attr['v-if-doc'] || WIDGET?.doc?.[ attr['v-if-doc'] ])"
                :openOnHover="true"
                :isAnimated="false" 
                :itemsSource="attr.attrs" 
                :initialized="initMenu" 
                :itemClicked="menuNodeClicked"
            >
                <!-- <i class="pi pi-bars"></i> -->
            </wj-menu>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: {
        attr_id: String,
        widget_id: [String, Number],
        initialAttr: {
            type: Object,
            default: null,
        },
    },
    data: () => ({
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
        if (this.initialAttr) {
            this.attr = this.initialAttr
        }
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        },
        menuNodeClicked(menu) {
            let actionMenu = menu.itemsSource[menu.selectedIndex]
            let vueObj = this.WIDGET.vueObj
            this.store.executeStoreMethod(vueObj, actionMenu)
        },
    },
    computed: {
        menuClass() {
            return [
                this.attr['cssClass'],
                'commandPanel-buttons-menu p-button-secondary p-button-sm mr-2 p-2', {
                // 'p-button-active': this.attr.active,
            }]
        },
    },
}
</script>

<style>
.widget_menu .wj-template {
    display: none;
}
.pi-bold {
    font-size: larger;
    font-weight: bolder;
}
.commandPanel-buttons-menu .wj-template {
    display: none;
}
.wj-listbox-item {
    z-index: 99999;
    box-shadow: none !important;
}
</style>