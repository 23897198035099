<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="wj-labeled-input attr-text" :style="attr.cssStyle||'max-width: 25rem !important;'" v-wjTooltip="tooltip()">
            <wj-combo-box
                :class="'attr-element '+attr.cssClass"
                :id="attr.html_id"
                :isEditable="true"
                :isReadOnly="isReadOnly"
                :type="attr['input-type']"
                v-model:text="doc[binding]"
                :textChanged="attr_set"
                v-wjContextMenu="'ContextWidgetMenuId'"
            />
                <!-- autocomplete="name"  -->
                <!-- required -->
            <label v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <div v-if="attr['wj-error']" class="wj-error" tabindex="-1">{{ attr['wj-error'] }}</div>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        binding: '',
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            // this.text = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding
        },
        attr_set(input) {
            if (this.doc[this.binding] !== input.text) {
                this.store.attr_set(this.WIDGET, this.attr, input.text)
            }
        },
        tooltip() {
            return this.store.tooltip_attr(this)
        },
    },
	computed: {
        isReadOnly() {
            return this.WIDGET.isReadOnly||this.attr.isReadOnly||this.attr.fieldMapping?.use||false
        },
	},
}
</script>

<style scoped>
.attr-text {
    width:-webkit-fill-available !important;
}
</style>