<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <!-- v-if="attr['@use']!=false" -->
        <Button 
            :id="attr.html_id"
            :class="buttonClass"
            v-wjTooltip="tooltip()"
            @click="onClick()"
            v-if="attr.visible !== false && attr.hide !== true && (!attr['v-if-doc'] || WIDGET?.doc?.[ attr['v-if-doc'] ])"
        >
            <div class="badge" v-if="attr.countItems && !attr['hide-countItems']">{{ store?.formatCount(attr.countItems) }}</div>
            {{ `${attr.cssClass&&attr.title? ' ' : ''}${attr.title?? ''}` }}
        </Button>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({
        WIDGET: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()

        if (this.attr.shortkey) {
        //    this.$shortkey(this.attr.hotkey, 'onClick');
        }
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        },
        onClick() {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, this.attr)
        },
        tooltip() {
            let params = {}
            
            params['Action ID'] = this.attr.action_id
            params['Command'] = this.attr.command
            params['Method'] = this.attr.method
            params['Target field'] = this.attr.binding
            params['Exclude'] = (this.attr.doc_sendToBackend_exclude || []).join(', ')

            return this.store.tooltip_html(params, this.attr.tooltip||'')
        },
    },
    computed: {
        buttonClass() {
            return [
                'commandPanel-buttons p-button-secondary p-button-sm mr-2 p-2 ' + this.attr['cssClass'], {
                'p-button-active': this.attr.active,
            }]
        },
    },
}
</script>

<style>
.commandPanel-buttons {
    overflow: visible;
}
</style>