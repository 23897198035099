import * as config_database from '../config_database';
import { createPinia } from 'pinia'
import { GlobalCmComponent } from "codemirror-editor-vue3";

// import 'bootstrap/dist/css/bootstrap.css';
// import '../node_modules/@mescius/wijmo.styles/wijmo.css';
// import './assets/styles/sass/buttons.less';

import 'primevue/resources/primevue.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import 'prismjs/themes/prism-coy.css';
import './assets/styles/layout.scss';
import './assets/demo/flags/flags.css';

import { createApp, reactive } from 'vue';

import router from './router';
import AppWrapper from './AppWrapper.vue';
import PrimeVue from 'primevue/config';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BadgeDirective from 'primevue/badgedirective';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Calendar from 'primevue/calendar';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Chart from 'primevue/chart';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import Chips from 'primevue/chips';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DataViewLayoutOptions from 'primevue/dataviewlayoutoptions';
import Dialog from 'primevue/dialog';
import Divider from 'primevue/divider';
// import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import Image from 'primevue/image';
import InlineMessage from 'primevue/inlinemessage';
import Inplace from 'primevue/inplace';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Galleria from 'primevue/galleria';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import OverlayPanel from 'primevue/overlaypanel';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel'
import ScrollTop from 'primevue/scrolltop';
import Slider from 'primevue/slider';
import Sidebar from 'primevue/sidebar';
import Skeleton from 'primevue/skeleton';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Steps from 'primevue/steps';
import StyleClass from 'primevue/styleclass';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Timeline from 'primevue/timeline';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Tooltip from 'primevue/tooltip';
import ToggleButton from 'primevue/togglebutton';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import TriStateCheckbox from 'primevue/tristatecheckbox';

import CodeHighlight from './AppCodeHighlight';
import BlockViewer from './BlockViewer';

import vVisible from './directives/v-visible.js';

import { registerChart } from '@mescius/wijmo.vue2.chart';
import { registerChartAnalytics } from '@mescius/wijmo.vue2.chart.analytics';
import { registerChartAnimation } from '@mescius/wijmo.vue2.chart.animation';
import { registerChartAnnotation } from '@mescius/wijmo.vue2.chart.annotation';
import { registerChartFinance } from '@mescius/wijmo.vue2.chart.finance';
import { registerChartHierarchical } from '@mescius/wijmo.vue2.chart.hierarchical';
import { registerChartInteraction } from '@mescius/wijmo.vue2.chart.interaction';
import { registerChartRadar } from '@mescius/wijmo.vue2.chart.radar';
import { registerCore } from '@mescius/wijmo.vue2.core';
import { registerGauge } from '@mescius/wijmo.vue2.gauge';
import { registerGrid } from '@mescius/wijmo.vue2.grid';
import { registerGridDetail } from '@mescius/wijmo.vue2.grid.detail';
import { registerGridFilter } from '@mescius/wijmo.vue2.grid.filter';
import { registerGridGrouppanel } from '@mescius/wijmo.vue2.grid.grouppanel';
import { registerGridMultirow } from '@mescius/wijmo.vue2.grid.multirow';
import { registerGridSearch } from '@mescius/wijmo.vue2.grid.search';
import { registerGridSheet } from '@mescius/wijmo.vue2.grid.sheet';
import { registerGridTransposed } from '@mescius/wijmo.vue2.grid.transposed';
import { registerGridTransposedMultirow } from '@mescius/wijmo.vue2.grid.transposedmultirow';
import { registerInput } from '@mescius/wijmo.vue2.input';
import { registerNav } from '@mescius/wijmo.vue2.nav';
import { registerOlap } from '@mescius/wijmo.vue2.olap';
import { registerViewer } from '@mescius/wijmo.vue2.viewer';

// import VueShortkey from 'vue-shortkey'; // npm install vue-shortkey --save  npm install --save vue-hotkey

wijmo.setLicenseKey("stage1.web-scm.com|dev.web-scm.com|uf.scm-core.com|app.scm-core.com|demo.scmcore.space|82.144.204.11|dev.scm-core.com|dev1.web-scm.com|dev2.web-scm.com,379595323164188#B0JoIIh94QiwiI8gTM4YTMzIzM5kTN9czMiojIklkIs4XXbpjInxmZiwiIyYnMyAjMiojIyVmdiwSZzxWYmpjIyNHZisnOiwmbBJye0ICRiwiI34zdzMES5sSSDFkQpFVWXlndvFGMWRDeohnUWlkQxhjcJdnZ79kbB54Mr8mb5g6YMNlQlpHWDJFMPBTOMNmbvdXdIdzSNVWMzRzTqVURXl7S7M5QM5WVSJjSsJjWVJzN7RkURR4UW3yTrI4bOZmQOVmT9cmRPRWQzoXajNjZo3SWM5UZLVXOSNWYTVldo94dLlFUnNVYMp4MzU5RyU5SsZXR5klS9gnavMXd7gWbwczdll6ZihmRoRjZqhnY7hkdKdXW9dTTj9ER55kbVRTbYBDe6hVdE3GS7QXN7ZneX5EV7oWVXR7VvMWUT54amZndURGU6QXO8QHMhF5btl5UwcTOadUYGFXeF3mesp7buh6Y0hjUyNHdGZXV7xWNhZjU9VFa0JkTEVmMVdDMRNmTRlFawk6drgUZhV6NQlzcLdDM9N4UCZXa5wUeKJmRoJXTVdGcaRTazllI0IyUiwiIDV4NwEURzIjI0ICSiwCO4MjNyADM9gTM0IicfJye#4Xfd5nIJBjMSJiOiMkIsIibvl6cuVGd8VEIgQXZlh6U8VGbGBybtpWaXJiOi8kI1xSfiUTSOFlI0IyQiwiIu3Waz9WZ4hXRgAicldXZpZFdy3GclJFIv5mapdlI0IiTisHL3JyS7gDSiojIDJCLi86bpNnblRHeFBCI73mUpRHb55EIv5mapdlI0IiTisHL3JCNGZDRiojIDJCLi86bpNnblRHeFBCIQFETPBCIv5mapdlI0IiTisHL3JyMDBjQiojIDJCLiUmcvNEIv5mapdlI0IiTisHL3JSV8cTQiojIDJCLi86bpNnblRHeFBCI4JXYoNEbhl6YuFmbpZEIv5mapdlI0IiTis7W0ICZyBlIsISOyQjM5ADI6ATNwQjMwIjI0ICdyNkIsISbvNmLtN6ctIWZ79iM6VGZs46bj9SbjNXLiV6duEjdlRGLt36YuUmcvNWLtN6cuYXZkxSMx8CNwIjL4QTMuIDOsU6YhB7cuUmcvNWbjNnLv5WZkxSbvNmLlJ7bj5SbjNnLwBXYs46bj9SZy36Yt46Yz9iZ5xSbvNmLtN6ctIWZ79idlRGLt36Yu46Yz5iYldnLxU6ZhR7ciojIz5GRiwiI1JuQ");

const app = createApp(AppWrapper);

registerChart(app);
registerChartAnalytics(app);
registerChartAnimation(app);
registerChartAnnotation(app);
registerChartFinance(app);
registerChartHierarchical(app);
registerChartInteraction(app);
registerChartRadar(app);
registerCore(app);
registerGauge(app);
registerGrid(app);
registerGridDetail(app);
registerGridFilter(app);
registerGridGrouppanel(app);
registerGridMultirow(app);
registerGridSearch(app);
registerGridSheet(app);
registerGridTransposed(app);
registerGridTransposedMultirow(app);
registerInput(app);
registerNav(app);
registerOlap(app);
registerViewer(app);

app.config.globalProperties.$appState = reactive({ theme: 'lara-light-indigo', darkTheme: false });

// app.use(VueShortkey);
app.use(GlobalCmComponent);

app.use(PrimeVue, { ripple: true, inputStyle: 'outlined' });
app.use(ConfirmationService);
app.use(ToastService);
app.use(router);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('code', CodeHighlight);
app.directive('badge', BadgeDirective);
app.directive('styleclass', StyleClass);
app.directive('visible', vVisible)

app.component('Accordion', Accordion);
app.component('AccordionTab', AccordionTab);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('Calendar', Calendar);
app.component('Card', Card);
app.component('Carousel', Carousel);
app.component('Chart', Chart);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('Chips', Chips);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DataViewLayoutOptions', DataViewLayoutOptions);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
// app.component('Dropdown', Dropdown);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('Image', Image);
app.component('InlineMessage', InlineMessage);
app.component('Inplace', Inplace);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputSwitch', InputSwitch);
app.component('InputText', InputText);
app.component('Galleria', Galleria);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('OverlayPanel', OverlayPanel);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Sidebar', Sidebar);
app.component('Skeleton', Skeleton);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Steps', Steps);
app.component('TabMenu', TabMenu);
app.component('TabView', TabView);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('TriStateCheckbox', TriStateCheckbox);
app.component('BlockViewer', BlockViewer);

const pinia = createPinia();
app.use(pinia);

import Widget from './components/widgets/Widget.vue'; app.component('Widget', Widget);
import AttrTab from './components/widgets/components_core/AttrTab.vue'; app.component('AttrTab', AttrTab);
import AttrTabRow from './components/widgets/components_core/AttrTabRow.vue'; app.component('AttrTabRow', AttrTabRow);
import Attr from './components/widgets/components_core/Attr.vue'; app.component('Attr', Attr);
import AttrStr from './components/widgets/components_core/AttrStr.vue'; app.component('AttrStr', AttrStr);
import AttrHTML from './components/widgets/components_core/AttrHTML.vue'; app.component('AttrHTML', AttrHTML);
import AttrSeparator from './components/widgets/components_core/AttrSeparator.vue'; app.component('AttrSeparator', AttrSeparator);
import AttrFlowchart from './components/widgets/components_core/AttrFlowchart.vue'; app.component('AttrFlowchart', AttrFlowchart);
import AttrLink from './components/widgets/components_core/AttrLink.vue'; app.component('AttrLink', AttrLink);
import AttrLinkOdoo from './components/widgets/components_core/AttrLinkOdoo.vue'; app.component('AttrLinkOdoo', AttrLinkOdoo);
import AttrBool from './components/widgets/components_core/AttrBool.vue'; app.component('AttrBool', AttrBool);
import AttrButton from './components/widgets/components_core/AttrButton.vue'; app.component('AttrButton', AttrButton);
import AttrMenu from './components/widgets/components_core/AttrMenu.vue'; app.component('AttrMenu', AttrMenu);
import AttrCommandPanel from './components/widgets/components_core/AttrCommandPanel.vue'; app.component('AttrCommandPanel', AttrCommandPanel);
import AttrNumber from './components/widgets/components_core/AttrNumber.vue'; app.component('AttrNumber', AttrNumber);
import AttrDate from './components/widgets/components_core/AttrDate.vue'; app.component('AttrDate', AttrDate);
import AttrTime from './components/widgets/components_core/AttrTime.vue'; app.component('AttrTime', AttrTime);
import AttrGrid from './components/widgets/components_core/AttrGrid.vue'; app.component('AttrGrid', AttrGrid);
import AttrSheet from './components/widgets/components_core/AttrSheet.vue'; app.component('AttrSheet', AttrSheet);
import AttrTree from './components/widgets/components_core/AttrTree.vue'; app.component('AttrTree', AttrTree);
import AttrGantt from './components/widgets/components_core/AttrGantt.vue'; app.component('AttrGantt', AttrGantt);
import AttrTabPanel from './components/widgets/components_core/AttrTabPanel.vue'; app.component('AttrTabPanel', AttrTabPanel);
import AttrJSON from './components/widgets/components_core/AttrJSON.vue'; app.component('AttrJSON', AttrJSON);
import AttrStrMulti from './components/widgets/components_core/AttrStrMulti.vue'; app.component('AttrStrMulti', AttrStrMulti);
import AttrChart from './components/widgets/components_core/AttrChart.vue'; app.component('AttrChart', AttrChart);
import AttrBubbleChart from './components/widgets/components_core/AttrBubbleChart.vue'; app.component('AttrBubbleChart', AttrBubbleChart);
import AttrBubbleWithAnimationChart from './components/widgets/components_core/AttrBubbleWithAnimationChart.vue'; app.component('AttrBubbleWithAnimationChart', AttrBubbleWithAnimationChart);
import AttrTreeMap from './components/widgets/components_core/AttrTreeMap.vue'; app.component('AttrTreeMap', AttrTreeMap);
import AttrPivotTool from './components/widgets/components_core/AttrPivotTool.vue'; app.component('AttrPivotTool', AttrPivotTool);
import EmptyIcon from './components/icons/EmptyIcon.vue'; app.component('EmptyIcon', EmptyIcon);
import AttrGraph from './components/widgets/components_core/AttrGraph.vue'; app.component('AttrGraph', AttrGraph);

import Dropdown from './components/widgets/Dropdown.vue'; app.component('Dropdown', Dropdown);
// const dropdown = createApp(Dropdown);
// const dropdownInstance = dropdown.mount(document.createElement('div'));
// document.body.appendChild(dropdownInstance.$el);
// app.config.globalProperties.$dropdown = dropdownInstance;

// import WjComboBox from "@mescius/wijmo.vue2.input"; app.component('wj-combo-box', WjComboBox)

// function getCookie(name) {
//     let cookieValue = null;
//     if (document.cookie && document.cookie !== '') {
//         const cookies = document.cookie.split(';');
//         for (let i = 0; i < cookies.length; i++) {
//             const cookie = cookies[i].trim();
//             if (cookie.substring(0, name.length + 1) === (name + '=')) {
//                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
//                 break;
//             }
//         }
//     }
//     return cookieValue;
// }

// function fetchCSRFToken() { // django.middleware.csrf.CsrfViewMiddleware (1)
//     console.log('/get-csrf-token/')
//     return fetch(`${config_database.url_backend}/get-csrf-token/`, {
//     // return fetch('http://localhost:8022/get-csrf-token/', {
//             credentials: 'include'
//         })
//         .then(response => {
//             if (!response.ok) {
//                 throw new Error('Network response was not ok');
//             }
//             return response.json();
//         })
//         .then(data => {
//             const csrftoken = data.csrfToken;
//             $.ajaxSetup({
//                 headers: {
//                     'X-CSRFToken': csrftoken
//                 },
//                 xhrFields: {
//                     withCredentials: true
//                 }
//             });
//             console.log(data)
//             return csrftoken;
//         });
// }

// fetchCSRFToken().then(() => {
    app.mount('#app');
// }).catch(error => {
//     console.error('Error fetching CSRF token:', error);
// });