import * as versionF from '../../version';
import { defineStore } from 'pinia'
import * as wjcCore from '@mescius/wijmo';
import * as wjGrid from '@mescius/wijmo.grid';
import * as wjcOlap from '@mescius/wijmo.olap';
import * as config_database from '../../config_database';

import * as wjcGridXlsx from '@mescius/wijmo.grid.xlsx';
import * as gridPdf from '@mescius/wijmo.grid.pdf';
import * as pdf from '@mescius/wijmo.pdf';
import { CellRange, ClipStringOptions } from '@mescius/wijmo.grid';
import { saveFile } from '@mescius/wijmo';
import { version } from 'process';
import { reactive } from 'vue'

export const useMainStore = defineStore('mainStore', {
    state: () => ({
        version_frontend: versionF.version_frontend,
        version_backend: '',
        url_backend_login: `${config_database.url_backend}/login/`,
        url_backend_api: `${config_database.url_backend}/api/`,
        url: 'https://web-scm.com/',
        process_model_doc: {id:''},
        user: {}, // {id:'user_admin', title:'admin'},
        menuTree: [],
        menuTree_model: [],
        database_attrs: [],
        user_attrs: [
            { 'title': 'My profile', 'command': 'onOpenMyProfile__Click' },
            { 'title': 'Log out', 'command': 'onLogOut__Click' },
        ],
        showSQL: false,
        action_id_front: 0,
        isAuthenticated: false,
        WORKSPACES: {},
        workspace_ind: 1,

        dragStart_widget_id: null,
        dragOver_widget_id: null,

        // active
        activeWORKSPACE: null,
            // .activePAGE, active_page
                // .leftArea
                activeWIDGET: null,
                // .rightArea
        
        nextWidget_id: 0,
        attr_dragged: null,
        attr_active: null,
        attr_activeEl: null,
        swapOnDragAndDrop: false,
        itemsSource: {},
        itemsSource_change_index: 0,

        attr_findDragSourceEl: () => document.querySelector('.attr.drag-source'),
        attr_findDragTargetEl: () => document.querySelector('.attr.drag-over'),
        attr_findClosestWidgetEl: event => wjcCore.closest(event.target, '.attr'),

        findDragSourceEl: () => document.querySelector('.WIDGET.drag-source'),
        findDragTargetEl: () => document.querySelector('.WIDGET.drag-over'),
        findClosestWidgetEl: event => wjcCore.closest(event.target, '.WIDGET'),

        setDragSourceEl: el => wjcCore.addClass(el, 'drag-source'),
        setDragTargetEl: el => wjcCore.addClass(el, 'drag-over'),
        unsetDragSourceEl: el => wjcCore.removeClass(el, 'drag-source'),
        unsetDragTargetEl: el => wjcCore.removeClass(el, 'drag-over'),
        setActiveEl: el => wjcCore.addClass(el, 'active'),
        unsetActiveEl: el => wjcCore.removeClass(el, 'active'),

        palette: [
            '#8e99f3',
            '#ffca28',
            '#5c6bc0',
            '#bbdefb',
        ],
        dateFormats: [
            // 'WORKSPACE',
            // 'dd',
            // 'DD.MM.YYYY hh:mm:ss',
            'yyyy/MM/dd', 'yyyy-MM-dd', 'yyyy.MM.dd', 'dd/MM/yyyy', 'yyyyMMdd', 'yyMMdd',
            'yyyy-MM-dd/ffff-ff-ff',
            'yyyy/MM', 'yyyy-MM', 'yyyy.MM', 'yyyy MM',
            'yyyy/M', 'yyyy-M', 'yyyy.M', 'yyyy M',
            'yyyy/M/dd', 'yyyy-M-dd', 'yyyy.M.dd', 'dd/M/yyyy',
            'MM/dd/yy', 'MM-dd-yy', 'MM.dd.yy', 'MM dd yy', 'MM dd, yy',
            'dd MM yyyy', 'dd-MM-yyyy', 'dd.MM.yyyy', 'dd MM yyyy',
            'MM/dd/yyyy', 'MM-dd-yyyy', 'MM.dd.yyyy', 'MM dd yyyy', 'MM dd, yyyy',
            'D',
            'M',
            'yyyy',
            'yyyy-MM-dd HH:mm:ss.ffffff',
            'yyyy-MM-dd HH:mm:ss',
            'yyyy.MM.dd HH:mm:ss',
            'dd.MM.yyyy HH:mm:ss',
        ],

        notification: {
            header: '',
            text: '',
            status: false,
            visible: false,
            fading: false,
            closing: false,
            timerId: null,
            statuses: { // timeout
                info: 10001,
                warning: 10002,
                error: 10003,
                critical: 10004,
                info_critical: 10005,
            }
        },

        // test
        i_test:0,
    }),
    actions: {
        setup(to, next) {
            const store = this

            store.WORKSPACES.length = 0
            store.activeWORKSPACE = null
            store.activeWIDGET = null

            store.get_process_model(to, next)
            store.async_get_itemsSource_data({}, { segment: 'SEGMENTS' })
        },
        async widget_open(WIDGET={}, parent_vueObj={}, callback=null, callback_params=null) {
            const store = this
            if (!WIDGET.workspace) {
                WIDGET.workspace = store.activeWORKSPACE.workspace
            }
            const WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            if (!WIDGET.page) {
                WIDGET.page = WORKSPACE.active_page
            }
            const PAGE = WORKSPACE.PAGES[WIDGET.page]
            let WIDGETS = PAGE.WIDGETS

            WIDGET.id = store.nextWidget_id++
            WIDGET.class = { WIDGET: true }
            WIDGET.data = {}
            WIDGET.nodes_svg = {} // info
            WIDGET.attrs_vueObj = []
            WIDGET.notification = {
                header: '',
                text: '',
                status: false,
                visible: false,
                fading: false,
                closing: false,
                timerId: null,
                statuses: { // timeout
                    info: 10001,
                    warning: 10002,
                    error: 10003,
                    critical: 10004,
                    info_critical: 10005,
                }
            }
            WIDGET.editAttrs = false
            WIDGET.attrsResize = []
            WIDGET.applys_filterChips = {}
            WIDGET.timerId = null
            WIDGET.style = ``
            WIDGET.hidden = true
            WIDGET.styleWidget = {
                marginTop: 3,
                marginRight: 3,
                marginBottom: 3,
                marginLeft: 3,
        
                top_rem:0,
                left_rem:0,
                width_rem:1,
                height_rem:1,

                // widthMin_rem:1, in doc
                // heightMin_rem:1,
            }

            if (parent_vueObj) {
                const parent_WIDGET = parent_vueObj.WIDGET
                if (parent_WIDGET) {

                    // parentWidget
                    WIDGET.parentWidget = { 
                        id: parent_WIDGET.id,
                        title: parent_WIDGET.title,
                        widget: parent_WIDGET.widget,
                        doc_id: parent_WIDGET.doc?.id,
                    }
                    WIDGET.workspace = parent_WIDGET.workspace

                    if (parent_WIDGET.doc?.segment === "TASKS-transform" ||parent_WIDGET.doc?.segment === "MAPPING-RULES") {
                        WIDGET.parentWidget.doc = parent_WIDGET.doc
                    }
                }
            }

            if (!('params_onOpen' in WIDGET)) {
                WIDGET.params_onOpen = {}
            }

            // find unique WIDGET with props (1)
            if ('props' in WIDGET && WIDGET.widget_class) {
                let widgetExsisit = store.findWidget_byProps(WIDGET.props, WIDGET.widget_class)
                if (widgetExsisit) {
                    console.log(`widgetExsisit (1): ${widgetExsisit.id}`)
                    store.widget_activate(widgetExsisit)
                    return
                }
            }

            // WORKSPACE.doc
            // if (WORKSPACE.doc && !(WIDGET.doc && WORKSPACE.doc.id === WIDGET.doc.id)) { 2024-07-31
            //     WIDGET.workspace_doc = WORKSPACE.doc
            // }
            // WIDGET.process_model_doc = store.process_model_doc 2024-07-31

            // open_in_children_widget
            let close_widget = null
            // if ('parentWidget' in WIDGET) {
            //     const parent = store.findWidget(WIDGET.parentWidget.id)
            //     if (parent.WIDGET.area === 'left-area' && WIDGET.parentActionMenu && WIDGET.parentActionMenu.open_in_children_widget && parent.WIDGET.children_widget_id) {
            //         close_widget = store.findWidget(parent.WIDGET.children_widget_id).WIDGET
            //         if (close_widget) {
            //             close_widget.loading = true
            //         }
            //     }
            // }

            WIDGET.title0 = WIDGET.title || WIDGET.parentActionMenu?.title || WIDGET.doc?.id
            WIDGET.component = 'WidgetAttrs'
            WIDGET.firstBackendCheck = true
            store.widgets_created(WIDGET)
            
            const { attrs_vueObj, ...widget_init } = WIDGET
            try {
                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'widget_open',
                        showSQL: store.showSQL,
                        process_model: store.process_model_doc.id,
                        workspace: WIDGET.workspace, // 2024-07-31
                        widget_init: widget_init,
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',

                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        const notification_frontend = null // {
                        store.TERMINAL(WIDGET, answer, notification_frontend, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(null, answer)) return
                        if (['critical'].includes(answer.notification?.status) || !answer.WIDGET) {
                            return
                        } 

                        if ('props' in answer.WIDGET && answer.WIDGET.doc?.segment) {
                            answer.WIDGET.props.segment = answer.WIDGET.doc.segment
                        }
                        // find unique WIDGET with props (2)
                        if ('props' in WIDGET && answer.WIDGET.widget_class && !close_widget) { // && !WIDGET.widget_class 
                            let widgetExsisit = store.findWidget_byProps(answer.WIDGET.props, answer.WIDGET.widget_class)
                            if (widgetExsisit) {
                                console.log(`widgetExsisit (2): ${widgetExsisit.id}`)
                                store.widget_activate(widgetExsisit)
                                return
                            }
                        }

                        Object.assign(WIDGET, answer.WIDGET)
                        WIDGET.firstBackendCheck = false
                        // WIDGET.attrs_popupTab = store.attr_find_by_keys(WIDGET.attrs, 'attrs_popupTab', ['name'])?.attrs

                        if (WIDGET.doc && WIDGET.doc.cssClassW) {
                            WIDGET.cssClassW = WIDGET.doc.cssClassW
                        }
                        if (WIDGET.doc && WIDGET.doc.cssClassH) {
                            WIDGET.cssClassH = WIDGET.doc.cssClassH
                        }

                        // ?is closed
                        const current = store.findWidget(WIDGET.id)
                        if (current.ind === null) {
                            return
                        }

                        store.set_nonEmptyWidget(WIDGET)
                        store.set_isMappedWidget(WIDGET)
                        WIDGET.attrs_vueObj.WIDGET.set_commandPanel()

                        // store.widgets_created(WIDGET) ...2
                        store.check_childWidgets(null, WIDGET)
                        store.check_childWidgets(WIDGET)
                        if (WIDGET.vueObj) {
                            // WIDGET.vueObj?.content_Changed()
                            store.widget_checkLoading(WIDGET.vueObj)
                        }
                        PAGE?.mainArea?.vueObj.checkWorkspaceScroll()
            
                        // contextWidgetMenu
                        if (WIDGET.groupMenu) {
                            WIDGET.groupMenu.contextWidgetMenu = []
                            if (WIDGET.groupMenu.actionMenu) {
                                // Open up menu ‘action’ in contextWidgetMenu
                                let indMemu = 0
                                while (indMemu < WIDGET.groupMenu.actionMenu.length) {
                                    let itemMenu = WIDGET.groupMenu.actionMenu[indMemu]
                                    if (itemMenu.title === '-') {
                                        indMemu++;
                                        break
                                    }
                                    WIDGET.groupMenu.contextWidgetMenu.push(itemMenu)
                                    indMemu++;
                                }
                                if (indMemu < WIDGET.groupMenu.actionMenu.length) {
                                    let itemMenu2 = { 'title': 'Action', 'attrs': [] }
                                    while (indMemu < WIDGET.groupMenu.actionMenu.length) {
                                        let itemMenu = WIDGET.groupMenu.actionMenu[indMemu]
                                        itemMenu2.attrs.push(itemMenu)
                                        indMemu++;
                                    }
                                    WIDGET.groupMenu.contextWidgetMenu.push(itemMenu2)
                                }
                            }

                            if (WIDGET.groupMenu.flexGridMenu) WIDGET.groupMenu.contextWidgetMenu.push({ 'title': 'Grid', 'attrs': WIDGET.groupMenu.flexGridMenu })
                            if (WIDGET.groupMenu.subwidget) WIDGET.groupMenu.contextWidgetMenu.push({ 'title': 'subwidget', 'attrs': WIDGET.groupMenu.subwidget })
                            if (WIDGET.groupMenu.moreMenu) WIDGET.groupMenu.contextWidgetMenu.push({ 'title': 'More', 'attrs': WIDGET.groupMenu.moreMenu })
                            if (WIDGET.groupMenu.windowMenu) WIDGET.groupMenu.contextWidgetMenu.push({ 'title': 'Window', 'attrs': WIDGET.groupMenu.windowMenu })
                        }

                        if (WIDGET.widget === 'widget-selection') {
                            PAGE.WIDGET_selection = WIDGET
                        }
                        if (callback) {
                            callback(WIDGET, ...callback_params)
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                    },
                })
            } catch (error) {
                store.TERMINAL(WIDGET, null, {text:error, status:'error'})
            }
        },
        widgets_created(WIDGET) {
            let store = this,
            WORKSPACE = store.WORKSPACES[WIDGET.workspace],
            PAGE = WORKSPACE.PAGES[WIDGET.page],
            WIDGETS = PAGE.WIDGETS,
            parent_ind = null

            if ('parentWidget' in WIDGET) {
                //place the WIDGET after the parent
                const parent = store.findWidget(WIDGET.parentWidget.id)
                // if (close_widget) {
                //     store.widget_close(close_widget.id)
                // }
                parent.WIDGET.children_widget_id = WIDGET.id

                if (parent.workspace === WIDGET.workspace && parent.ind < WIDGETS.length - 1) {
                    parent_ind = parent.ind + 1
                }
            }

            if (parent_ind === null) {
                for (let i = 0; i < WIDGETS.length; i++) {
                    if (WIDGETS[i].id > WIDGET.id && (parent_ind === null || WIDGETS[i].id < WIDGETS[parent_ind].id)) {
                        parent_ind = i
                    }
                }
            }

            WIDGET.isVisible = false

            if (parent_ind !== null) {
                WIDGETS.splice(parent_ind, 0, WIDGET)
            } else {
                WIDGETS.push(WIDGET)
            }

            this.preventVerticalScroll(document.body)

            // const pageYOffset = window.scrollY
            // PAGE.mainArea.vueObj.$nextTick(() => {
            //     window.scrollTo(0, pageYOffset)
            // })

        },
        preventVerticalScroll(e) {
            const hasScroll = e.scrollHeight > e.clientHeight
            if (!hasScroll && !e.classList.contains('no-scroll')) {
                e.classList.add('no-scroll')
            }
        },
        widget_activate(WIDGET, run_scrollInWorkspace = true) {
            const store = this

            if (store.activeWIDGET === WIDGET) {
                return
            } else if (WIDGET?.widget_activate === false) {
                return
            // } else if (WIDGET?.area === 'right-area') {
            //     return
            // } else if (WIDGET?.area === 'left-area') {
            //     return
            }
            if (WIDGET?.workspace) {
                console.log('widget_activate ' + WIDGET.id)
                let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
                let PAGE = WORKSPACE.PAGES[WIDGET.page]

                // activeWORKSPACE
                if (store.activeWORKSPACE !== WORKSPACE) {
                    store.activeWORKSPACE = WORKSPACE
                }

                if (store.activeWIDGET !== WORKSPACE.activeWIDGET) {
                    store.activeWIDGET = WORKSPACE.activeWIDGET
                    if (store.activeWIDGET === WIDGET) {
                        return
                    }
                }

                // // deactivate WIDGET
                // if (store.activeWIDGET && store.activeWIDGET.workspace==workspace && $('#widget_title'+store.activeWIDGET.id)[0]) {
                //     $('#widget_title'+store.activeWIDGET.id)[0].classList.toggle("widget-active")
                // } else if (WORKSPACE.activeWIDGET && WORKSPACE.activeWIDGET !== WIDGET && $('#widget_title'+WORKSPACE.activeWIDGET.id)[0]) {
                //     $('#widget_title'+WORKSPACE.activeWIDGET.id)[0].classList.toggle("widget-active")
                // }

                // activate WIDGET
                if (WIDGET) {
                    store.activeWIDGET = WIDGET
                    const active_WidgetHeader = $('#widget_title' + store.activeWIDGET.id)[0]
                    const WidgetContainer = $('#WIDGET' + store.activeWIDGET.id)[0]
                    if (WidgetContainer) {
                        PAGE.activeWIDGET = store.activeWIDGET
                        // // activate
                        // active_WidgetHeader.classList.toggle("widget-active")

                        // // focus
                        // const flex = store.activeWIDGET.vueObj.active_flex()
                        // if (flex) {
                        //     flex.focus()
                        // }

                        // // cancel minimization
                        // let WidgetContainer = $('#WIDGET'+WIDGET.id)[0] // document.querySelector(`#WIDGET${WIDGET.id}`)
                        // if (WidgetContainer.classList.contains('widget-h0')) {
                        //     WidgetContainer.classList.remove('widget-h0')
                        // }

                        // scroll
                        if (run_scrollInWorkspace) {
                            store.scrollInWorkspace(WIDGET)
                        } else {
                            console.log('// scrollInWorkspace')
                        }
                    } else {
                        store.activeWIDGET = null
                    }
                }
            } else {
                store.activeWIDGET = null
            }

            // store.rightArea_hide()
        },
        widget_close(widget_id_toRemove, reloadParent = false) {
            const store = this
            const WIDGET = store.findWidget(widget_id_toRemove).WIDGET

            if (!WIDGET) {
                // pass
            // } else if (WIDGET.area === 'right-area') {
            //     store.rightArea_hide(true)
            // } else if (WIDGET.area === 'left-area') {
            //     store.leftArea_hide(true)
            } else {
                WIDGET.close = true

                let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
                let PAGE = WORKSPACE.PAGES[WIDGET.page]
                let WIDGETS = PAGE.WIDGETS

                if (WIDGET.widget === 'widget-selection') {
                    PAGE.WIDGET_selection = null
                }

                PAGE.vueObj.removeNodes(WIDGET)

                // activate parent,...
                if (PAGE.activeWIDGET === WIDGET) {
                    let widget_toActivate = null
                    if ('parentWidget' in WIDGET) {
                        widget_toActivate = store.findWidget(WIDGET.parentWidget.id).WIDGET
                        if (widget_toActivate && reloadParent && widget_toActivate.page === 'WidgetFlexGrid') { // reloadAfterChangeChild
                            store.executeStoreMethod(widget_toActivate.vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'] })
                        }
                    }
                    if (!widget_toActivate && WIDGETS.length > 1) {
                        //find first in PAGE
                        if (WIDGETS[0] !== WIDGET) {
                            widget_toActivate = WIDGETS[0]
                        } else {
                            widget_toActivate = WIDGETS[1]
                        }
                    }
                    store.widget_activate(widget_toActivate)
                }
                
                // let WidgetContainer = document.querySelector(`#WIDGET${WIDGET.id}`)
                // WIDGET.class['leave-active'] = true // wjcCore.addClass(WidgetContainer, 'leave-active')
                // WidgetContainer.addEventListener('transitionend', () => {
                    WIDGETS.splice(store.findWidget(widget_id_toRemove).ind, 1)
                    store.check_childWidgets(null, WIDGET)
                    // store.leftArea_setVisible()
                // }, { once: true })
            }
            // store.leftArea_setVisible()
            this.preventVerticalScroll(document.body)
        },

        // ----------------------- workspace -----------------------
        async workspace_open(workspace, page='') {
            const store = this
            const WORKSPACE = store.WORKSPACES[workspace]
            if (store.activeWORKSPACE?.workspace === workspace) {
                if (store.activeWORKSPACE.active_page === page || !page) {
                    return WORKSPACE // FIXIT is error case
                } else {
                    return store.workspace_route(workspace, page)
                }
            } else if (WORKSPACE) {
                if (!page) {
                    page = WORKSPACE.active_page
                }
                return store.workspace_route(workspace, page)
            }

            try {
                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'workspace_open',
                        showSQL: store.showSQL,
                        process_model: store.process_model_doc.id,
                        workspace_init: {
                            workspace: workspace,
                            active_page: page,
                            workspaceClass: 'workspace_main',
                        },
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',

                    success: function (answer, textStatus, jqXHR) {
                        store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(null, answer)) return
                        if (['critical'].includes(answer.notification?.status)) {
                            let to = store.router.currentRoute
                            if (to.query.workspace !== 'workspace-settings') {
                                store.router.push({ name:to.name, params:to.params, query: { ...to.query,
                                    workspace: 'workspace-settings',
                                    page: '',
                                }})
                            }
                            return
                        }

                        // WORKSPACE++
                        let WORKSPACE = store.WORKSPACES[workspace] = {
                            // attrs: []
                            notification: {
                                header: '',
                                text: '',
                                status: false,
                                visible: false,
                                fading: false,
                                closing: false,
                                timerId: null,
                                statuses: { // timeout
                                    info: 10001,
                                    warning: 10002,
                                    error: 10003,
                                    critical: 10004,
                                    info_critical: 10005,
                                }
                            },
                            selectionWorkspace: {},
                            ...answer.WORKSPACE,
                        }
                        page = WORKSPACE.active_page
        
                        // menu++
                        let menuNode = store.find_menuNode(workspace)
                        if (!menuNode) {
                            let [to, path_to] = store.workspace_route_to(workspace, page)
                            let menu_str = {
                                to: to,
                                label: WORKSPACE.doc.title,
                                img: WORKSPACE.doc.img,
                            }
                            if (!menu_str.img) {
                                menu_str.pi = WORKSPACE.doc.pi || `pi pi-globe`
                            }
                            if (!('items' in store.menu_model_WORKSPACES)) {
                                store.menu_model_WORKSPACES.items = []
                            }
                            store.menu_model_WORKSPACES.items.push(menu_str)
                        }

                        return store.workspace_route(workspace, page)
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'error'})
                    },
                })
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'})
            }
        },
        workspace_route(workspace, page) {
            const store = this,
                WORKSPACE = store.WORKSPACES[workspace],
                PAGE = WORKSPACE.PAGES[page]

            let [to, path_to] = store.workspace_route_to(workspace, page)
            const pathName = `${workspace}/${page}`

            // // router++
            // if (!store.router.hasRoute(pathName)) {
            //     store.router.addRoute('app', {
            //         path: path_to,
            //         name: pathName,
            //         component: () => import('../pages/Page.vue'), // `../pages/${PAGE.component}.vue`
            //         props: {
            //             workspace: workspace,
            //             page: page,
            //             dbid: store.user.database.dbid
            //         },
            //     })
            // }

            // go
            store.router.push(to)
        },
        workspace_route_to(workspace, page='') {
            const store = this
            
            // check PAGES.length
            if (page) {
                let WORKSPACE = store.WORKSPACES[workspace]
                if (Object.keys(WORKSPACE.PAGES).length < 2){
                    page = ''
                }
            }

            if (!page) {
                return [
                    `/${store.user.database.dbid}?process_model=${store.process_model_doc.id}&workspace=${workspace}`,
                    `/${workspace}`,
                ]
            } else {
                return [
                    `/${store.user.database.dbid}?process_model=${store.process_model_doc.id}&workspace=${workspace}&page=${page}`,
                    `/${workspace}/${page}`,
                ]
            }
        },
        workspace_activate(workspace, page) {
            const store = this
            const WORKSPACE = store.WORKSPACES[workspace]
            const PAGE = WORKSPACE.PAGES[page]
            const WIDGETS = PAGE.WIDGETS
            // const leftArea = PAGE.leftArea

            // set active
            store.activeWORKSPACE = WORKSPACE
            WORKSPACE.active_page = page
            WORKSPACE.activePAGE = PAGE

            // update WIDGETS
            // if (page === 'page-settings') {
            //     // WIDGETS.length = 0

            //     // onShowSettings__Click
            //     if (!WIDGETS.length && WORKSPACE.doc) {
            //         store.executeStoreMethod({}, { 'command': 'onWorkspaceShowSettings__Click' })
            //     }
            // } else 
            // if (workspace === 'workspace-settings' && page === 'page-processModel') {
            //     if (!WIDGETS.length) {

            //         // show process_model
            //         if (store.process_model_doc) {
            //             store.widget_open({
            //                 workspace: workspace,
            //                 widget: 'widget-DOC',
            //                 parentWidget: { id: workspace, title: workspace },
            //                 parentActionMenu: { 'action_id':'action-front-5', method: 'store.workspace_activate' },
            //                 doc_take_from_front: true,
            //                 doc: store.JSON_sort(store.process_model_doc),
            //                 props: {
            //                     props_for_widget: workspace
            //                 }
            //             })
            //         } else {
            //             store.executeStoreMethod({}, { 'command': 'chooseProcessModel' })
            //         }
            //     }
            // } else
            // if (leftArea && leftArea.WIDGET && PAGE.closeWidgets_OnActivate) { // page-calculationList
            //     WIDGETS.length = 0
            //     leftArea.visible = true
            //     store.leftArea_setVisible()
            //     store.executeStoreMethod(leftArea.WIDGET.vueObj, { 'title': 'Select', 'command': 'executeWidgetMethod', 'method': 'select' })
            // } else 
            if (WIDGETS.length) {
                if (!PAGE.updateRelatedWidgets_onWorkspaceActivate_disabled) {
                    store.updateRelatedWidgets(workspace, page)
                }
            } else {
                store.workspace_show_widgets(workspace, page)
            }

            // activate WIDGET
            if (store.activeWIDGET !== PAGE.activeWIDGET) {
                store.activeWIDGET = PAGE.activeWIDGET
                // store.widget_activate(store.activeWIDGET)
            }

            return WORKSPACE
        },

        workspace_show_widgets(workspace, page) {
            const store = this
            const WORKSPACE = store.WORKSPACES[workspace]
            const PAGE = WORKSPACE.PAGES[page]
            const WIDGETS = PAGE.WIDGETS
            let widgets_onOpening = []
        
            function scan_widgets_onOpening(attrs) {
                attrs.forEach(row => {
                    if (row.use === false) return
        
                    if (row.widget && row.onOpening) {
                        widgets_onOpening.push({
                            action_id: 'action-front-1',
                            command: 'widget_open',
                            ...row,
                            workspace,
                            page
                        })
                    }
        
                    if (row.attrs) {
                        scan_widgets_onOpening(row.attrs)
                    }
                })
            }
        
            if (WIDGETS.length === 0) {
                scan_widgets_onOpening(PAGE.attrs || [])
        
                widgets_onOpening.forEach(actionMenu => {
                    store.executeStoreMethod({}, actionMenu)
                })
            }
        },

        // leftArea_setVisible() {
        //     const store = this
        //     const WORKSPACE = store.activeWORKSPACE
        //     const PAGE = WORKSPACE.activePAGE
        //     const leftArea = PAGE.leftArea
        //     const WIDGETS = PAGE.WIDGETS
            
        //     if (leftArea && leftArea.WIDGET) {
        //         leftArea.use = true
        //         if (!WIDGETS.length) {
        //             leftArea.visible = true
        //             leftArea.WIDGET.cssClassW = 'left-area-w4'
        //         } else {
        //             leftArea.WIDGET.cssClassW = 'left-area-w2'
        //         }
        //     } else {
        //         leftArea.use = false
        //     }
        // },
        // leftArea_hide(justHide = false, workspace = null) {
        //     const store = this
        //     const WORKSPACE = workspace ? store.WORKSPACES[workspace] : store.activeWORKSPACE
        //     const PAGE = WORKSPACE.activePAGE
        //     const leftArea = PAGE.leftArea

        //     if (leftArea) {
        //         leftArea.visible = false
        //         leftArea.WIDGET.title = 'DEL'
        //         leftArea.WIDGET = null
        //         leftArea.use = false
        //     }
        // },

        // rightArea_setVisible(vueObj, actionMenu) {
        //     const store = this
        //     const WORKSPACE = store.activeWORKSPACE
        //     const PAGE = WORKSPACE.activePAGE
        //     const rightArea = PAGE.rightArea

        //     rightArea.use = true
        //     rightArea.visible = true
        //     store.rightArea_setDataSource(vueObj, actionMenu.binding, actionMenu.configType, null, actionMenu)
        // },
        // rightArea_setDataSource(vueObj, binding, configType, item, actionMenu) { // set parent
        //     if (!vueObj) return

        //     const store = this
        //     const WORKSPACE = store.activeWORKSPACE
        //     const PAGE = WORKSPACE?.activePAGE
        //     const rightArea = PAGE?.rightArea
        //     if (!rightArea) return
            
        //     const WIDGET = vueObj.WIDGET
            
        //     // parentWidget
        //     const parentWidget = rightArea.parentWidget = {
        //         vueObj: vueObj,
        //         WIDGET: WIDGET,
        //         configType: configType,
        //         binding: binding,
        //     }

        //     // show_button
        //     rightArea.use = Boolean(parentWidget.configType && parentWidget.WIDGET)
        //     if (!rightArea.use) {
        //         store.rightArea_hide()
        //         return
        //     }

        //     // item
        //     if (!item) {
        //         if (!binding) { // configType='FORECAST_rules'
        //             if (actionMenu && actionMenu.attrs) {
        //                 item = {}
        //                 for (let attr of actionMenu.attrs) {
        //                     store.doc_set(item, attr.binding, store.doc_get(WIDGET.doc, attr.binding))
        //                 }
        //                 item.title = actionMenu.title || actionMenu.tooltip
        //             } else {
        //                 item = WIDGET.doc
        //             }
        //         } else {
        //             item = WIDGET.vueObj.selectedRow(binding)?.dataItem
        //         }
        //     }
        //     rightArea.parentWidget.item = item
        //     rightArea.parentWidget.doc = {
        //         fields: WIDGET.doc.fields,
        //         id: WIDGET.doc.id,
        //         title: WIDGET.doc.title,
        //     }
            
        //     if (rightArea.visible) {
        //         if (item) {
        //             store.rightArea_setData()
        //         } else {
        //             store.rightArea_hide()
        //         }
        //     }
        // },
        // rightArea_setData() {
        //     const store = this
        //     const WORKSPACE = store.activeWORKSPACE
        //     const PAGE = WORKSPACE.activePAGE
        //     const rightArea = PAGE.rightArea
        //     const parentWidget = rightArea.parentWidget

        //     if (rightArea.use && parentWidget) {
        //         if (!parentWidget.item) {
        //             parentWidget.item = parentWidget.WIDGET.vueObj.selectedRow(parentWidget.binding)?.dataItem
        //         }
        //         if (parentWidget.item) {
        //             store.widget_open({
        //                 widget: 'widget-params',
        //                 parentWidget: {
        //                     id: parentWidget.WIDGET.id,
        //                     title: parentWidget.WIDGET.title,
        //                     binding: parentWidget.binding,
        //                     configType: parentWidget.configType,
        //                     doc: parentWidget.doc
        //                 },
        //                 parentActionMenu: { 'action_id':'action-front-8', method: 'store.rightArea_setData' },
        //                 doc: parentWidget.item,
        //             })
        //         }
        //     }
        // },
        // rightArea_editEnded(WIDGET, attr) {
        //     const store = this
        //     let parent = store.findWidget(WIDGET.parentWidget.id),
        //         doc = WIDGET.doc,
        //         need_backend = false

        //     if (WIDGET.parentWidget.configType === 'measure_calculationModel') {
        //         let measureRow = store.get_measureRow(parent.WIDGET, doc.measure)
        //         if (attr.binding !== 'own_calculateModel') {
        //             doc.own_calculateModel = true
        //         } else if (!doc.own_calculateModel) {
        //             need_backend = true
        //         }
        //         measureRow.own_calculateModel = doc.own_calculateModel
        //         measureRow.calculateModel_params = doc
        //     } else if (!WIDGET.parentWidget.binding) { // configType='FORECAST_rules'
        //         store.doc_set(parent.WIDGET.doc, attr.binding, store.doc_get(WIDGET.doc, attr.binding))
        //     } else {
        //         let tree = parent.WIDGET.vueObj.active_flex(WIDGET.parentWidget.binding),
        //             node = tree.selectedNode
        //         need_backend = node.dataItem.segment !== doc.segment
        //         if (['viewDoc', 'viewList'].includes(WIDGET.parentWidget.configType) && node.dataItem.binding !== doc.binding) {
        //             need_backend = true
        //         }
        //         if (doc.binding && doc.binding !== node.dataItem.binding
        //             && (!doc.title || (node.dataItem.binding || '').toLowerCase() === (node.dataItem.title || '').toLowerCase())) {
        //             doc.title = store.title_toUpperCase(doc.binding)
        //         }
        //         for (let key in doc) {
        //             if (key !== 'attrs') {
        //                 node.itemsSource[node.index][key] = doc[key]
        //                 node.dataItem[key] = doc[key]
        //                 node.dataItem['##modified'] = true
        //             }
        //         }
        //         // node.isChecked = doc.use || false
        //         node.refresh()
        //         this.tree_WidgetatItem(tree, node)
        //     }
        //     if (need_backend) {
        //         store.executeStoreMethod(parent.WIDGET.vueObj, { 'command': 'rightArea_setVisible', 'binding': WIDGET.parentWidget.binding, 'configType': WIDGET.parentWidget.configType })
        //     }
        // },
        // rightArea_hide(justHide = false) {
        //     const store = this
        //     const WORKSPACE = store.activeWORKSPACE
        //     const PAGE = WORKSPACE.activePAGE
        //     const rightArea = PAGE.rightArea
            
        //     rightArea.visible = false
        //     if (!justHide) {
        //         rightArea.WIDGET = null
        //     }
        // },

        calc_widget_position(workspace, page) {
            const store = this,
                staticMenuActive = !(this.staticMenuInactive && this.layoutMode === 'static'),
                left = staticMenuActive ? '170px' : ''
            let WORKSPACE = store.WORKSPACES[workspace]
            let PAGE = WORKSPACE.PAGES[page]
            let WIDGETS = PAGE.WIDGETS
    

            if (WIDGETS.length === 1) {
                WIDGETS[0].style = `
                    position: absolute;
                    top: calc(42px);
                    height: calc(-42px + 100%);
                    left: calc(0px);
                    width: calc(100%);
                    margin: 0.5rem;
                `
            } else if (WIDGETS.length === 2) {
                1
            }
            // for (let i=0; i<WIDGETS.length; i++) {

            // }
        },
        findWidget(widget_id, workspace='', page='') {
            const store = this
            let result = null

            if (!workspace) {
                if (widget_id in store.WORKSPACES) {
                    return { workspace: null, page: null, ind: null, WIDGET: store.WORKSPACES[widget_id] }
                } 
                if (store.activeWORKSPACE) {
                    workspace = store.activeWORKSPACE.workspace
                    page = store.activeWORKSPACE.active_page
                    
                    result = store.findWidget(widget_id, workspace, page)
                    if (result.WIDGET) { 
                        return result
                    }
                    
                    result = store.findWidget(widget_id, workspace)
                    if (result.WIDGET) { 
                        return result
                    }

                    for (workspace in store.WORKSPACES) {
                        result = store.findWidget(widget_id, workspace)
                        if (result.WIDGET) { 
                            return result
                        }
                    }
                }
            } else if (!page) {
                let WORKSPACE = store.WORKSPACES[workspace]
                for (let page in WORKSPACE.PAGES) {
                    result = store.findWidget(widget_id, workspace, page)
                    if (result.WIDGET) { 
                        return result
                    }
                }
            } else {
                let WORKSPACE = store.WORKSPACES[workspace]
                let PAGE = WORKSPACE.PAGES[page]

                // // leftArea
                // if (PAGE.leftArea && PAGE.leftArea.WIDGET && PAGE.leftArea.WIDGET.id === widget_id) {
                //     return { workspace: WORKSPACE.workspace, page: PAGE.page, ind: 0, WIDGET: PAGE.leftArea.WIDGET }
                // }

                // // rightArea
                // if (PAGE.rightArea && PAGE.rightArea.WIDGET && PAGE.rightArea.WIDGET.id === widget_id) {
                //     return { workspace: WORKSPACE.workspace, page: PAGE.page, ind: 0, WIDGET: PAGE.rightArea.WIDGET }
                // }

                // WIDGETS
                let WIDGETS = PAGE.WIDGETS
                if (WIDGETS) {
                    let ind = WIDGETS.findIndex(t => t.id === widget_id)
                    if (ind !== -1) {
                        return { workspace: WORKSPACE.workspace, page: PAGE.page, ind: ind, WIDGET: WIDGETS[ind] }
                    }
                }
            }

            return { workspace: null, page: null, ind: null, WIDGET: null }
        },
        findWidget_byProps(props, widget_class) {
            const store = this
            if (store.activeWORKSPACE) {
                const WORKSPACE = store.activeWORKSPACE
                const PAGE = WORKSPACE.activePAGE
                const WIDGETS = PAGE.WIDGETS

                for (let i = WIDGETS.length - 1; i >= 0; i--) {
                    let exsistForm = WIDGETS[i]
                    if (JSON.stringify(exsistForm.props) === JSON.stringify(props)
                        && (exsistForm.widget_class === widget_class || (!widget_class && exsistForm.widget_class.search('_list') === -1))) {
                        return exsistForm
                    }
                }
            }
            return null
        },
        concat_params(params_in = {}, source = {}, sourceName = '', params_excluding = []) {
            let params = { ...params_in }
            if (source.params) {
                for (let param in source.params) {
                    if (!params_excluding.includes(param)) {
                        params[param] = source.params[param]
                    }
                }
            }
            return params
        },
        attr_activate(payload) {
            const { event, widget_id, attr } = payload;
            const store = this
            if (store.attr_active) {
                store.unsetActiveEl(store.attr_activeEl)
            }
            store.attr_active = attr;
            store.attr_activeEl = store.attr_findClosestWidgetEl(event)
            store.setActiveEl(store.attr_activeEl)

            const WIDGET = store.findWidget(widget_id).WIDGET

            store.widget_open({
                workspace: WIDGET.workspace,
                page: WIDGET.page,
                area: 'right-area',
                widget_class: 'widget_Attr',
                parentWidget: { id: WIDGET.id, title: WIDGET.title },
                parentActionMenu: { 'action_id':'action-front-6', method: 'store.attr_activate' },
                cssClassW: 'widget-w4',
                doc: attr,
            })

            // console.log('################# attr_activate ')
        },
        attr_dragStart(payload) {
            const { event, widget_id, attr } = payload;
            const store = this
            store.attr_activate(payload)
            store.attr_dragged = attr;
            event.dataTransfer.effectAllowed = 'move';
            store.setDragSourceEl(store.attr_findClosestWidgetEl(event))
            // console.log('################# attr_dragStart ')
        },
        attr_dragOver(payload) {
            // console.log('################# attr_dragOver')
            const { event } = payload;
            const store = this
            const attr_widget = store.attr_findClosestWidgetEl(event);
            const dragTarget = store.attr_findDragTargetEl();
            if (attr_widget !== dragTarget) {
                store.unsetDragTargetEl(dragTarget);
            }
            const dragSource = store.attr_findDragSourceEl();
            if (dragSource && attr_widget !== dragSource) {
                event.preventDefault();
                event.dataTransfer.dropEffect = 'move';
                store.setDragTargetEl(attr_widget);
            }
        },
        attr_dragFinish(payload) {
            // console.log('################# attr_dragFinish')
            const { event, widget_id, attr } = payload;
            const store = this
            const dragSource = store.attr_findDragSourceEl();
            const dragTarget = store.attr_findDragTargetEl();
            if (dragSource && dragTarget) {
                event.preventDefault();
                const WIDGET = store.findWidget(widget_id).WIDGET
                const sourceIndex = store.attrs_find(WIDGET.attrs, store.attr_dragged)
                const targetIndex = store.attrs_find(WIDGET.attrs, attr)
                if (store.swapOnDragAndDrop) {
                    // store.WORKSPACES[sourceIndex.workspace].PAGES[sourceIndex.page].WIDGETS.splice(sourceIndex.ind, 1, targetIndex.WIDGET);
                    // store.WORKSPACES[targetIndex.workspace].PAGES[targetIndex.page].WIDGETS.splice(targetIndex.ind, 1, sourceIndex.WIDGET);
                } else {
                    sourceIndex.attrs.splice(sourceIndex.ind, 1);
                    targetIndex.attrs.splice(targetIndex.ind, 0, store.attr_dragged);
                }
                wjcCore.Control.invalidateAll(); // invalidate Wijmo controls after layout updates
            }
        },
        attr_dragEnd() {
            // console.log('################# attr_dragEnd')
            const store = this
            store.unsetDragSourceEl(store.attr_findDragSourceEl());
            store.unsetDragTargetEl(store.attr_findDragTargetEl());
        },
        attrs_find(attrs, attr) {
            const store = this
            for (let ind in attrs) {
                if (attrs[ind] === attr) {
                    return { attrs: attrs, ind: ind }
                }
            }
            for (let ind in attrs) {
                if (attrs[ind].attrs) {
                    let res = store.attrs_find(attrs[ind].attrs, attr)
                    if (res) {
                        return res
                    }
                }
            }
        },
        JSON_sort(content1) {
            if (this.isinstance_dict(content1)) {
                let content_template_start = {
                    id: '',
                    title: '',
                    header: '',
                    widget_class: '',
                    page: '',
                    component: '',
                    class: '',
                    segment: '',
                    attr_type: '',
                    parentType: '',
                    parentWidget: '',
                    parentActionMenu: '',
    
                    params_onOpen: '',
                    params: '',
                    workspace: '',
                    doc: '',
    
                    action: '',
                    dateDoc: '',
                    number: '',
                    version: '',
                    status: '',
                    date: '',
                    date_cor: '',
                    period_from: '',
                    period_to: '',
                    object: '',
                    subject: '',
                    subject_cor: '',
    
                    quantity: '',
                    price: '',
                    amount: '',
    
                    order: '',
                    archived: '',
                    comment: '',
    
                    keys: '',
                    attr: '',
                    dataSource: '',
                    query_text: '',
                }
                let content_template_end = {
                    workgroup: '',
                    changes_info: '',
                    source_info: '',
                    groupMenu: '',
                }
    
                let content_template_expert = {
                    META_params: '',
                }
    
                let content2 = {}
                try {
                    for (let key in content_template_start) {
                        if (key in content1) {
                            content2[key] = content1[key]
                        }
                    }
                    for (let key in content1) {
                        if (!(key in content_template_start || key in content_template_end || key in content_template_expert)) {
                            content2[key] = content1[key]
                        }
                    }
                    for (let key in content_template_end) {
                        if (key in content1) {
                            content2[key] = content1[key]
                        }
                    }
                } catch (error) {
                    store.TERMINAL(null, null, {text:error, status:'error'})
                }

                return content2
            } else {
                return content1
            }
        },
        str_toJSON(WIDGET, str, format='') {
            const store = this
            let json = {}
            if (format === '') {
                const JSON5 = require('json5')
                
                str = str.replace(/True/g, 'true').replace(/'/g, '"')
                str = str.replace(/False/g, 'false').replace(/'/g, '"')

                // del comments
                str = this.removeComments(str)

                // // Removing extra commas
                // str = str.replace(/,(\s*[}\]])/g, '$1')

                try {
                    json = JSON5.parse(`{${str}}`)
                    // json = JSON.parse(`{${str}}`)
                } catch (e) {
                    store.TERMINAL(WIDGET, null, {header:`Error parsing JSON: ${e}`, text:`{${str}}`, status:'error'})
                }
                
            } else if (format === 'cell') {
                // str = 'Date:2023-02;Sum forecast:0;'

                const pairs = str.split(';')
                pairs.forEach(pair => {
                    const [key, value] = pair.split(':')

                    if (key) {
                        const cleanKey = key.trim()
                        const cleanValue = value.trim()

                        json[cleanKey] = cleanValue
                    }
                })
            }
            return json
        },
        removeComments(str) {
            // str = str.replace(/#.*$/gm, '')
            str = str.replace(/(^|\s)#.*$/gm, (match, p1) => {
              const preMatch = str.substring(0, match.index).split('\n').pop();
              if (preMatch.includes('"') && !preMatch.split('"').pop().includes('"')) {
                return match
              }
              return p1
            });
          
            str = str.replace(/(?<=\s)#.*$/gm, '')

            str = str.replace(/\/\/.*$/gm, '')

            return str
        },
        columns_set(flex, grid_data, columns) {
            flex.columns.clear()
            if (columns) {
                for (let attr of columns) {
                    if (attr.visible !== false) {
                        let c = new wjGrid.Column();
                        c.binding = attr.binding;
                        c.header = attr.title;
                        c.width = attr.width;
                        c.cssClass = attr.cssClass
                        flex.columns.push(c);
                    }
                }
            } else if (grid_data.length) {
                flex.autoGenerateColumns = true
            } else {
                let c = new wjGrid.Column();
                c.binding = 'title';
                c.header = 'Title';
                c.width = '*';
                flex.columns.push(c);
            }
        },
        scan_element(content_grid, from) {
            const store = this
            for (let key in from) {
                if (from[key] instanceof Object) {
                    let data2 = []
                    store.scan_element(data2, from[key])
                    content_grid.push({ key: key, value: JSON.stringify(from[key]), children_nodes: data2 })
                } else {
                    content_grid.push({ key: key, value: from[key] })
                }
            }
        },
        async saveDoc(vueObj, doc, params = {}) {
            const store = this, WIDGET = vueObj.WIDGET
            // let workspace_doc = store.activeWORKSPACE.doc

            const WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            if (WIDGET.doc && WORKSPACE.doc && WIDGET.doc.id === WORKSPACE.doc.id) {
                WORKSPACE.doc = WIDGET.doc
            }

            WIDGET.loading = true;
            params.update = params.update || false
            params.row = params.row || null
            params.closeAfterSave = params.closeAfterSave || false
            params.reloadAfterSave = params.reloadAfterSave || false
            params.content_reloadAfterSave = params.content_reloadAfterSave || false
            try {
                let timeStart = vueObj.time = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'saveDoc',
                        showSQL: store.showSQL,
                        doc: doc,
                        ...params,
                        user: store.user,
                        params_onOpen: WIDGET.params_onOpen, // look for: collection
                        // workspace_doc: workspace_doc, // look widget_FORECAST.beforeSave(...)
                        action_from_frontend: true,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // async: false,

                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        const notification_frontend = null // {
                        //     text: (WIDGET?.title || '') + ', saveDoc time: ' + Math.round(performance.now() - vueObj.time) / 1000,
                        //     status: 'info',
                        // }
                        store.TERMINAL(WIDGET, answer, notification_frontend, null, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(vueObj, answer)) return
                        
                        if (!Array.isArray(doc) && !params.multi) {
                            delete doc['##modified']
                            delete doc['##dublicate']
                            delete doc['##new']

                            if (answer.id) {
                                if (params.row && params.row.id !== answer.id) {
                                    params.row.id = answer.id
                                }
                                if (doc.id !== answer.id) {
                                    doc.id = answer.id
                                }
                                if (answer.segment && answer.title) {
                                    store.refresh_itemsSource(answer.segment, { id: answer.id, title: answer.title })
                                }
                            }
                        }

                        WIDGET.loading = false;
                        if (params.closeAfterSave) {
                            store.widget_close(vueObj.widget_id, true)
                            if (params.reloadAfterSave) {
                                store.executeStoreMethod(vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'], 'selectRowID': doc.id })
                            }
                        } else if (params.reloadAfterSave) {
                            store.executeStoreMethod(vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'], 'selectRowID': doc.id })
                        } else if (params.content_reloadAfterSave) {
                            vueObj.content_Changed()
                        }
                        if (params.parentWidget_reloadAfterSave && params.parentWidget) {
                            const widget_parent = store.findWidget(params.parentWidget.id).WIDGET
                            if (widget_parent?.widget_class === "widget_LIST" || widget_parent?.widget_class === "widget_TREE") {
                                store.executeStoreMethod(widget_parent.vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'], 'selectRowID': doc.id })
                            }
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                        WIDGET.loading = false
                    },
                });
            } catch (error) {
                store.TERMINAL(WIDGET, null, {text:error, status:'error'})
            }
        },
        async Save_PIVOT_TOOL(vueObj, rows_modify, params = {}) {
            const store = this, WIDGET = vueObj.WIDGET
            // let workspace_doc = store.activeWORKSPACE.doc
            WIDGET.loading = true;
            params.update = params.update || false
            params.row = params.row || null
            params.closeAfterSave = params.closeAfterSave || false
            params.reloadAfterSave = params.reloadAfterSave || false
            params.content_reloadAfterSave = params.content_reloadAfterSave || false
            try {
                let timeStart = vueObj.time = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'Save_PIVOT_TOOL',
                        showSQL: store.showSQL,
                        rows_modify: rows_modify,
                        ...params,
                        user: store.user,
                        params_onOpen: WIDGET.params_onOpen, // look for: collection
                        // workspace_doc: workspace_doc, // look widget_FORECAST.beforeSave(...)
                        action_from_frontend: true,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // async: false,

                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        const notification_frontend = null // {
                        //     text: (WIDGET?.title || '') + ', Save_PIVOT_TOOL time: ' + Math.round(performance.now() - vueObj.time) / 1000,
                        //     status: 'info',
                        // }
                        store.TERMINAL(WIDGET, answer, null, notification_frontend, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(vueObj, answer)) return
                        WIDGET.loading = false
                        // if (params.reloadAfterSave) {
                            store.executeStoreMethod(vueObj, { 'tooltip': 'Update data', 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'], 'cssClass':'pi pi-sync' })
                        // }
                    },
                    error: function (error) {
                        store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                        WIDGET.loading = false
                    },
                });
            } catch (error) {
                store.TERMINAL(WIDGET, null, {text:error, status:'error'});
            }
        },
        check_loginAfterAjax(vueObj = null, answer) {
            const store = this
            if (answer.isAuthenticated === false) {
                console.log('isAuthenticated false')
                store.logout()
                return false
            }
            return true
        },
        async check_sessionKey(to, next) {
            console.log('check_sessionKey')
            const store = this,
                csrftoken = store.getCookie('csrftoken'),
                session_key = store.getCookie(`session_key_${to.params.dbid}`) // store.user.database.dbid
            if (!session_key || !csrftoken) {
                return store.logout(to, next)
            }
            try {
                $.ajaxSetup({
                    headers: {
                        'X-CSRFToken': csrftoken
                    },
                    xhrFields: {
                        withCredentials: true
                    }
                })

                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        version_frontend: store.version_frontend,
                        command: 'check_sessionKey',
                        showSQL: store.showSQL,
                        // user: { session_key: session_key }
                        user: { database: {dbid:to.params.dbid} }
                    }),
                    contentType: "application/json",
                    dataType: 'json',

                    success: function (answer, textStatus, jqXHR) {
                        store.check_versions(answer)
                        store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        // if (!store.check_loginAfterAjax(vueObj, answer)) return
                        store.isAuthenticated = answer.isAuthenticated
                        if (store.isAuthenticated) {
                            store.user = answer.user
                            return next(to)
                        } else {
                            document.cookie = `session_key_${to.params.dbid}=; Max-Age=0`
                            return store.logout(to, next)
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'error'})
                        store.isAuthenticated = false
                        document.cookie = `session_key_${to.params.dbid}=; Max-Age=0`
                        return store.logout(to, next)
                    },
                });
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'});
            }
        },
        check_versions(answer) {
            const store = this
            store.version_backend = answer.version_backend
            console.log(`versions: ${store.version_frontend} ${store.version_backend}`)
        },
        logout(to=null, next=null, isLogout=false) {
            const store = this

            store.isAuthenticated = false
            document.cookie = `session_key=; Max-Age=0` // for compatibility 2024 06 07
            // document.cookie = `csrftoken=; Max-Age=0` // LOOK other connections
            if (isLogout && store.user?.database?.dbid) {
                document.cookie = `session_key_${store.user.database.dbid}=; Max-Age=0`
            }

            if (next) {
                if (to.params.dbid) {
                    return next({ name: 'dbLogin', query:to.query, params:to.params })
                } else {
                    return next({ name: 'login' })
                }
            } else {
                if (store.user?.database?.dbid) {
                    store.router.push({ name: 'dbLogin', params:{ dbid:store.user.database.dbid } })
                } else {
                    store.router.push({ name: 'login' })
                }
            }
        },
        async login(vueObj, user, dbid, rememberMe) {
            const store = this
            try {
                // const csrftoken = store.getCookie('csrftoken')
                // console.log(csrftoken)
                let timeStart = performance.now()
                vueObj.error = '...'
                $.ajax({
                    url: store.url_backend_login,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        // command: 'login',
                        version_frontend: store.version_frontend,
                        showSQL: store.showSQL,
                        user: user,
                        dbid: dbid,
                        route: vueObj.$route.query,
                    }),
                    // dataType: 'json',
                    // // xhrFields: { withCredentials: true },
                    // // crossDomain: true,
                    contentType: 'application/json',
                    xhrFields: {
                        withCredentials: true
                    },
                    // headers: { // django.middleware.csrf.CsrfViewMiddleware (2)
                    //     'X-CSRFToken': 'cD0agdvNYI9XilNw05oh2gCP8q3dsXSu',
                    //     // 'X-CSRFToken': csrftoken,
                    //     // 'Origin': 'http://localhost:8080'
                    // },
                    success: function (answer, textStatus, jqXHR) {
                        store.check_versions(answer)
                        store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        // if (!store.check_loginAfterAjax(vueObj, answer)) return
                        store.isAuthenticated = answer.isAuthenticated
                        if (store.isAuthenticated) {
                            const csrftoken = store.getCookie('csrftoken'),
                                session_key = store.getCookie('session_key')
                            $.ajaxSetup({
                                headers: {
                                    'X-CSRFToken': csrftoken
                                },
                                xhrFields: {
                                    withCredentials: true
                                }
                            })

                            store.$reset()
                            store.isAuthenticated = answer.isAuthenticated
                            store.user = answer.user
                            // if (rememberMe) {
                            //     document.cookie = `session_key_${store.user.database.dbid}=${session_key}` // answer.user.session_key
                            // }
                            const process_model = vueObj.$route.query.process_model || store.process_model_doc.id,
                            workspace = vueObj.$route.query.workspace || store.user.workspace_onOpening
                            if (store.user.workspace_onOpening) {
                                vueObj.$router.push(`/${store.user.database.dbid}?process_model=${process_model}&workspace=${workspace}`)
                            } else {
                                vueObj.$router.push(`/${store.user.database.dbid}`)
                            }
                        } else {
                            vueObj.error = answer.notification?.status
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'critical'})
                        vueObj.error = "Backend error";
                    },
                });
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'});
            }
        },
        async get_menuTree(to, next) {
            console.log('get_menuTree')
            const store = this
            try {
                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'get_menuTree',
                        showSQL: store.showSQL,
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // xhrFields: { withCredentials: true },
                    // crossDomain: true,

                    success: function (answer, textStatus, jqXHR) {
                        store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        store.menuTree = answer.menuTree

                        next(to)

                        // pages_menu
                        store.menuTree_model = []
                        for (let i in store.menuTree) {
                            store.menu_push(store.menuTree_model, store.menuTree[i])
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'error'})
                    },
                });
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'});
            }
        },
        menu_push(model_items, menuNode) {
            const store = this
            if (menuNode.workspace) {
                [menuNode.to, menuNode.path_to] = store.workspace_route_to(menuNode.workspace)
            }
            let menu_str = {
                to: menuNode.to,
                label: menuNode.title,
                pi: menuNode.pi,
                img: menuNode.img,
            }
            if (!menu_str.img) {
                menu_str.pi = menuNode.pi
            }
            if (menuNode.name === 'node-WORKSPACES') { //menuNode.title === 'WORKSPACES'
                store.menu_model_WORKSPACES = menu_str
            }
            if (!('visible' in menuNode) || menuNode.visible) {
                model_items.push(menu_str)
            }
            if (menuNode.attrs) {
                let items = []
                for (let i in menuNode.attrs) {
                    store.menu_push(items, menuNode.attrs[i])
                }
                if (items.length)
                    menu_str.items = items // for WORKSPACE
            }
        },
        find_menuNode(workspace, attrs) {
            const store = this
            if (!attrs)
                attrs = store.menuTree
            for (let menuNode of attrs) {
                if (menuNode.workspace === workspace) {
                    return menuNode
                }
                if (menuNode.id === workspace) {
                    return menuNode
                }
            }
            for (let menuNode of attrs)
                if (menuNode.attrs) {
                    let menuNode2 = this.find_menuNode(workspace, menuNode.attrs)
                    if (menuNode2)
                        return menuNode2
                }
            return null
        },
        async get_process_model(to, next) {
            console.log('get_process_model')
            const store = this
            try {
                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'get_process_model',
                        showSQL: store.showSQL,
                        user: store.user,
                        process_model: to.query.process_model||'',
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // xhrFields: { withCredentials: true },
                    // crossDomain: true,

                    success: function (answer, textStatus, jqXHR) {
                        store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        store.process_model_doc = answer.process_model_doc

                        if (to.query.process_model !== answer.process_model_doc.id) {
                            next({ name:to.name, params:to.params, query: { ...to.query,
                                process_model: answer.process_model_doc.id,
                            }})
                        } else {
                            store.get_menuTree(to, next)
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'error'})
                    },
                });
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'});
            }
        },
        getCookie(name) {
            //eslint-disable-next-line
            let matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
            return matches ? decodeURIComponent(matches[1]) : undefined;
        },
        // getCookie0(name) {
        //     let cookieValue = null;
        //     if (document.cookie && document.cookie !== '') {
        //         const cookies = document.cookie.split(';');
        //         for (let i = 0; i < cookies.length; i++) {
        //             const cookie = cookies[i].trim();
        //             if (cookie.substring(0, name.length + 1) === (name + '=')) {
        //                 cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        //                 break;
        //             }
        //         }
        //     }
        //     return cookieValue;
        // },

        // async import_file(vueObj) {
        //     const store = this;
        //     const WIDGET = vueObj.WIDGET
        //     try {
        //         vueObj.time = performance.now();
        //         $.ajax({
        //             url: store.url_backend_api,
        //             type: "POST",
        //             data: JSON.stringify({
        //                 command: 'import_file',
                        // showSQL: store.showSQL,
                        //                 file_name: vueObj.file_name,
        //                 file_dounloadToBackend: vueObj.file_dounloadToBackend,
        //                 user: store.user,
        //             }),
                    // contentType: "application/json",
        //             dataType: 'json',
        //             // async: false,

        //             success: function (answer, textStatus, jqXHR) {
                        // store.TERMINAL(null, answer, null, timeStart, jqXHR)
                        //                 if (!store.check_loginAfterAjax(vueObj, answer)) return

        //                 // console.log(WIDGET.title + ', import_file time: ', Math.round(performance.now() - vueObj.time)/1000)
        //             },
        //             error: function(error){
        //                 store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
        //                 WIDGET.loading = false
        //             },
        //         })
        //     } catch (error) {
        //         store.TERMINAL(WIDGET, null, {text:error, status:'error'})
        //     }
        // },
        async printPDF(vueObj) {
            const store = this, WIDGET = vueObj.WIDGET
            try {
                let timeStart = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        command: 'printPDF',
                        showSQL: store.showSQL,
                        doc: vueObj.WIDGET.doc,
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // async: false,

                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        store.TERMINAL(WIDGET, answer, null, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(vueObj, answer)) return
                        if (answer.file_dounloadToFrontend) {
                            let fileWindow = window.open("")
                            fileWindow.document.write(
                                "<iframe width='100%' height='100%' src='" + encodeURI(answer.file_dounloadToFrontend) + "'></iframe>"
                            )
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                        WIDGET.loading = false
                    },
                })
            } catch (error) {
                store.TERMINAL(WIDGET, null, {text:error, status:'error'})
            }
        },
        executeStoreMethod(vueObj, actionMenu, form_attr=null) {
            const store = this, WIDGET = vueObj.WIDGET
            actionMenu.action_id_front = ++store.action_id_front
            console.log(`[${WIDGET?.id}][${actionMenu.action_id_front}]${actionMenu.command} ${actionMenu.method} START`)
            switch (actionMenu.command) {
                case 'widget_open': {
                    let widget_init = {
                        doc:{},
                        widget: actionMenu.widget,
                        widget_class: actionMenu.widget_class,
                        duplicate: actionMenu.duplicate,
                        parentActionMenu: actionMenu,
                        area: actionMenu.area,
                    }

                    if (actionMenu.item) {
                        widget_init.doc = actionMenu.item
                    } else if (actionMenu.get_selectedRow) {
                        let selectedRow = vueObj.selectedRow(actionMenu.binding)
                        widget_init.doc = selectedRow?.dataItem || {}
                        if (actionMenu.binding2) {
                            widget_init.doc = {
                                id: widget_init.doc[actionMenu.binding2],
                            }
                        }
                        // if (actionMenu.MDH) {
                        //     widget_init.doc.MDH = structuredClone(actionMenu.MDH)
                        // } else {
                        let parentMDH = structuredClone(WIDGET.doc?.MDH) || {}
                        parentMDH.title = WIDGET.title
                        parentMDH.widget = WIDGET.widget
                        parentMDH.widget_id = WIDGET.id
                        delete parentMDH.doc 

                        let MDH = widget_init.doc.MDH
                        if (!MDH) {
                            MDH = widget_init.doc.MDH = {
                                id: parentMDH?.id || WIDGET.doc?.id,
                                segment: parentMDH?.segment || WIDGET.doc?.segment,
                                binding: actionMenu.binding,
                                iroot: selectedRow?.iroot,
                                // attr_id: vueObj.attr_id,
                            }
                        }
                        // if (actionMenu.get_Parent) {
                            MDH.parentMDH = parentMDH // parentMDH?.binding, iroot
                        // }

                    } else if (actionMenu.widget === 'widget-list') {
                        if (actionMenu.params_onOpen) {
                            widget_init.params_onOpen = actionMenu.params_onOpen
                        } else if (actionMenu.get_currentDocID) {
                            widget_init.props = { id: WIDGET.doc?.id }
                            widget_init.params_onOpen = {
                                segment: WIDGET.doc?.id,
                            }
                        } else {
                            const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                            if (item) {
                                widget_init.props = { id: item.id }
                                widget_init.params_onOpen = {
                                    segment: item.id,
                                }
                            } else {
                                break
                            }
                        }
                    } else if (actionMenu.widget === 'widget-DOC') {
                        if (actionMenu.new) {
                            widget_init.doc = {
                                id: '',
                                '##new':true,
                                segment: WIDGET.doc.params.segment,
                                version: WIDGET.doc.params.version,
                            }
                            if (vueObj.WIDGET.widget_class === 'widget_TREE') {
                                let tree = vueObj.active_flex(actionMenu.binding),
                                    node = tree.selectedNode
                                if (node) {
                                    widget_init.doc.parent_segment = node.dataItem.id
                                    widget_init.doc.order = node.dataItem.order || 0 + 1
                                }
                            }
                        } else {
                            const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                            if (item) {
                                widget_init.doc = { 
                                    id: item.id,
                                    segment: item.segment,
                                }
                            } else {
                                break
                            }
                        }
                        if (WIDGET.widget_class === 'widget_LIST' && !widget_init.doc.segment) {
                            widget_init.doc.segment = WIDGET.doc.params.segment
                            widget_init.doc.version = WIDGET.doc.params.version
                        }
                    }
    

                    store.widget_open(widget_init, vueObj)
                    break
                } case 'executeWidgetMethod_inNewWidget': {
                    let widget = actionMenu.widget
                    if (!widget) {
                        const item = vueObj.selectedRow(actionMenu.name || actionMenu.binding)?.dataItem
                        if (item) {
                            widget = item.widget
                    
                            item.date_start = '...'
                            item.status = '...'
                            const flex = vueObj.active_flex(actionMenu.name || actionMenu.binding)
                            flex.refresh()
                        } else {
                            break
                        }
                    }
                    let request = {
                        method: actionMenu.method,
                        widget_init: {
                            title: actionMenu.title || actionMenu.tooltip || '',
                            widget: widget,
                            parentActionMenu: actionMenu,
                            process_model: store.process_model_doc.id,
                        }
                    }
                    let workspace_doc = store.activeWORKSPACE.doc
                    if (workspace_doc) {
                        // request.widget_init.workspace_doc = workspace_doc
                        request.widget_init.workspace = workspace_doc.id
                    }
                    if (WIDGET?.doc) {
                        request.widget_init.execute_from_doc = WIDGET.doc
                    }
                    store.executeWidgetMethod_inNewWidget(vueObj, request, actionMenu)
                    break
                } case 'workspace_open': {
                    const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                    if (item && item.workspace && store.menu_model_WORKSPACES) {
                        store.workspace_open(item.workspace)
                    } else if (item && item.widget) {
                        store.executeStoreMethod(vueObj, { 'command':'widget_open', widget:'widget-DOC', item:{id:item.widget} })
                    }
                    break
                // } case 'rightArea_setVisible': {
                //     store.rightArea_setVisible(vueObj, actionMenu)
                //     break
                } case 'executeWidgetMethod': {
                    // if (WIDGET.area === 'right-area') {
                    //     const WORKSPACE = store.activeWORKSPACE
                    //     const PAGE = WORKSPACE.activePAGE
                    //     const rightArea = PAGE.rightArea

                    //     vueObj = rightArea.parentWidget.vueObj
                    // }
                    if (actionMenu.method === 'select') {
                        const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                        if (item) {
                            actionMenu.selectRowID = item.id
                        }
                    }
                    if (actionMenu.mark_selectedItem) {
                        const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                        if (item) {
                            item['##selected'] = true
                        }
                    }
                    store.executeWidgetMethod(vueObj, actionMenu)
                    break
                } case 'executeVueObjMethod': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    attr_vueObj[actionMenu.method](actionMenu)
                    break
                } case 'collapseToLevel': {
                    let tree = vueObj.active_flex(actionMenu.binding)
                    tree.collapseToLevel(actionMenu.collapseToLevel)
                    break
                } case 'onChoice': {
                    const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                    if (item) {
                        let parent_attr = WIDGET.parent_attr

                        // if (parent_attr.isChooseProcessModel) {
                        //     let to = vueObj.$route
                        //     store.router.push({ name:to.name, params:to.params, query: { ...to.query,
                        //         process_model: item.id,
                        //     }})
                        // } else {
                            let parentWidget = store.findWidget(WIDGET.parentWidget.id).WIDGET,
                                parent_attr_vueObj = parentWidget.attrs_vueObj[parent_attr.id]
                            parent_attr_vueObj.choiceFinish(parent_attr, item)
                            store.widget_close(vueObj.widget_id)
                        // }
                    }
                    break
                } case 'popupTab-onChoice': {
                    const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                    if (item) {
                        WIDGET['@@data'].parent_attr_vueObj.choiceFinish(WIDGET['@@data'].parent_attr, item)
                        // WIDGET.hide()        
                    }
                    break
                } case 'widget_forSelectedItem': {
                    const item = actionMenu.doc || vueObj.selectedRow(actionMenu.binding)?.dataItem
                    if (item) {
                        store.widget_open({
                            widget: actionMenu.widget,
                            parentWidget: { id: WIDGET.id, title: WIDGET.title },
                            parentActionMenu: actionMenu,
                            params_onOpen: {
                                segment: item.segment,
                                version: item.version,
                                parentSelectedItem: item,
                            },
                        })
                    }
                    break
                } case 'onShowArchived__Click': {
                    WIDGET.doc.params.showArchived = !(WIDGET.doc.params.showArchived)
                    store.executeStoreMethod(vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'] })
                    break
                } case 'onSaveElement__Click': {
                    const archive = (actionMenu.params && actionMenu.params.archive)
                    if (archive) {
                        WIDGET.doc.archived = !WIDGET.doc.archived
                    }
                    store.saveDoc(vueObj, WIDGET.doc, {
                        closeAfterSave: (actionMenu.params && actionMenu.params.closeWindow),
                        actionMenu: actionMenu,
                        content_reloadAfterSave: true,
                        parentWidget_reloadAfterSave: true,
                        parentWidget: WIDGET.parentWidget,
                    })
                    break
                } case 'onSave_PIVOT_TOOL__Click': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    if (WIDGET.doc.rows_modify && WIDGET.doc.rows_modify.length) {
                        store.Save_PIVOT_TOOL(vueObj, WIDGET.doc.rows_modify, {
                            actionMenu: actionMenu,
                            content_reloadAfterSave: true,
                            parentWidget_reloadAfterSave: true,
                            parentWidget: WIDGET.parentWidget,
                        })

                        attr_vueObj.set_commandPanel(true)
                    }
                    break
                } case 'onCloseWindow__Click': {
                    store.widget_close(vueObj.widget_id)
                    break
                } case 'onChoose_inList__Click': {
                    const item = vueObj.selectedRow(actionMenu.binding)?.dataItem
                    if (item) {
                        // const widget_parent = store.findWidget(WIDGET.parentWidget.id).WIDGET
                        // widget_parent.workspace_doc = {'id':item.id, 'title':item.title}

                        store.widget_close(vueObj.widget_id)
                    }
                    break
                } case 'onArchiveInList__Click': {
                    const items = vueObj.selectedItems()
                    let docs = []
                    for (let i = 0; i < items.length; i++) {
                        const item = items[i];
                        // const isLast = i === items.length - 1;
                        docs.push({
                            id: item.id,
                            archived: !WIDGET.doc.params.showArchived
                        })
                    }
                    store.saveDoc(vueObj, docs, {
                        update: true,
                        reloadAfterSave: true,
                        multi: true,
                    })
                    break
                } case 'onDuplicateElement__Click': {
                    store.widget_open({
                        workspace: WIDGET.workspace, widget_class: actionMenu.widget_class || WIDGET.widget_class,
                        parentActionMenu: actionMenu,
                        duplicate: true,
                        doc: store.JSON_sort(WIDGET.doc),
                    })
                    break
                } case 'onShowLogInList__Click': {
                    store.widget_open({
                        workspace: WIDGET.workspace, widget_class: 'widget_Logs_list',
                        parentActionMenu: actionMenu,
                    });
                    break
                } case 'onWidgetInfo__Click': {
                    let formInfo = {}
                    for (let key in WIDGET) {
                        if (key !== 'vueObj' && key !== 'data')
                            formInfo[key] = WIDGET[key]
                    }
                    store.widget_open({
                        workspace: WIDGET.workspace, widget: 'widget-INFO',
                        parentWidget: { id: WIDGET.id, title: WIDGET.title },
                        parentActionMenu: actionMenu,
                        doc: {
                            title: `Widget info for: ${WIDGET.widget_class}[${WIDGET.id}]`,
                            content: store.JSON_sort(formInfo),
                        },
                    })
                    break
                } case 'onPrintPDF__Click': {
                    store.printPDF(vueObj)
                    break
                } case 'onSubmit__Click': {
                    const widget_parent = store.findWidget(WIDGET.parentWidget.id).WIDGET
                    let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
                    if (WORKSPACE.doc) {
                        // widget_parent.workspace_doc = 2024-07-31
                        WORKSPACE.doc = store.JSON_sort(WIDGET.doc)
                    }

                    if (actionMenu.params && actionMenu.params.closeWindow) {
                        store.widget_close(vueObj.widget_id)
                    }
                    store.executeStoreMethod(widget_parent.vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'] })
                    break
                } case 'onSaveSettings__Click': {
                    store.widget_open({
                        workspace: WIDGET.workspace, widget_class: 'widget_Settings_saveList',
                        parentWidget: { id: WIDGET.id, title: WIDGET.title },
                        parentActionMenu: actionMenu,
                        params_onOpen: { widget_class: WIDGET.widget_class },
                        doc: store.JSON_sort(WIDGET.doc),
                    })
                    break
                } case 'onRestoreSettings__Click': {
                    store.widget_open({
                        workspace: WIDGET.workspace, widget_class: 'widget_Settings_restoreList',
                        parentWidget: { id: WIDGET.id, title: WIDGET.title },
                        parentActionMenu: actionMenu,
                        params_onOpen: { widget_class: WIDGET.widget_class },
                    })
                    break
                // } case 'onWorkspaceShowSettings__Click': {
                //     const store = this,
                //         workspace = store.activeWORKSPACE.workspace,
                //         workspace_doc = store.activeWORKSPACE.doc
                //     store.widget_open({
                //         workspace: workspace,
                //         widget: 'widget-DOC',
                //         parentWidget: { id: workspace, title: workspace },
                //         parentActionMenu: { 'action_id':'action-front-7', method: 'onWorkspaceShowSettings__Click' },
                //         // doc_take_from_front: true,
                //         // doc: store.JSON_sort(workspace_doc),
                //         doc: {id:workspace_doc.id},
                //         props: {
                //             props_for_widget: workspace
                //         }
                //     })
                //     break
                // } case 'chooseProcessModel': {
                //     const store = this,
                //         workspace_doc = store.activeWORKSPACE.doc
                //     let widget_init = {
                //         widget: 'widget-list',
                //         parentActionMenu: actionMenu,
                //         parent_attr: {
                //             isChooseProcessModel: true,
                //         },
                //         props: { command: 'chooseProcessModel' },
                //         params_onOpen: {
                //             current_item: workspace_doc,
                //             segment: 'PROCESS-MODEL',
                //             command_Choice: true,
                //         },
                //     }

                //     store.widget_open(widget_init, vueObj)
                //     break
                } case 'onScale__Click': {
                    store.Scale(vueObj, actionMenu.params)

                    // scroll
                    store.scrollInWorkspace(WIDGET)
                    break
                } case 'onMinimizeWidget__Click': {
                    const WORKSPACE = store.activeWORKSPACE
                    const PAGE = WORKSPACE.activePAGE
    
                    WIDGET.styleWidget.dock = 'min'
                    PAGE.mainArea.vueObj.setStyle()

                    break
                } case 'onFullscreen__Click': {
                    let elem = document.querySelector(`#WIDGET${vueObj.widget_id}`)
                    console.log('requestFullscreen')
                    if (actionMenu.currentVueObj) {
                        let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.name || actionMenu.binding)
                        elem = document.querySelector(`#vueObj${attr_vueObj.attr_id}`)
                        elem.webkitRequestFullscreen()
                    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                        elem.webkitRequestFullscreen()
                    } else if (elem.requestFullscreen) {
                        elem.requestFullscreen()
                    } else if (elem.mozRequestFullScreen) { /* Firefox */
                        elem.mozRequestFullScreen()
                    } else if (elem.msRequestFullscreen) { /* IE/Edge */
                        elem.msRequestFullscreen()
                    }
                    // this.widgetResize(vueObj)
                    break
                } case 'onCancel__Click': {
                    store.widget_close(vueObj.widget_id)
                    break
                } case 'onshowGroupPanel__Click': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    WIDGET.doc.showGroupPanel = attr_vueObj.attr.showGroupPanel = !attr_vueObj.attr.showGroupPanel
                    break
                } case 'onShowFilter__Click': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    attr_vueObj.attr.showFilter = !attr_vueObj.attr.showFilter
                    break
                } case 'onFlexGrid_isEditable__Click': {
                    WIDGET.flexGrid_isEditable = !(WIDGET?.flexGrid_isEditable)
                    WIDGET.flexGrid_allowAddNew = WIDGET.flexGrid_isEditable
                    WIDGET.flexGrid_allowDelete = WIDGET.flexGrid_isEditable
                    break
                } case 'onFlexGrid_showAllColumns__Click': {
                    const flex = vueObj.active_flex()
                    const grid_data = flex.itemsSource
                    let row = null
                    if (grid_data.length) {
                        row = grid_data[0]
                    } else if (grid_data.items.length) {
                        row = grid_data.items[0]
                    }
                    if (row) {
                        for (let binding in row) {
                            let column = flex.columns.find(x => x.binding === binding)
                            if (!column) {
                                let c = new wjGrid.Column();
                                c.binding = binding;
                                flex.columns.push(c);
                            } else if (!column.visible) {
                                column.visible = true
                            }
                        }
                    }
                    break
                } case 'onExportChart__Click': {
                    const flex = vueObj.active_flex(actionMenu.name || actionMenu.binding)
                    flex.saveImageToFile(`${WIDGET.title}.svg`)
                    break
                } case 'onExportSheet__Click': {
                    const flex = vueObj.active_flex(actionMenu.name || actionMenu.binding)
                    flex.saveAsync(`${WIDGET.title}.xlsx`)
                    break
                } case 'onExportGrid__Click': {
                    const flex = vueObj.active_flex(actionMenu.binding)
                    if (actionMenu.params.format === 'xlsx') {
                        wjcGridXlsx.FlexGridXlsxConverter.saveAsync(
                            flex,
                            {
                                includeColumnHeaders: true,
                                includeRowHeaders: true
                                // includeStyles: false,
                                // formatItem: store.customContent
                                //     ? store.exportFormatItem
                                //     : null
                            },
                            `${WIDGET.title}.xlsx`
                        );
                    } else if (actionMenu.params.format === 'csv') {
                        let rng = new CellRange(0, 0, flex.rows.length - 1, flex.columns.length - 1), csv = flex.getClipString(rng, ClipStringOptions.CSV, true, false);
                        saveFile(csv, `${WIDGET.title}.csv`);
                    } else if (actionMenu.params.format === 'pdf') {
                        gridPdf.FlexGridPdfConverter.export(flex, `${WIDGET.title}.pdf`, {
                            maxPages: 10,
                            exportMode: gridPdf.ExportMode.All,
                            scaleMode: gridPdf.ScaleMode.ActualSize,
                            documentOptions: {
                                pageSettings: {
                                    layout: pdf.PdfPageOrientation.Portrait
                                },
                                header: {
                                    declarative: {
                                        text: '\t&[Page]\\&[Pages]'
                                    }
                                },
                                footer: {
                                    declarative: {
                                        text: '\t&[Page]\\&[Pages]'
                                    }
                                }
                            },
                            styles: {
                                cellStyle: {
                                    backgroundColor: '#ffffff',
                                    borderColor: '#c6c6c6'
                                },
                                altCellStyle: {
                                    backgroundColor: '#f9f9f9'
                                },
                                groupCellStyle: {
                                    backgroundColor: '#dddddd'
                                },
                                headerCellStyle: {
                                    backgroundColor: '#eaeaea'
                                }
                            }
                        });
                    }
                    break
                } case 'onExport__Click': {
                    store.widget_open({
                        widget_class: 'widget_Export',
                        parentWidget: { id: WIDGET.id, title: WIDGET.title, doc:{params: WIDGET.doc.params} },
                        parentActionMenu: actionMenu,
                    })

                    break
                } case 'onImport__Click': {
                    store.widget_open({
                        widget_class: 'widget_Import',
                        parentWidget: { id: WIDGET.id, title: WIDGET.title, doc:{params: WIDGET.doc.params} },
                        parentActionMenu: actionMenu,
                    })

                    break
                } case 'onImportFile__Click': {
                    var input = document.createElement('input');
                    input.type = 'file';

                    input.onchange = e => {
                        var file = e.target.files[0];
                        var file_name = file.name;
                        var reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = readerEvent => {
                            WIDGET['doc'].file_dounloadToBackend = readerEvent.target.result
                            WIDGET['doc'].file_name = file_name
                            // vueObj.content_Changed()
                            store.executeWidgetMethod(vueObj, { 'command': 'executeWidgetMethod', 'method': 'import_analysis_attrs' })
                            // store.import_file(vueObj)
                        }
                    }
                    input.click();

                    break
                } case 'onChartTypeChanged__Click': {
                    vueObj.pivotChart.chartType = wjcOlap.PivotChartType[actionMenu.chartType];
                    break
                } case 'onShowDatail__Click': {
                    break
                } case 'onApplySelector__Click': { // selector
                    let selector_widget_doc = WIDGET.doc.params.selector_widget_doc
                    if (!selector_widget_doc) break

                    let WORKSPACE = store.WORKSPACES[WIDGET.workspace],
                        // rowGROUP = store.get_rowGROUP(WIDGET.doc.params),
                        data = WIDGET.doc.data,
                        rowWHERE = store.get_rowWHERE(WORKSPACE.doc.params, WIDGET.doc.params, selector_widget_doc)

                    if (actionMenu.selctor_currentRow) {
                        const item = vueObj.WIDGET.vueObj.selectedRow(actionMenu.binding)?.dataItem,
                            flex = vueObj.WIDGET.vueObj.active_flex(actionMenu.binding)
                        if (item) {
                            for (let row of data) {
                                row.selector = false
                            }
                            item.selector = true
                            flex.refresh()
                        }
                    }     

                    rowWHERE.use = false
                    rowWHERE.rightValue.values.length = 0
                    if (WIDGET.doc.params['graph-eval']) {
                        if (WIDGET.doc.selector_values) {
                            rowWHERE.use = true
                            for (let value of WIDGET.doc.selector_values) {
                                rowWHERE.rightValue.values.push({value:value})
                            }
                        }
                    } else if (WIDGET.doc.params['pre-select-KPIs']) {
                        store.scan_selector_values(data, rowWHERE, selector_widget_doc)
                    } else {
                        for (let row of data) {
                            if (row.selector) {
                                rowWHERE.use = true
                                rowWHERE.rightValue.values.push({value:row[selector_widget_doc.binding]}) // leftValue
                            }
                        }
                    }

                    WIDGET.doc.params.flex_havActiveSelectors = rowWHERE.use
                    WIDGET.attrs_vueObj.WIDGET.set_commandPanel()

                    store.updateRelatedWidgets(WIDGET.workspace, WIDGET.page, WIDGET)
                    break
                } case 'onClearSelector__Click': { // selector
                    let selector_widget_doc = WIDGET.doc.params.selector_widget_doc
                    if (!selector_widget_doc) break

                    let WORKSPACE = store.WORKSPACES[WIDGET.workspace],
                        // rowGROUP = store.get_rowGROUP(WIDGET.doc.params),
                        data = WIDGET.doc.data,
                        rowWHERE = store.get_rowWHERE(WORKSPACE.doc.params, WIDGET.doc.params, selector_widget_doc)

                    if (WIDGET.doc.params['graph-eval']) {
                        WIDGET.doc.params.flex_havActiveSelectors = false
                        WIDGET.attrs_vueObj.WIDGET.set_commandPanel()
                        
                        WIDGET.doc.selector_values = []
                        let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                        attr_vueObj.set_highlighted()
                        attr_vueObj.refresh()

                    } else if (rowWHERE.use) {
                        rowWHERE.use = false
                        // rowWHERE.rightValue.values.length = 0
                        for (let row of data) {
                            if (row.selector) {
                                row.selector = false
                            }
                        }

                        WIDGET.doc.params.flex_havActiveSelectors = false
                        WIDGET.attrs_vueObj.WIDGET.set_commandPanel()

                        // store.executeStoreMethod(vueObj, { 'command': 'executeWidgetMethod', 'method': 'select', 'doc_sendToBackend_exclude': ['data'] })
                        const flex = vueObj.WIDGET.vueObj.active_flex(actionMenu.binding)
                        flex.refresh()

                        store.updateRelatedWidgets(WIDGET.workspace, WIDGET.page, WIDGET)
                    }

                    break
                } case 'updateRelatedWidgets': {
                    store.updateRelatedWidgets(WIDGET.workspace, WIDGET.page, WIDGET, actionMenu)

                    break
                } case 'onAttrOpen__Click': {
                    vueObj.onAttrOpen__Click(vueObj.active_attr)
                    break
                } case 'onAttrChooseInList__Click': {
                    vueObj.onAttrChooseInList__Click(vueObj.active_attr)
                    break
                } case 'onShowInList__Click': {
                    let widget_init = {
                        widget: 'widget-list',
                        parentActionMenu: actionMenu,
                        props: { current_item: WIDGET.doc.id },
                        params_onOpen: {
                            current_item: WIDGET.doc,
                            segment: WIDGET.doc.segment,
                        },
                    }

                    store.widget_open(widget_init, vueObj)
                    break
                } case 'onAttrClear__Click': {
                    break
                } case 'onJS_deep__Click': {
                    vueObj.JS_deep = actionMenu.params.JS_deep
                    vueObj.WIDGET.doc = store.JS_parse(vueObj.content_str)
                    vueObj.content_str = store.JS_stringify(vueObj.WIDGET.doc, vueObj.JS_deep)
                    break
                } case 'onOpenMyProfile__Click': {
                    store.widget_open({
                        widget_class: 'widget_USER',
                        parentActionMenu: actionMenu,
                        doc: {
                            id: store.user.id,
                            title: store.user.title,
                        },
                        props: {
                            id: store.user.id
                        },
                    });
                    break
                } case 'onLogOut__Click': {
                    store.logout(null, null, true)
                    break
                } case 'dounloadFile': {
                    let link = document.createElement("a");
                    link.download = actionMenu.fileName_dounloadToFrontend;
                    link.href = encodeURI(actionMenu.file_dounloadToFrontend);
                    link.click();
                    break
                } case 'updateDoc_ifChanged': {
                    let attr_vueObj = vueObj.active_attr_vueObj('dataSheet')
                    if (attr_vueObj.backendProcessed) {
                            console.log(`[${WIDGET.id}][${actionMenu.action_id_front}]${actionMenu.command} ${actionMenu.method} BREAK, because backendProcessed`)
                        break
                    }

                    if (!store.updateDoc_ifChanged(WIDGET.doc.data, actionMenu.doc.data)) {
                        WIDGET.doc.data = actionMenu.doc.data
                        // attr_vueObj.flex.collectionView.refresh()
                        attr_vueObj.content_Changed()
                    }

                    attr_vueObj.undoStack.length = 0
                    attr_vueObj.changesStack = {}
                    attr_vueObj.flex.refresh()
    
                    break
                } case 'updateDoc': {
                    if ('doc' in actionMenu) {
                        WIDGET.doc = actionMenu.doc
                    }
                    if ('attrs' in actionMenu) {
                        store.updateAttrs(WIDGET.attrs, actionMenu.attrs)

                        WIDGET.attrs_vueObj.WIDGET.set_commandPanel()
                    }
                    if ('doc' in actionMenu || 'attrs' in actionMenu) {
                        store.set_nonEmptyWidget(WIDGET)
                        store.set_isMappedWidget(WIDGET)
                    }
                    if ('refresh_itemsSource' in actionMenu) {
                        store.refresh_itemsSource(actionMenu.refresh_itemsSource.segment)
                    }
                    vueObj.content_Changed()
                    // this.widgetResize(vueObj)

                    if ('actions_afterUpdate' in actionMenu) {
                        store.executeStoreMethod(vueObj, actionMenu.actions_afterUpdate)
                    }

                    // store.rightArea_hide()

                    break
                // } case 'onEditAttrs__Click': {
                //     WIDGET.editAttrs = !WIDGET.editAttrs
                //     if (!WIDGET.editAttrs) {
                //         let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
                //         let PAGE = WORKSPACE.PAGES[WIDGET.page]
                //         let rightArea = PAGE.rightArea

                //         store.attr_active = null;
                //         store.attr_activeEl = null;

                //         // close WIDGETS
                //         if (rightArea.WIDGET) {
                //             store.rightArea_hide()
                //         }
                //     }
                //     break
                } case 'plus-click': {
                    let tree = vueObj.active_flex(actionMenu.binding),
                        attrs = tree.itemsSource,
                        node = tree.selectedNode,
                        attr_new = store.attr_new(attrs, actionMenu)
                    if (actionMenu.duplicate && node) { // duplicate_Click
                        attr_new.title = store.incrementName(node.dataItem.title)
                        attr_new.attr_type = node.dataItem.attr_type
                    }
                    if (node && node.parentNode) {
                        tree.selectedNode = node.parentNode.addChildNode(node.index + 1, attr_new)
                        this.tree_WidgetatItem(tree, tree.selectedNode)
                    } else if (node) {
                        tree.selectedNode = tree.addChildNode(node.index + 1, attr_new)
                        this.tree_WidgetatItem(tree, tree.selectedNode)
                    } else {
                        tree.itemsSource.push(attr_new)
                        tree.loadTree(true);
                        tree.selectedItem = tree.itemsSource[0]
                    }
                    break
                } case 'plus-child-click': {
                    let tree = vueObj.active_flex(actionMenu.binding),
                        attrs = tree.itemsSource,
                        node = tree.selectedNode,
                        attr_new = store.attr_new(attrs, actionMenu)
                    if (node) {
                        let i = node.nodes ? node.nodes.length : 0;
                        tree.selectedNode = node.addChildNode(i, attr_new)
                        this.tree_WidgetatItem(tree, tree.selectedNode)
                    } else {
                        tree.itemsSource.push(attr_new)
                        tree.loadTree(true);
                        tree.selectedItem = tree.itemsSource[0]
                    }
                    break
                } case 'minus-click': {
                    let tree = vueObj.active_flex(actionMenu.binding),
                        node = tree.selectedNode
                    if (node) {
                        node.remove()
                    }
                    break
                } case 'changeAttrState': {
                    for (const attr of actionMenu.attrs_newState) {
                        const attr_ = store.attr_find_by_keys(WIDGET.attrs, attr.name)
                        if (attr_) {
                            for (let key in attr) {
                                attr_[key] = attr[key]
                            }
                        }
                    }
                    if (actionMenu.defaultSeriesVisibility) {
                        store.executeStoreMethod(WIDGET.vueObj, { 'command':'set_visibleChart', 'defaultSeriesVisibility':true })
                    }
                    vueObj.content_Changed(null, 'changeAttrState')
                    break
                } case 'set_viewDoc_params': {
                    const value = store.attr_get(WIDGET, form_attr),
                    parts = (form_attr.binding||'').split('.'), // 'viewDoc-params.dataSheet.showCommandPanel'
                    name = parts[1],
                    param = parts[2],
                    // target_attr = store.attr_find_by_keys(WIDGET.attrs, name)
                    target_attrs = store.attr_findAll(WIDGET.attrs, name, 'name')
                    
                    for (let target_attr of target_attrs) {
                        if (target_attr && param) {
                            if (target_attr[param] !== value) {
                                target_attr[param] = value
                                // target_attr.tooltip = `${++store.i_test}`
                                if (param === 'showCommandPanel') {
                                    const target_attr_vueObj = vueObj.active_attr_vueObj(name)
                                    target_attr_vueObj.set_commandPanel()
                                }
                                if (param === 'aggregateFooters') {
                                    const target_attr_vueObj = vueObj.active_attr_vueObj(name)
                                    target_attr_vueObj.aggregateFooters()
                                }
                            }
                        }
                    }

                    // if (attr && WIDGET.doc && WIDGET.doc.params && WIDGET.doc.viewDoc-params?.dataSheet) {
                    //     const params_viewDocAttr = WIDGET.doc.viewDoc-params.dataSheet
                    //     for (const [param, value] of Object.entries(params_viewDocAttr)) {
                    //         if (attr[param] !== value) {
                    //             attr[param] = value
                    //             if (param === 'showCommandPanel') {
                    //                 attr_vueObj.set_commandPanel()
                    //             }
                    //             if (param === 'aggregateFooters') {
                    //                 attr_vueObj.aggregateFooters()
                    //             }
                    //         }
                    //     }
                    
                    //     if (!params_viewDocAttr.frozenColumns && attr.frozenColumns_default) {
                    //         if (attr.frozenColumns !== attr.frozenColumns_default) {
                    //             attr.frozenColumns = attr.frozenColumns_default
                    //         }
                    //     }
                    // }
                    break
                // } case 'set_attr_use': { // FIXIT del
                //     let attr2 = store.attr_find_by_keys(WIDGET.attrs, form_attr.use_for_attr_name)
                //     const [doc, binding] = store.attr_get_link(WIDGET, form_attr),
                //         value = doc[binding]
                //     if (attr2 && attr2.use !== value) {
                //         attr2.use = value
                //     }
                //     break
                } case 'set_visibleChart': {
                    let attr_data = store.attr_find_by_keys(WIDGET.attrs, 'dataSheet')
                    if (attr_data) {
                        let vueSheet = WIDGET.attrs_vueObj[attr_data.id]
                        for (let item of WIDGET.doc.dataSheet) {
                            if (actionMenu.defaultSeriesVisibility) {
                                item.visibleChart = (item.measure=='MEASURE_baseLine' || item.measure=='MEASURE_forecast')
                            } else {
                                item.visibleChart = false
                            }
                            vueSheet.set_visibleChart(item.visibleChart, item.measure)
                        }
                    }
                    break
                } case 'onAddFilterOnSelectedCells__Click': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    attr_vueObj.addFilterOnSelectedCells()
                    break
                } case 'onClearFilter__Click': {
                    let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                    attr_vueObj.clearFilter()
                    break
                } case 'set_nonEmptyWidget': {
                    let attr = store.attr_find(WIDGET.groupMenu.commandPanel, 'non-empty-widget', 'name')
                    attr.active = !attr.active

                    store.set_nonEmptyWidget(WIDGET)
                    break
                } case 'set_isMappedWidget': {
                    let attr = store.attr_find(WIDGET.groupMenu.commandPanel, 'isMapped-widget', 'name')
                    attr.active = !attr.active

                    store.set_isMappedWidget(WIDGET)
                    break
                } case 'validateAll_isMappedWidget': {
                    store.validateAll_isMappedWidget(WIDGET)
                    break
                } case 'close_childrenWidgets': {
                    store.close_childrenWidgets(WIDGET)
                    break
                }
            }
        },
        updateRelatedWidgets(workspace, page, WIDGET=null, actionMenu={}) {
            const store = this
            let WORKSPACE = store.WORKSPACES[workspace]
            let PAGE = WORKSPACE.PAGES[page]
            let WIDGETS = PAGE.WIDGETS

            // let workspaceWidget = WORKSPACE.workspaceWidget
            for (let WIDGET2 of WIDGETS.values()) {
                if (WIDGET2 !== WIDGET && WIDGET2.workspace === workspace) {
                    // WIDGET2.workspace_doc = WORKSPACE.doc 2024-07-31
                    store.executeStoreMethod(WIDGET2.vueObj, { 
                        command: 'executeWidgetMethod',
                        method: 'select',
                        doc_sendToBackend_exclude: ['data'],
                        updateDoc_ifChanged: actionMenu.updateDoc_ifChanged,
                    })
                }
            }

            // if (PAGE.leftArea.WIDGET && PAGE.leftArea.WIDGET !== WIDGET) {
            //     store.executeStoreMethod(PAGE.leftArea.WIDGET.vueObj, { 
            //         command: 'executeWidgetMethod',
            //         method: 'select',
            //         doc_sendToBackend_exclude: ['data'],
            //         updateDoc_ifChanged: actionMenu.updateDoc_ifChanged,
            //     })
            // }
        },
        setSelectionWorkspace_date(WIDGET=null, selectionWorkspace=null) {
            const store = this
            let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            let PAGE = WORKSPACE.PAGES[WIDGET.page]
            let WIDGETS = PAGE.WIDGETS

            if (selectionWorkspace !== null) {
                WORKSPACE.selectionWorkspace.selectedDates = selectionWorkspace.selectedDates
            }

            if (WORKSPACE.selectionWorkspace?.selectedDates) {
                for (let WIDGET2 of WIDGETS.values()) {
                    if (WIDGET2 !== WIDGET && WIDGET2.getSelectionWorkspace_date) {
                        WIDGET2.getSelectionWorkspace_date()
                    }
                }
            }

            // if (PAGE.leftArea.WIDGET && PAGE.leftArea.WIDGET !== WIDGET) {
            // }
        },
        use_selectorMeasures(WIDGET=null, selectedMeasures=null) {
            const store = this
            let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            let PAGE = WORKSPACE.PAGES[WIDGET.page]
            let WIDGETS = PAGE.WIDGETS

            if (selectedMeasures !== null) {
                PAGE.selectedMeasures = selectedMeasures
            }

            if (PAGE.selectedMeasures) {
                for (let WIDGET2 of WIDGETS.values()) {
                    if (WIDGET2.doc?.params?.use_selectorMeasures) { // WIDGET2 !== WIDGET && 
                        // dataSheet
                        let attr_vueObjSheet = WIDGET2.vueObj?.active_attr_vueObj('dataSheet')
                        if (attr_vueObjSheet?.attr?.use_selectorMeasures && attr_vueObjSheet?.attr?.attrs) {
                            let isChanged = false
                            for (let item of attr_vueObjSheet.flex.rows) {
                                if (item.dataItem?.measure in PAGE.selectedMeasures) {
                                    let selector = PAGE.selectedMeasures[item.dataItem.measure]
                                    if (item.dataItem.selector !== selector) {
                                        item.dataItem.selector = selector
                                        isChanged = true
                                    }
                                }
                            }
                            if (isChanged) {
                                attr_vueObjSheet.flex.refresh()
                            }
                        }

                        // dataChart
                        let attr_vueObjChart = WIDGET2.vueObj?.active_attr_vueObj('dataChart')
                        if (attr_vueObjChart?.flex?.series) {
                            for (let series of attr_vueObjChart.flex.series) {
                                if (series.binding in PAGE.selectedMeasures) {
                                    let visibility = null
                                    if (PAGE.selectedMeasures[series.binding]) {
                                        // visibility = 1 // Plot
                                        visibility = 0 // Visible
                                    } else {
                                        // visibility = 3 // Hidden
                                        visibility = 2 // Legend
                                    }
                                    if (series.visibility !== visibility) {
                                        series.visibility = visibility
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        JS_stringifyHtml(value_dict, deep=0) {
            const store = this
            let html = ''
            // keyLevel = deep === 0 ? '' : '2'  // ${keyLevel}
        
            for (let key in value_dict) {
                if (store.isinstance_dict(value_dict[key])) {
                    html += `<b class='json-key'>${key}:</b> { ${this.JS_stringifyHtml(value_dict[key], deep+1)} } `
                } else {
                    html += `<b class='json-key'>${key}:</b> ${value_dict[key]} `
                    // html += `<b class='json-key'>${key}:</b> <b class='json-value'>${value_dict[key]}</b>`
                }
            }
        
            return html
        },
        JS_stringify(value_dict, JS_deep = 2, space = 4, tab = '', sort=false, isRoot=true) {
            const store = this,
            validIdentifier = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/
            let value_dict2 = value_dict
            if (sort) {
                value_dict2 = store.JSON_sort(value_dict)
            }
        
            // return JSON.stringify(value_dict2, null, space)
        
            // Check if the value is an object (and not an array)
            if (JS_deep && store.isinstance_dict(value_dict2)) {
                let s = []

                // Iterate over the object's keys
                for (let key in value_dict2) {
                    if (Object.prototype.hasOwnProperty.call(value_dict2, key)) {
                        if (!validIdentifier.test(key)) {
                            s.push(`${tab}'${key}'`)
                        } else {
                            s.push(`${tab}${key}`)
                        }
                        s.push(': ')

                        // Handle arrays
                        if (JS_deep > 1 && Array.isArray(value_dict2[key])) {
                            s.push('[\n')
                            value_dict2[key].forEach((item, index) => {
                                if (JS_deep === 2) {
                                    let subValue = JSON.stringify(item)
                                    s.push(`${tab}    ${subValue},\n`)
                                } else {
                                    let subValue = store.JS_stringify(item, JS_deep - 1, space, `${tab}        `, sort, false)
                                    if (store.isinstance_dict(item)) {
                                        s.push(`${tab}    {\n`)
                                        s.push(`${subValue}`)
                                        s.push(`${tab}    },\n`)
                                    } else {
                                        s.push(`${tab}    ${subValue},\n`)
                                    }
                                }
                            })
                            s.push(`${tab}],\n`)  // Closing array bracket

                        // Handle nested objects
                        } else if (JS_deep > 1 && typeof value_dict2[key] === "object" && value_dict2[key] !== null) {
                            let subValue = store.JS_stringify(value_dict2[key], JS_deep - 1, space, `${tab}    `, sort, false)
                            s.push(`{\n${subValue}`)
                            s.push(`${tab}},\n`)

                        // Handle undefined values
                        } else if (value_dict2[key] === undefined) {
                            s.push('undefined')
                            s.push(',\n')

                        // Handle all other values
                        } else {
                            s.push(JSON.stringify(value_dict2[key]))
                            s.push(',\n')
                        }
                    }
                }
                return s.join('')

            } else if (JS_deep && Array.isArray(value_dict2)) {
                // Handle case when the passed value is an array
                let s = ['[\n']
                value_dict2.forEach((item) => {
                    if (typeof item === "object" && item !== null) {
                        let subValue = store.JS_stringify(item, JS_deep - 1, space, `${tab}    `, sort, false)
                        s.push(`${tab}    {\n`)
                        s.push(`${subValue}`)
                        s.push(`${tab}    },\n`)
                    } else {
                        let subValue = JSON.stringify(item)
                        s.push(`${tab}    ${subValue},\n`)
                    }
                })
                s.push(`${tab}]`)  // Closing array bracket
                return s.join('')
                
            } else if (isRoot) {
                return JSON.stringify(value_dict2).slice(1, -1)
            } else {
                // If not an object and not an array, return the value as it is
                return JSON.stringify(value_dict2)
            }
        },
        JS_parse(value_str) {
            //return JSON.parse(value_str)
            let r3 = {}
            let r2 = eval(`r3={ ${value_str} }`)
            return r2
        },
        widgetResize(vueObj, timeout = 400, isManuallyResizing=false) {
            // console.log('store widgetResize')
            const store = this, WIDGET = vueObj.WIDGET;
            if (WIDGET.timerId) {
                clearTimeout(WIDGET.timerId)
                WIDGET.timerId = null
            }
            WIDGET.isManuallyResizing = WIDGET.isManuallyResizing || isManuallyResizing
            WIDGET.timerId = window.setTimeout(() => {
                store.widgetResize_afterTimer(vueObj)
            }, timeout)
        },
        widgetResize_afterTimer(vueObj) {
            // console.log('widgetResize afterTimer')
            const store = this
            const WIDGET = vueObj.WIDGET
            const elementForm = document.getElementById(`WIDGET${WIDGET.id}`)

            if (elementForm) {
                WIDGET.timerId = null
                WIDGET.Rect = elementForm.getBoundingClientRect()

                for (let attrResize of WIDGET.attrsResize) {
                    attrResize()
                }
            
                const PAGE = store.WORKSPACES[WIDGET.workspace].PAGES[WIDGET.page]
                if (PAGE) {
                    if (WIDGET.isManuallyResizing) {
                        let stylePage = PAGE.stylePage,
                        styleWidget = WIDGET.styleWidget,
                        currentWidth_rem = Math.max(1, Math.min(stylePage.width_rem, WIDGET.Rect.width / stylePage.widthWidget)),
                        currentHeight_rem = Math.max(1, Math.min(stylePage.height_rem, WIDGET.Rect.height / stylePage.heightWidget))

                        if (currentWidth_rem > styleWidget.width_rem) {
                            currentWidth_rem = Math.ceil(currentWidth_rem)
                        } else {
                            currentWidth_rem = Math.floor(currentWidth_rem)
                        }
                        if (WIDGET.doc.widthMin_rem !== currentWidth_rem) {
                            WIDGET.doc.widthMin_rem = currentWidth_rem
                        }

                        if (currentHeight_rem > styleWidget.height_rem) {
                            currentHeight_rem = Math.ceil(currentHeight_rem)
                        } else {
                            currentHeight_rem = Math.floor(currentHeight_rem)
                        }
                        if (WIDGET.doc.heightMin_rem !== currentHeight_rem) {
                            WIDGET.doc.heightMin_rem = currentHeight_rem
                        }

                        WIDGET.isManuallyResizing = false
                    }

                    PAGE?.mainArea?.vueObj.checkWorkspaceScroll()
                }
            }
        },
        Scale(vueObj, params) {
            const WIDGET = vueObj.WIDGET

            if (params.widthMin_rem) {
                WIDGET.doc.widthMin_rem = params.widthMin_rem
            }
            if (params.heightMin_rem) {
                WIDGET.doc.heightMin_rem = params.heightMin_rem
            }

            this.scrollInWorkspace(WIDGET)

            // const WidgetContainer = $('#WIDGET'+WIDGET.id)[0] //  document.querySelector(`#WIDGET${WIDGET.id}`) //document.getElementById(...)
            // if (!WidgetContainer) {
            //     return
            // }

            // // reset if there was a manual resizing of the WIDGET
            // if (WidgetContainer.style.width) {
            //     WidgetContainer.style.width = null
            // }
            // if (WidgetContainer.style.height) {
            //     WidgetContainer.style.height = null
            // }

            // // this.widgetResize(vueObj) 2023 03 24 look widgetResize()

            // let reverse = (!params.cssClassW || WidgetContainer.classList.contains(params.cssClassW)) && (!params.cssClassH || WidgetContainer.classList.contains(params.cssClassH))

            // const cssClassW_2024_10 = false
            // if (params.cssClassW && cssClassW_2024_10) {
            //     let allcssClassW = new Set(['widget-w1', 'widget-w2', 'widget-w3', 'widget-w4'])
            //     for (let class_ of allcssClassW) {
            //         if (class_ !== params.cssClassW) {
            //             WidgetContainer.classList.remove(class_)
            //         }
            //     }
            //     if (params.cssClassW === 'none' || reverse) {
            //         WidgetContainer.classList.remove(params.cssClassW)
            //     } else if (params.cssClassW) {
            //         WidgetContainer.classList.add(params.cssClassW)
            //     }
            // }
            // if (params.cssClassH && cssClassW_2024_10) {
            //     let allcssClassH = new Set(['widget-h0', 'widget-h1', 'widget-h2', 'widget-h3', 'widget-h4'])
            //     for (let class_ of allcssClassH) {
            //         if (class_ !== params.cssClassH) {
            //             WidgetContainer.classList.remove(class_)
            //         }
            //     }
            //     if (params.cssClassH === 'none' || reverse) {
            //         WidgetContainer.classList.remove(params.cssClassH)
            //     } else if (params.cssClassH) {
            //         WidgetContainer.classList.add(params.cssClassH)
            //     }
            // }
        },
        // prepareWHERE(WHERE) {
        //     for (let rowWHERE of params.WHERE) {
        //         if (rowWHERE.selector_widget === selector_widget_doc.id) {
        //             // params2.WHERE.push(rowWHERE)
        //             return rowWHERE
        //         }
        //     }
        // },
        get_itemsSource(vueObj, input, attr, input_is = 'not_data') {
            const store = this, WIDGET = vueObj.WIDGET
            let itemsSource = null,
                need_backend = false,
                params = {
                    segment: store.get_segment(attr),
                }

            // from attr
            if (attr.itemsSource && attr.itemsSource.length) {
                itemsSource = { data: attr.itemsSource, change_index: 0 }
            } else {
                itemsSource = store.itemsSource[params.segment]
                if (!itemsSource) {
                    itemsSource = store.itemsSource[params.segment] = { segment: params.segment, change_index: 0 }
                    need_backend = true
                }
            }

            if (vueObj.attr?.attr_type === 'AttrLink') {
                vueObj.itemsSource = itemsSource
            }

            if (itemsSource.data) {
                store.set_itemsSource_dataMap(itemsSource)
            }

            if (itemsSource.dataMap) {
                // set itemsSource
                if (input_is === 'data') {
                    let bindingTiltle = `${attr.binding}.title`
                    for (let row of input) {
                        if (attr.binding in row && !(bindingTiltle in row)) {
                            if (itemsSource.ids[row[attr.binding]]) {
                                row[bindingTiltle] = itemsSource.ids[row[attr.binding]].title
                            } else {
                                row[bindingTiltle] = row[attr.binding]
                            }
                        }
                    }
                } else {
                    input.itemsSource_myFreeze = true
                    if ('dataMap' in input) {
                        // AttrGrid
                        input.dataMap = itemsSource.dataMap
                        if (!attr.relation_segment) {
                            input.dataMapEditor = 1 // DropDownList,   look tplBtnAttrDoc_showDroppedDown
                        }
                    } else {
                        // AttrLink
                        const selectedValue = input.selectedValue
                        input.itemsSource = itemsSource.data
                        input.selectedValue = selectedValue
                        // input.showDropDownButton = !input.isReadOnly
                    }
                    input.itemsSource_myFreeze = false
                }
            } else {
                input.showDropDownButton = false
                store.async_get_itemsSource_data(vueObj, params, need_backend)
                // wait in watch
            }

            return itemsSource
        },
        async async_get_itemsSource_data(vueObj, params, need_backend = false) {
            const store = this, WIDGET = vueObj.WIDGET
            if (!params.segment) {
                console.log('!!!async_get_itemsSource_data(): params.segment==null')
                return
            }
            if (!store.itemsSource[params.segment]) {
                store.itemsSource[params.segment] = { segment: params.segment, change_index: 0 }
                need_backend = true
            }
            params.showArchived = false
            if (!need_backend) {
                console.log(`get_itemsSource delayed:${params.segment}`)
            } else {
                try {
                    let timeStart = vueObj.time = performance.now()
                    $.ajax({
                        url: store.url_backend_api,
                        type: "POST",
                        // timeout: 50000,
                        data: JSON.stringify({
                            command: 'get_itemsSource',
                            showSQL: store.showSQL,
                            params: params,
                            user: store.user,
                        }),
                        contentType: "application/json",
                        dataType: 'json',
                        // async: false,

                        success: function (answer, textStatus, jqXHR) {
                            store.TERMINAL(WIDGET, answer, null, timeStart, jqXHR)
                            if (!store.check_loginAfterAjax(vueObj, answer)) return
                            console.log(`get_itemsSource:${params.segment}, length:${answer.data.length}, time:${Math.round(performance.now() - vueObj.time) / 1000}`)

                            store.itemsSource[params.segment].data = answer.data
                            store.set_itemsSource_dataMap(store.itemsSource[params.segment])
                        },
                        error: function (error) {
                            store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                            if (WIDGET) {
                                WIDGET.loading = false
                            }
                        },
                    })
                } catch (error) {
                    store.TERMINAL(WIDGET, null, {text:error, status:'error'})
                }
            }
        },
        set_itemsSource_dataMap(itemsSource) {
            const store = this
            if (!itemsSource.dataMap) {
                itemsSource.dataMap = new wjGrid.DataMap(itemsSource.data, 'id', 'title');

                // if (!itemsSource.ids && itemsSource.segment=='SEGMENTS') {
                itemsSource.ids = {}
                for (let item of itemsSource.data) {
                    itemsSource.ids[item.id] = item
                }

                if (itemsSource.segment) {
                    store.itemsSource.change_type = itemsSource.segment
                    store.itemsSource_change_index++
                }

                itemsSource.change_index++
            }
        },
        refresh_itemsSource(segment, new_item) {
            const store = this

            if (store.itemsSource[segment]) {
                // delete store.itemsSource[segment]
                delete store.itemsSource[segment].data
                delete store.itemsSource[segment].dataMap
                delete store.itemsSource[segment].ids
                store.async_get_itemsSource_data({}, { segment: segment }, true)
            }

            // let itemsSource = store.itemsSource[segment],
            //     find = false
            // if (itemsSource) {
            //     for (let item of itemsSource.data) {
            //         if (item.id === new_item.id) {
            //             item.title = new_item.title
            //             find = true
            //             break
            //         }
            //     }
            //     // if is new 
            //     if (!find) {
            //         itemsSource.data.push(new_item)
            //     }
            //     itemsSource.dataMap = new wjGrid.DataMap(itemsSource.data, 'id', 'title');
            // }
        },
        open_widgetList_forAttr(item, attr, params_onOpen, vueObj) {
            const store = this
            let widget_init = {
                widget: 'widget-list',
                parentActionMenu: { 'action_id':'action-front-9', method: 'store.open_widgetList_forAttr' },
                parent_attr: attr,
                props: {},
                params_onOpen: {
                    ...params_onOpen,
                    current_item: item,
                    segment: store.get_segment(attr),
                },
            }

            store.widget_open(widget_init, vueObj)
        },
        get_segment(attr) {
            if (attr.attr_col && 'relation_segment' in attr.attr_col) {
                return attr.attr_col.relation_segment
            } else if ('relation_segment' in attr) {
                return attr.relation_segment
            }
        },
        scrollInWorkspace(WIDGET) {
            const store = this
            // const WidgetContainer = $('#WIDGET'+WIDGET.id)[0]
            // const active_WidgetRect = WidgetContainer.getBoundingClientRect()

            WIDGET.styleWidget.dock = ''
            WIDGET.styleWidget.setVisible = true
            
            const PAGE = store.WORKSPACES[WIDGET.workspace].PAGES[WIDGET.page]
            PAGE.mainArea.vueObj.setStyle()
    
            // // if (WidgetContainer) {
            // //     WidgetContainer.scrollIntoView({ behavior: 'smooth', block: 'end' });
            // // }

            // const AppTopBar = $('.topbar')[0].getBoundingClientRect() // $('#AppTopBar')
            // const pageYOffset = window.scrollY

            // if (active_WidgetRect.top < AppTopBar.bottom) {
            //     // scroll up
            //     window.scrollTo({
            //         top: pageYOffset - (AppTopBar.bottom - active_WidgetRect.top),
            //         left: 0,
            //         behavior: 'smooth'
            //     })
            // } else if (window.innerHeight < active_WidgetRect.bottom) {
            //     let min_height = 200,
            //     bottom = active_WidgetRect.bottom
            //     if (active_WidgetRect.bottom - active_WidgetRect.top < min_height) {
            //         bottom += min_height
            //     }
            //     // scroll down
            //     window.scrollTo({
            //         top: pageYOffset + (bottom - window.innerHeight),
            //         left: 0,
            //         behavior: 'smooth'
            //     })
            // }
        },
        title_toUpperCase(str) {
            if (!str) return str;
            return str[0].toUpperCase() + str.slice(1);
        },
        createElementFromHTML(htmlString) {
            var div = document.createElement('div');
            div.innerHTML = htmlString.trim();

            // Change this to div.childNodes to support multiple top-level nodes.
            return div.firstChild;
        },
        async executeWidgetMethod(vueObj, actionMenu) {
            const store = this, WIDGET = vueObj.WIDGET
            if (WIDGET.close) { return }            
            WIDGET.loading = true
            WIDGET.notification.visible = false
            let request = {}
            let widget_to_transfer = {
                title: WIDGET.title,
                id: WIDGET.id,
                widget_class: WIDGET.widget_class,
                parentWidget: WIDGET.parentWidget,
                params_onOpen: WIDGET.params_onOpen,
                attrs: WIDGET.attrs,
            }
            const WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            if (WORKSPACE.doc) { // LOOK await 'router.beforeEach'.saveDoc()
                widget_to_transfer.workspace = WORKSPACE.doc.id
                // widget_to_transfer.workspace_doc = WORKSPACE.doc
            }
            let doc_stayOnFront = {}
            if (actionMenu.doc_sendToBackend_exclude || actionMenu.doc_stayOnFront) {
                widget_to_transfer.doc = {}
                for (let key in WIDGET.doc) {
                    if (actionMenu.doc_stayOnFront?.includes(key)) {
                        doc_stayOnFront[key] = WIDGET.doc[key]
                    } else if (actionMenu.doc_sendToBackend_exclude?.includes(key)) {
                        // pass
                    } else {
                        widget_to_transfer.doc[key] = WIDGET.doc[key]
                    }
                }
                if (actionMenu.doc_sendToBackend_exclude?.includes('not ##modified data.row')) {
                    widget_to_transfer.doc.data = widget_to_transfer.doc.data.filter(row => row['##modified'])
                }
            } else {
                widget_to_transfer.doc = WIDGET.doc
            }
            if (actionMenu.doc_sendToBackend_undoStack) {
                let attr_vueObj = vueObj.active_attr_vueObj(actionMenu.binding)
                widget_to_transfer.undoStack = attr_vueObj.undoStack
            }
            if (actionMenu.get_selectedRanges) {
                request.selectedRanges = vueObj.get_selectedRanges(actionMenu.binding)
            }
            WIDGET.last_backend_action = actionMenu.action_id_front
            try {
                let timeStart = vueObj.time = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        showSQL: store.showSQL,
                        ...actionMenu,
                        ...request,
                        process_model: store.process_model_doc.id,
                        workspace: WIDGET.workspace,
                        // command: 'executeWidgetMethod', 
                        // method: actionMenu.method, 
                        WIDGET: widget_to_transfer,
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        store.TERMINAL(WIDGET, answer, null, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(vueObj, answer)) return
                        if (WIDGET.last_backend_action > actionMenu.action_id_front) {
                            console.log(`[${WIDGET.id}][${actionMenu.action_id_front}]${actionMenu.command} ${actionMenu.method} BREAK: ${Math.round(performance.now() - timeStart) / 1000}`)
                            return
                        }
                        console.log(`[${WIDGET.id}][${actionMenu.action_id_front}]${actionMenu.command} ${actionMenu.method} FINISH: ${Math.round(performance.now() - timeStart) / 1000}`)

                        for (let key in doc_stayOnFront) {
                            answer.doc[key] = doc_stayOnFront[key]
                        }

                        let action_after = answer.action_after
                        delete answer.action_after

                        // command=='dounloadFile'
                        WIDGET.loading = false
                        store.executeStoreMethod(vueObj, answer)

                        if (action_after) {
                            store.executeStoreMethod(vueObj, action_after)
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(WIDGET, null, {text:"Backend error", status:'error'})
                        WIDGET.loading = false
                    },
                });
            } catch (error) {
                store.TERMINAL(WIDGET, null, {text:error, status:'error'});
            }
        },
        async executeWidgetMethod_inNewWidget(vueObj, request, actionMenu) {
            const store = this
            let action_after1 = actionMenu.action_after
            if (action_after1 && action_after1.timeout) {
                if (vueObj.timerId) {
                    console.log("setTimeout: clear")
                    clearTimeout(vueObj.timerId);
                    vueObj.timerId = null
                }
                action_after1.executeCoount = 2
                console.log("setTimeout: set")
                vueObj.timerId = window.setTimeout(store.executeStoreMethod_afterTimer, action_after1.timeout, vueObj, action_after1)
            }
            try {
                let timeStart = vueObj.time = performance.now()
                $.ajax({
                    url: store.url_backend_api,
                    type: "POST",
                    // timeout: 50000,
                    data: JSON.stringify({
                        showSQL: store.showSQL,
                        ...request,
                        process_model: store.process_model_doc.id,
                        workspace: store.activeWORKSPACE.doc.id,
                        command: 'executeWidgetMethod_inNewWidget',
                        user: store.user,
                    }),
                    contentType: "application/json",
                    dataType: 'json',
                    // async: false,

                    success: function (answer, textStatus, jqXHR) {
                        if (WIDGET.close) { return }            
                        store.TERMINAL(vueObj.WIDGET, answer, null, timeStart, jqXHR)
                        if (!store.check_loginAfterAjax(vueObj, answer)) return
                        console.log(request.widget_init.title + ' executeWidgetMethod_inNewWidget time: ', Math.round(performance.now() - vueObj.time) / 1000)
                        for (let statistics_key in answer.statistics)
                            console.log(statistics_key + ': ' + answer.statistics[statistics_key])

                        if (vueObj.timerId) {
                            console.log("setTimeout: clear")
                            clearTimeout(vueObj.timerId);
                            vueObj.timerId = null
                        }
                        if (action_after1) {
                            store.executeStoreMethod(vueObj, action_after1)
                        }

                        let action_after2 = answer.action_after
                        delete answer.action_after
                        if (action_after2) {
                            store.executeStoreMethod(vueObj, action_after2)
                        }
                    },
                    error: function (error) {
                        store.TERMINAL(null, null, {text:"Backend error", status:'error'})

                        if (vueObj.timerId) {
                            console.log("setTimeout: clear")
                            clearTimeout(vueObj.timerId);
                            vueObj.timerId = null
                        }
                        if (action_after1) {
                            store.executeStoreMethod(vueObj, action_after1)
                        }
                    },
                })
            } catch (error) {
                store.TERMINAL(null, null, {text:error, status:'error'})
            }
        },
        executeStoreMethod_afterTimer(vueObj, action_after1) {
            const store = this
            console.log("setTimeout: execute")
            vueObj.timerId = null
            store.executeStoreMethod(vueObj, action_after1)

            if (action_after1.executeCoount) {
                action_after1.executeCoount--
                vueObj.timerId = window.setTimeout(store.executeStoreMethod_afterTimer, action_after1.timeout, vueObj, action_after1)
            }
        },
        attr_get(WIDGET0, attr, row = null, col = null) {
            const store = this
            
            let WIDGET = WIDGET0,
            binding = attr.binding
            if (WIDGET0.widget_class === 'widget_DOC_ROW' && WIDGET0.parentWidget?.id !== undefined) {
                const parent = store.findWidget(WIDGET0.parentWidget.id)
                let MDH = WIDGET0.doc.MDH
                binding = MDH.binding + '.' + MDH.iroot + '.' + attr.binding
                
                WIDGET = parent.WIDGET
            }

            if (binding?.startsWith('WIDGET.')) {
                return store.doc_get(WIDGET, store.substringAfterDot(binding), row = row, col = col)
            } else if (binding?.startsWith('store.')) {
                return store.doc_get(store, store.substringAfterDot(binding), row = row, col = col)
            } else {
                return store.doc_get(WIDGET.doc, binding, row = row, col = col)
            }
        },
        attr_get_link(WIDGET0, attr, row = null, col = null) {
            const store = this;

            let WIDGET = WIDGET0,
            binding = attr.binding
            if (WIDGET0.widget_class === 'widget_DOC_ROW' && WIDGET0.parentWidget?.id !== undefined) {
                const parent = store.findWidget(WIDGET0.parentWidget.id)
                let MDH = WIDGET0.doc.MDH
                binding = MDH.binding + '.' + MDH.iroot + '.' + attr.binding
                
                WIDGET = parent.WIDGET
            }

            let value = WIDGET.doc
            if (binding?.startsWith('store.')) {
                return [store, store.substringAfterDot(binding)]
            } else if (binding?.includes('.')) {
                let aBinding = binding?.split('.'),
                bindingLast = ''
                for (let iBinding in aBinding) {
                    bindingLast = aBinding[iBinding]
                    if (!(bindingLast in value)) {
                        if (iBinding == aBinding?.length - 1) {
                            if (attr.attr_type === 'AttrBool') {
                                value[bindingLast] = false
                            } else {
                                value[bindingLast] = ''
                            }
                        } else {
                            value[bindingLast] = {}
                        }
                    }
                    if (iBinding != aBinding?.length - 1) {
                        if (!value[bindingLast]) {
                            value[bindingLast] = {}
                        }
                        value = value[bindingLast]
                    }
                }
                return [value, bindingLast]
            }
            return [value, binding]
        },
        doc_get(doc, binding, row = null, col = null) {
            let doc0 = doc
            // if (!binding?.includes('.')) {
            //     doc0 = doc[binding]
            // } else 
            if (binding in doc) {
                doc0 = doc[binding]
            } else{
                let value = doc,
                aBinding = binding?.split('.'),
                bindingLast = ''
                for (let iBinding in aBinding) {
                    bindingLast = aBinding[iBinding]
                    if (!this.isinstance_object(value)) {
                        return null
                    } else if (bindingLast in value) {
                        value = value[bindingLast]
                    } else {
                        return null
                    }
                }
                doc0 = value
            }
            if (row === null) {
                return doc0
            } else {
                if (row in doc0 && col === null) {
                    return doc0[row]
                } else if (row in doc0 && col in doc0[row]) {
                    return doc0[row][col]
                } else {
                    return null
                }
            }
        },
        attr_set(WIDGET0, attr, value, row = null, col = null) {
            const store = this
            
            let WIDGET = WIDGET0,
            binding = attr.binding
            if (WIDGET0.widget_class === 'widget_DOC_ROW' && WIDGET0.parentWidget?.id !== undefined) {
                const parent = store.findWidget(WIDGET0.parentWidget.id)
                let MDH = WIDGET0.doc.MDH
                binding = MDH.binding + '.' + MDH.iroot + '.' + attr.binding
                
                WIDGET = parent.WIDGET
            }

            if (binding?.startsWith('WIDGET.')) {
                store.doc_set(WIDGET, store.substringAfterDot(binding), value, row, col)
            } else if (binding?.startsWith('store.')) {
                store.doc_set(store, store.substringAfterDot(binding), value, row, col)
            } else {
                store.doc_set(WIDGET.doc, binding, value, row, col)
                if (binding === 'title') {
                    WIDGET.title = value
                }
                // if (WIDGET.widget_class === 'rightArea.WIDGET') {
                //     store.rightArea_editEnded(WIDGET, attr)
                // }
            }

            // afterEdit
            if (WIDGET0.widget_class === 'widget_DOC_ROW' && WIDGET0.parentWidget?.id !== undefined) {
                let attr_vueObjSheet = WIDGET.vueObj?.active_attr_vueObj(WIDGET0.doc.MDH.binding)
                if (attr_vueObjSheet?.flex?.refresh) {
                    attr_vueObjSheet.flex.refresh()
                }
            }
            if (attr.actionMenu) {
                store.executeStoreMethod(WIDGET.vueObj, attr.actionMenu, attr)
            }
            if (attr.executeWidgetMethod_afterEdit) {
                store.executeStoreMethod(WIDGET.vueObj, { 
                    command: 'executeWidgetMethod', 
                    method: attr.executeWidgetMethod_afterEdit,
                    doc_sendToBackend_exclude: ['data'],
                })
            }
        },
        doc_set(doc, binding, value, row = null, col = null, findRow = null) {
            if (binding === '' || binding === undefined) {
                for (const key in value) {
                    doc[key] = value[key];
                }
            } else {
                // bindingLast //
                let doc0 = doc
                let bindingLast = binding
                if (binding?.includes('.')) {
                    bindingLast = ''
                    let aBinding = binding?.split('.')
                    for (let iBinding in aBinding) {
                        if (bindingLast !== '') {
                            if (!(bindingLast in doc0)) {
                                doc0[bindingLast] = {}
                            }
                            doc0 = doc0[bindingLast]
                        }
                        bindingLast = aBinding[iBinding]
                    }
                }

                // set
                if (row === null && findRow === null) {
                    if (doc0[bindingLast] !== value) {
                        if (doc0[bindingLast] || !this.isEmpty(value)) {
                            doc['##modified'] = true
                            console.log(`doc['##modified'] = true`)
                        }
                        // console.log(`attr_set ${binding} ${value.title}`)
                        doc0[bindingLast] = value
                    }
                } else {
                    if (findRow !== null) {
                        row = doc0[bindingLast].findIndex(rowData => {
                            return Object.keys(findRow).every(key => rowData[key] === findRow[key])
                        })
                        if (row === -1) {
                            return false
                        }
                    }
                    if (!doc0[bindingLast][row]) {
                        doc0[bindingLast].push({})
                    }
                    if (doc0[bindingLast][row][col] !== value) {
                        if (doc0[bindingLast][row][col] || value) {
                            doc['##modified'] = true
                        }
                        doc0[bindingLast][row][col] = value
                    }
                }
            }
        },
        findMatch(query, itemsSource) {
            let bestMatch = -1;
            let bestMatchScore = 0;
            let m_query = query.split(' ')

            for (let i = 0; i < itemsSource.length; i++) {
                let score = 0;
                const name = itemsSource[i].title.toLowerCase();

                // Calculate the points for each row
                for (let j = 0; j < m_query.length; j++) {
                    const substring = m_query[j].toLowerCase();
                    if (name.includes(substring)) {
                        score++;
                    }
                }

                // If a line with a high score is found, update the best result
                if (score > bestMatchScore) {
                    bestMatch = i;
                    bestMatchScore = score;
                }
            }

            return bestMatch;
        },
        widget_attr(WIDGET, attr_id, attrs = null, parent = null) {
            const store = this;
            let attr_inContent = null;
        
            if (attrs === null) {
                if (WIDGET.groupMenu && WIDGET.groupMenu.commandPanel) {
                    attr_inContent = store.widget_attr(WIDGET, attr_id, WIDGET.groupMenu.commandPanel, WIDGET.groupMenu);
                    if (attr_inContent) {
                        return attr_inContent;
                    }
                }
                if (WIDGET.doc && WIDGET.attrs) {
                    attr_inContent = store.widget_attr(WIDGET, attr_id, WIDGET.attrs, WIDGET);
                }
                return attr_inContent;
            }
        
            for (let attr of attrs) {
                if (attr.id === attr_id) {
                    return { attr, parent }
                }
            }
        
            for (let attr of attrs) {
                if (attr.attrs) {
                    attr_inContent = store.widget_attr(WIDGET, attr_id, attr.attrs, attr);
                    if (attr_inContent) {
                        return attr_inContent;
                    }
                }
                if (attr.groupMenu) {
                    for (let keyMenu in attr.groupMenu) {
                        attr_inContent = store.widget_attr(WIDGET, attr_id, attr.groupMenu[keyMenu], attr);
                        if (attr_inContent) {
                            return attr_inContent;
                        }
                    }
                }
            }
            return null;
        },
        widget_attr_set(vueObj, set__attrs_vueObj = true) {
            const store = this,
            attr_inContent = store.widget_attr(vueObj.WIDGET, vueObj.attr_id)
            vueObj.attr = attr_inContent?.attr
            vueObj.parent_attr = attr_inContent?.parent
            if (set__attrs_vueObj) {
                vueObj.WIDGET.attrs_vueObj[vueObj.attr_id] = vueObj
            }
        },
        attr_find(attrs, value = '', key = 'binding') {
            const store = this
            if (attrs) {
                for (let attr of attrs) {
                    if (attr[key] === value && !(attr.attr_type === 'AttrButton' && key === 'binding')) {
                        return attr
                    }
                }
                for (let attr of attrs) {
                    if (attr.attrs && !(attr.binding && key === 'binding')) {
                        let attr_ = store.attr_find(attr.attrs, value, key)
                        if (attr_) {
                            return attr_
                        }
                    }
                }
            }
            return null
        },
        attr_find_by_keys(attrs, value, keys = ['name', 'binding']) {
            const store = this
            for (let key of keys) {
                let result = store.attr_find(attrs, value, key)
                if (result) {
                    return result
                }
            }
            return null
        },
        attr_findAll(attrs, value=null, key = '', foundAttrs = null) {
            const store = this
            if (foundAttrs === null) {
                foundAttrs = []
            }
            for (let attr of attrs) {
                if (key in attr && (value === null || attr[key] === value)) {
                    foundAttrs.push(attr)
                }
            }
            for (let attr of attrs) {
                if (attr.attrs) {
                    store.attr_findAll(attr.attrs, value, key, foundAttrs)
                }
            }
            return foundAttrs
        },
        attr_length(attrs) {
            const store = this;
            let length = attrs.length
            for (const attr of attrs) {
                if (attr.attrs) {
                    length += store.attr_length(attr.attrs)
                }
            }
            return length;
        },
        attr_new(attrs, actionMenu) {
            const store = this;
            let i = store.attr_length(attrs) + 1,
                default_forNewRow = actionMenu.default_forNewRow || { attr_type: 'AttrStr', title: 'field' },
                binding_new = `${default_forNewRow.title}-${i}`
            while (store.attr_find_by_keys(attrs, binding_new)) {
                binding_new = `${default_forNewRow.title}-${++i}`
            }
            return {
                binding: binding_new,
                title: store.title_toUpperCase(binding_new),
                attr_type: default_forNewRow.attr_type,
                '##modified': true,
            }
        },
        dblclick(vueObj, attr, execute = true) {
            const store = this, WIDGET = vueObj.WIDGET
            let dblclick_action = null
            // find first action
            while (!dblclick_action) {
                if (attr && attr.groupMenu) {
                    if (attr && attr.groupMenu.dblclick_action?.length) {
                        dblclick_action = attr.groupMenu.dblclick_action[0]; if (dblclick_action) break
                    }
                    dblclick_action = store.find_dblclick(vueObj, attr.groupMenu.cell_buttons); if (dblclick_action) break
                    dblclick_action = store.find_dblclick(vueObj, attr.groupMenu.actionMenu); if (dblclick_action) break
                    dblclick_action = store.find_dblclick(vueObj, attr.groupMenu.commandPanel); if (dblclick_action) break
                }
                if (vueObj.WIDGET.groupMenu) {
                    dblclick_action = store.find_dblclick(vueObj, WIDGET.groupMenu.actionMenu); if (dblclick_action) break
                    dblclick_action = store.find_dblclick(vueObj, WIDGET.groupMenu.commandPanel); if (dblclick_action) break
                }
                if (!dblclick_action) return null
            }
            if (dblclick_action && execute) {
                store.executeStoreMethod(vueObj, dblclick_action)
            }
            return dblclick_action
        },
        find_dblclick(vueObj, menuTREE) {
            const store = this
            // run first action
            if (menuTREE && menuTREE.length) {
                for (let action of menuTREE) {
                    if (action.dblClick || (action.title && action.title.includes('dblClick'))) {
                        // store.executeStoreMethod(vueObj, action)
                        return action
                    }
                }
            }
            return null
        },
        getSearchList(attrs, searchList, path) {
            // set defaults
            if (searchList == null) searchList = [];
            if (path == null) path = '';

            // add attrs and sub-attrs
            if (attrs) {
                for (var i = 0; i < attrs.length; i++) {
                    var attr = attrs[i];
                    searchList.push({
                        attr: attr,
                        path: path + attr.title,
                        keywords: attr.keywords
                    });
                    if (attr.attrs) {
                        this.getSearchList(attr.attrs, searchList, path + attr.title + ' / ');
                    }
                }
            }
            return searchList;
        },
        get_measureRow(WIDGET, measure) {
            for (let measureRow of WIDGET.doc.measures) {
                if (measureRow.measure === measure)
                    return measureRow
            }
        },
        tree_WidgetatItem(tree, node) {
            // tree.onWidgetatItem(node)
            // if (node.nodes)
            //     for (let sub_node of node.nodes) {
            //         this.tree_WidgetatItem(tree, sub_node)
            //     }
        },
        parseDateString(dateString) {
            let parseDate = wjcCore.Globalize.parseDate
            if (dateString && (dateString.length === 1 || dateString.length === 2)) {
                let today = new Date();
                let currentMonth = today.getMonth() + 1;
                let currentYear = today.getFullYear();
                dateString = currentYear + '-' + currentMonth + '-' + dateString
            }
            for (let format of this.dateFormats) {
                let date = parseDate(dateString, format) // parseDate tryParseExact
                if (date) {
                    return date
                }
            }
            return null
            // let year = date.getFullYear(),
            //     month = ('0' + (date.getMonth() + 1)).slice(-2),
            //     day = ('0' + date.getDate()).slice(-2),
            //     formattedDate = year + '-' + month + '-' + day
        },
        incrementName(name) {
            const match = name.match(/\WORKSPACE+$/)
            const num = match ? Number(match[0]) + 1 : 1
            const substr = match ? name.substring(0, match.index) : name
            return `${substr}${num}`
        },
        clear_modified(data) {
            for (let row of data||[]) {
                if ('##modified' in row) {
                    delete row['##modified']
                }
                if (row.attrs) {
                    this.clear_modified(row.attrs)
                }
            }
        },
        attr_prepare(attr, attr_ind, attr_prefics = 'rootFront', parent_binding = '') {
            if (!attr.attr_type) {
                if (!attr.title && !attr.tooltip && !attr.command) {
                    attr.attr_type = 'AttrSeparator'
                } else {
                    attr.attr_type = 'AttrButton'
                }
            }
            attr.component = attr.attr_type
            if (attr.name) {
                attr.id = attr.html_id = `${attr_prefics}_${attr.name}_${attr.attr_type.split('_').pop()}`
            } else {
                attr.id = attr.html_id = `${attr_prefics}_${attr_ind}_${attr.attr_type.split('_').pop()}`
            }
            
            this.attrs_prepare(attr.attrs || [], attr.id, attr.binding || parent_binding)
        },
        attrs_prepare(attrs, attr_prefics = 'rootFront', parent_binding = '') {
            let attr_ind = 0
            attrs.forEach((attr) => {
                if (attr.use === undefined || attr.use) {
                    this.attr_prepare(attr, attr_ind, attr_prefics, parent_binding)
                    attr_ind++
                }
            })
        },
        get_buttons_innerHTML(attrs) {
            let innerHTML = ''
            for (let attr of attrs) {
                innerHTML +=
                    `<span wj-part="btn" class="wj-input-group-btn">
                    <button class="wj-btn wj-btn-default attr-doc-button my-text-shadow p-2" id="${attr.html_id}" tabindex="-1" type="button" aria-label="${attr.tooltip}" v-wjTooltip="${attr.tooltip}">
                        <i class="${attr.cssClass}" id="${attr.html_id}"></i>
                        ${attr.title ? attr.title : ''}
                    </button>
                </span>`
            }
            return innerHTML
        },
        executeCommand_by_html_id(vueObj, attrs, html_id) {
            for (let attr of attrs) {
                if (attr.html_id === html_id) {
                    vueObj.store.executeStoreMethod(vueObj, attr)
                }
            }
        },
        get_rowGROUP(params) {
            for (let rowGROUP of params.GROUP || []) {
                if (rowGROUP.use === undefined || rowGROUP.use) {
                    return rowGROUP
                }
            }
            return {} // {binding:'object'}
        },
        get_rowWHERE(params, params2, selector_widget_doc) {
            if (!params.WHERE) {
                params.WHERE = []
            }
            // if (!params2.WHERE) {
            //     params.WHERE = []
            // }
            // params2.WHERE = params2.WHERE.filter(rowWHERE => rowWHERE.selector_widget !== selector_widget_doc.id);
            for (let rowWHERE of params.WHERE) {
                if (rowWHERE.selector_widget === selector_widget_doc.id) {
                    // params2.WHERE.push(rowWHERE)
                    return rowWHERE
                }
            }
            let rowWHERE = {
                use: true,
                leftValue: selector_widget_doc.leftValue,
                selector_widget: selector_widget_doc.id,
                DS: selector_widget_doc.DS_selector,
                condition: 'in',
                rightValue: { values: [] },
            }
            // params2.WHERE.push(rowWHERE)
            params.WHERE.push(rowWHERE)
            return rowWHERE
        },

        tooltip_html(params, header='') {
            let html = header
            for (let key in params) {
                if (html) {
                    html += '</br>'
                }
                html += `<b class='tooltip-header'>${key}:</b> <b class='tooltip-text'> ${this.formatNumber(params[key])}</b>`
            }
            return html
        },
        tooltip_attr(vueObj, tooltip2='') {
            const store = this
            let params = {}
            
            params['Field'] = vueObj.attr.binding
            
            if (vueObj.attr?.isReadOnly) {
                params['isReadOnly'] = `<input type="checkbox" checked disabled>`
            }
            
            const fieldMapping = vueObj.attr?.fieldMapping
            if (fieldMapping) {
                params[`Field mapping`] = store.attr_html(vueObj.WIDGET, vueObj.attr_id, 'fieldMapping.use', fieldMapping.use)
                params['... source value'] = fieldMapping['source-value']
                params['... from'] = store.value_AttrMulti(fieldMapping.rightValue)
            }
            
            return store.tooltip_html(params, (vueObj.attr.tooltip||'') + tooltip2)
        },
        tooltipChange(WIDGET, e, tooltip_element_id) {
            const store = this
            const checkbox = e.target,
            attr_e = store.attr_html_splitID(tooltip_element_id)

            if (attr_e.binding === 'fieldMapping.use') { // checkbox && checkbox.type === 'checkbox' && 
                const isChecked = checkbox.checked,
                vueObj = WIDGET.attrs_vueObj[attr_e.attr_id]

                vueObj.attr.fieldMapping.use = isChecked
                let leftValue = store.value_AttrMulti(vueObj.attr.fieldMapping.leftValue)
                store.doc_set(vueObj.WIDGET.doc, 'params.fieldsMapping', isChecked, null, 'use', {binding:leftValue})

                // restore source-value
                if (vueObj.attr.fieldMapping?.use && 'source-value' in vueObj.attr.fieldMapping) {
                    store.doc_set(vueObj.WIDGET.doc, vueObj.attr.binding, vueObj.attr.fieldMapping['source-value'])
                }
            } else if (attr_e.binding === 'noupdate') {
                WIDGET.doc.noupdate = checkbox.checked
                console.log(WIDGET.doc.noupdate)
            }
        },
        tooltip_attachListeners(content) {
            const store = this

            const idRegex = /id=['"]([^'"]+)['"]/g
            const matches = content.matchAll(idRegex)
            const ids = Array.from(matches, match => match[1])
        
            if (ids.length) {
                for (let tooltip_element_id of ids) {
                    const checkbox = document.getElementById(tooltip_element_id),
                    attr_e = store.attr_html_splitID(tooltip_element_id)
        
                    if (checkbox) {
                        const WIDGET = store.findWidget(attr_e.widget_id).WIDGET
                        // let vueObj = WIDGET.attrs_vueObj[attr_e.attr_id]
                        checkbox.addEventListener('change', (event) => {
                            store.tooltipChange(WIDGET, event, tooltip_element_id)
                            // vueObj.tooltipChange.call(vueObj, event, tooltip_element_id)
                        })
                    }
                }
            }
        },
        attr_html(WIDGET, attr_id, binding, value) {
            const tooltip_element_id = `${WIDGET.id}-${attr_id}-${binding}`,
            checkbox = `<input type="checkbox" id='${tooltip_element_id}' ${value ? 'checked' : ''}  @change="tooltipChange">`
            return checkbox
    
            // const tooltip_element_id = `${WIDGET.id}-${this.widget_id}-noupdate`
            // params[`Non Updatable`] = `<input type="checkbox" id='${tooltip_element_id}' ${WIDGET.doc.noupdate ? 'checked' : ''}  @change="tooltipChange">`

        },
        attr_html_splitID(id) {
            const parts = id.split('-')
            return {
                widget_id: Number(parts[0]),
                attr_id: parts[1],
                binding: parts[2],
            }
        },

        formatNumber(num) {
            if (!isNaN(num) && num !== null) {
                return num.toLocaleString()
            } else {
                return num;
            }
        },
        get_hierarchy(data, childItemsPath = 'childs') {
            const dataHierarchy = []
            const dataParents = {
                '-1': {[childItemsPath]: dataHierarchy}
            };
        
            data.forEach(row => {
                const level = row.level | 0
                delete row.level
                if (dataParents[level - 1]) {
                    if (!dataParents[level - 1][childItemsPath]) {
                        dataParents[level - 1][childItemsPath] = []
                    }
                    dataParents[level - 1][childItemsPath].push(row)
                }
                dataParents[level] = row
            });
        
            return dataHierarchy
        },
        updateDoc_ifChanged(data_to, data_from) {
            if (data_to.length !== data_from.length) {
                return false
            }
        
            for (let i = 0; i < data_from.length; i++) {
                const row = data_from[i]
                for (const key in row) {
                    if (key === 'childs' || key === 'attrs') {
                        if (!this.updateDoc_ifChanged(data_to[i][key], row[key])) {
                            return false
                        }
                    } else if (data_to[i][key] !== row[key]) {
                        data_to[i][key] = row[key]
                    }
                }
        
                for (const key in data_to[i]) {
                    if (!(key in row)) {
                        delete data_to[i][key]
                    }
                }
            }
        
            return true
        },
        updateAttrs(data_to, data_from) {
            if (data_to?.length !== data_from?.length) {
                data_to.length = 0
                data_to.push(...data_from)
            }
        
            for (let i = 0; i < data_from.length; i++) {
                const row = data_from[i]
                for (const key in row) {
                    if (key === 'attrs') {
                        if (data_to[i][key] === undefined) {
                            data_to[i][key] = row[key]
                        }
                        this.updateAttrs(data_to[i][key], row[key])
                    } else if (data_to[i][key] !== row[key]) {
                        data_to[i][key] = row[key]
                    }
                }
        
                for (const key in data_to[i]) {
                    if (!(key in row)) {
                        delete data_to[i][key]
                    }
                }
            }
        },
        widget_checkLoading(vueObj) {
            const store = this
            if (vueObj.WIDGET.loading) {
                if (typeof vueObj.WIDGET.loading === 'object') {
                    store.executeStoreMethod(vueObj, vueObj.WIDGET.loading)
                } 
                // else {
                //     store.widget_getData(vueObj);
                // }
            }
        },
        isEmpty(value) {
            return (
                value === null ||
                value === undefined ||
                value === 0 ||
                value === '' ||
                (Array.isArray(value) && value.length === 0) ||
                (typeof value === 'object' && !Array.isArray(value) && Object.keys(value).length === 0)
            )
        },
        scan_selector_values(data, rowWHERE, selector_widget_doc) {
            const store = this
            let values = []
            for (let row of data) {
                for (let icol = 1; icol < 6; icol++) {
                    let cell_doc = row[`c${icol}`];
                    if (cell_doc && cell_doc.selector) {
                        rowWHERE.use = true
                        rowWHERE.rightValue.values.push({value:cell_doc[selector_widget_doc.binding]}) // leftValue
                    }
                }
                if (row.attrs) {
                    store.scan_selector_values(row.attrs, rowWHERE, selector_widget_doc)
                }
            }
        },
        substringAfterDot(str) {
            const parts = str.split('.')
            if (parts.length < 2) {
                return ''
            }
            return parts.slice(1).join('.')
        },
        TERMINAL(WIDGET, answer, notification_frontend=null, timeStart=null, jqXHR=null) { // just_WIDGET=false
            const store = this
            let isError
            if (WIDGET) {
                if (!WIDGET.TERMINAL) {
                    WIDGET.TERMINAL = ''
                } else {
                    WIDGET.TERMINAL = WIDGET.TERMINAL.slice(-100000) + '\n' + '-'.repeat(80) // + WIDGET.TERMINAL.length
                }
            }
            let timeInfo = ''
            if (timeStart !== null) {
                const FRONTEND_duration = Math.round(performance.now() - timeStart) / 1000
                const Transfer_duration = Math.round(((FRONTEND_duration - answer.BACKEND_duration) + Number.EPSILON) * 1000) / 1000
                const packege_size = Math.round(jqXHR.responseText.length / 1024)
                timeInfo = `${answer.BACKEND_duration}s backend + ${Transfer_duration}s transfer - ${packege_size}KB `

                if (WIDGET && !WIDGET.startTime) {
                    WIDGET.startTime = performance.now()
                }
            }
            if (answer?.TERMINAL) {
                console.log(timeInfo + answer.TERMINAL)
                if (WIDGET) {
                    if (WIDGET.TERMINAL !== '') {
                        WIDGET.TERMINAL += '\n';
                    }
                    WIDGET.TERMINAL += timeInfo + answer.TERMINAL
                }
            }
            let notification = notification_frontend
            if (notification) {
                console.log(notification.text)
                if (WIDGET) {
                    if (WIDGET.TERMINAL !== '') {
                        WIDGET.TERMINAL += '\n';
                    }
                    WIDGET.TERMINAL += (notification.header||'')
                    WIDGET.TERMINAL += notification.text
                }
            }
            if (answer?.notification) {
                if (!notification || notification.status === 'info') {
                    notification = answer.notification
                }
            }
            if (notification?.text) {
                console.log(notification.text)
                if (notification.owner === 'workspace') {
                    // let workspace_doc = store.activeWORKSPACE.doc
                    // if (workspace_doc && workspace_doc.notification) {
                    //     workspace_doc.notification.header = notification.header
                    //     workspace_doc.notification.text = notification.text
                    //     workspace_doc.notification.status = notification.status
                    //     workspace_doc.notification.visible = true
                    // }
                } else if (['error', 'critical', 'info_critical'].includes(notification.status)) {
                    if (WIDGET) {
                        WIDGET.notification.header = notification.header
                        WIDGET.notification.text = notification.text
                        WIDGET.notification.status = notification.status
                        WIDGET.notification.visible = true
                    }
                }
                store.show_notification(notification)
            }
            if (WIDGET?.vueObj) {
                WIDGET.vueObj.content_Changed_attr_vueObj('TERMINAL', {fold:true})
            }
        },
        show_notification(notification) {
            const store = this

            if (!notification.text) {
                return
            } else if (store.notification.visible && store.notification.statuses[store.notification.status] > store.notification.statuses[notification.status]) {
                // Skipped because the current message is more important
                return
            }
            
            store.notification.header = notification.header
            store.notification.text = notification.text
            store.notification.status = notification.status || 'info'
            store.notification.timeout = notification.timeout||store.notification.statuses[store.notification.status]
            store.notification.closing = false

            store.restore_notification()
            store.fadeOut_notification()
        },
        restore_notification() {
            const store = this
            if (store.notification.closing) {
               return
            }
            if (store.notification.timerId) {
                clearTimeout(store.notification.timerId);
                store.notification.timerId = null
            }
            store.notification.fading = false
            store.notification.visible = true
        },
        fadeOut_notification() {
            const store = this
            store.notification.timerId = setTimeout(() => {
                store.close_notification()
            }, store.notification.timeout)
        },
        close_notification() {
            const store = this
            if (store.notification.timerId) {
                clearTimeout(store.notification.timerId);
                store.notification.timerId = null
            }

            store.notification.fading = true
            store.notification.closing = true
            store.notification.timerId = setTimeout(() => {
                store.notification.visible = false
            }, 1000)
        },
        humanDuration(seconds) {
            const hours = Math.floor(seconds / 3600)
            const minutes = Math.floor((seconds % 3600) / 60)
            
            const pad = (n) => n.toString().padStart(2, '0')
            
            const remainingSeconds = (seconds % 60).toFixed(0)
            if (hours || minutes) {
                return `${hours*60 + minutes} min ${remainingSeconds} sec`
            } else if (remainingSeconds > 2) {
                return `${remainingSeconds} sec`
            } else {
                return `${(seconds % 60).toFixed(3)} sec`
            }

            // const remainingSeconds = (seconds % 60).toFixed(3)
            // return `${pad(hours)}:${pad(minutes)}:${pad(remainingSeconds)}`
        },
        isinstance_dict(value) {
            return value !== null && typeof value === 'object' && !Array.isArray(value)
        },
        isinstance_object(value) {
            return value !== null && typeof value === 'object'
        },

        // ---------------- AttrMulti ------------------------
        check_AttrMulti(attr_col, dataItem) {
            const store = this,
            binding = attr_col.binding
            if (!(binding in dataItem)) {
                dataItem[binding] = store.new_valueMulti(attr_col)
            } else if (!store.isinstance_dict(dataItem[binding])) {
                let value = dataItem[binding]
                dataItem[binding] = store.new_valueMulti(attr_col)
                dataItem[binding].value = value
                dataItem[binding].valueStr = String(value)
            }
            return dataItem[attr_col.binding]
        },
        prepare_AttrMulti(vueObj, attr_col, dataItem, parentsList) {
            const store = this

            let V = store.check_AttrMulti(attr_col, dataItem)

            if (V?.binding) {
                V.value = store.attr_get(vueObj.WIDGET, V)
            }

            if (!V.component && V.attr_type) {
                V.component = V.attr_type
            }
            if (!V.value) {
                V.valueStr = ''
            }
            
            if (V.attr_type === 'AttrLink') {
                if (!V.valueStr_fix || !V.valueStr) {
                    const founded = store.get_valueStr(vueObj, V, V, attr_col, parentsList)
                    if (attr_col.customValueWarning) {
                        if (founded && V.is_customValueWarning) {
                            delete V.is_customValueWarning
                        } else if (!founded && V['valueStr-new']) {
                            V.valueStr = V['valueStr-new']
                            delete V.is_customValueWarning
                        } else if (!founded && !V.is_customValueWarning && V.value) {
                            V.is_customValueWarning = true
                        }
                    }
                }
            } else if (V.attr_type === 'AttrLinkOdoo') {
                const itemOdoo = V.value
                V.value = itemOdoo?.[0] || ''
                V.valueStr = itemOdoo?.[1] || ''
            } else if (V.attr_type === 'AttrMulti') {
                V.valueStr = String(V.value?.valueStr || '')
                // V.value = V.value.value || ''
                // Object.assign(V, V.value)

            } else {
                V.valueStr = String(V.value || '')
            }
        },
        get_valueStr(vueObj, V, attr, attr_col=null, parentsList=[]) { // return founded
            const store = this
            if (store.is_value_eval(V.value)) {
                V.valueStr = V.value
                return true
            }
            if (attr_col !== null && (attr_col.itemsSource || attr_col.relation_segment)) {
                if (store.get_valueStr(vueObj, V, attr_col, null, parentsList)) {
                    return true
                }
            }
            if (attr.itemsSource) {
                let itemsSource_data = attr.itemsSource
                if (parentsList.length) {
                    let foundItem_parent = itemsSource_data.find(item => item.id === parentsList[0].value)
                    itemsSource_data = foundItem_parent?.attrs||[]
                }

                const foundItem = itemsSource_data.find(item => item.id === V.value)
                V.valueStr = foundItem ? foundItem.title : V.value
                return !!foundItem
            } else if (attr.relation_segment) {
                let itemsSource = store.get_itemsSource(vueObj, {}, attr)
                V.valueStr = itemsSource.ids?.[V.value]?.title || V.value
                return !!(itemsSource.ids?.[V.value]?.title)
            }
            return false
        },
        get_valueMulti_byStr(vueObj, subTitle, attr, attr_col, previous_title, parentsList) {
            const store = this
            if (attr_col !== null) {
                let valueMulti = store.get_valueMulti_byStr(vueObj, subTitle, attr_col, null, previous_title, parentsList)
                if (valueMulti !== null) {
                    return valueMulti
                }
            }
            let itemsSource = store.get_itemsSource(vueObj, {}, attr)
            if (itemsSource.ids) {
                let foundItem,
                subTitle_lowerCase = String(subTitle).trim().toLowerCase(),
                itemsSource_data = itemsSource.data
                
                if (parentsList.length && !attr_col) {
                    let foundItem_parent = itemsSource_data.find(item => item.id === parentsList[0].value)
                    itemsSource_data = foundItem_parent?.attrs||[]
                    // itemsSource_parent = get_itemsSource_parent(itemsSource_data, parentsList)
                }
                
                if (!parentsList.length && subTitle in itemsSource.ids) {
                    foundItem = itemsSource.ids[subTitle]
                }

                foundItem = foundItem || itemsSource_data.find(item => item.title === subTitle)
                foundItem = foundItem || itemsSource_data.find(item => item.code === subTitle)
                foundItem = foundItem || itemsSource_data.find(item => item.title.trim().toLowerCase() === subTitle_lowerCase)
                foundItem = foundItem || itemsSource_data.find(item => item.title.toLowerCase().includes(subTitle_lowerCase))

                // for (let item of itemsSource_data) {
                //     if (item.title.includes(subTitle)) {
                //         foundItem = item
                //         break
                //     }
                // }

                if (foundItem) {
                    return { value:foundItem.id, valueStr:foundItem.title, attr_type:'AttrLink', relation_segment:itemsSource.segment||'' }
                }
            }

            return null
        },
        get_itemsSource_parent(itemsSource_data, parentsList, iParent=0) {
            if (parentsList.length > iParent) {
                const value = parentsList[iParent],
                foundItem = itemsSource_data.find(item => item.id === value)
                return foundItem ? get_itemsSource_parent(foundItem, parentsList, iParent+1) : []

            }
            return itemsSource_data
        },
        value_AttrMulti(V) {
            const store = this
            if (store.isinstance_dict(V)) {
                return V.value
            } else {
                return V
            }
        },
        new_valueMulti(attr_col) {
            if (attr_col.default) {
                return { ...attr_col.default }
            } else {
                return {
                    title:'',
                    value:'',
                    valueStr:'',
                    attr_type:'AttrLink',
                    component:'AttrLink',
                }
            }
        },
        is_value_eval(evaluated_value) {
            return typeof evaluated_value === 'string' && evaluated_value.trim().startsWith('=');
        },
        truncateText(text, length) {
            if (!text) {
                return ''
            } else if (text.length > length) {
                return text.substring(0, length) + '...'
            }
            return text
        },

        // ----------------------------------- non-empty-widget -----------------------------------
        set_nonEmptyWidget(WIDGET) {
            const store = this
            let attr_nonEmptyWidget = store.attr_find(WIDGET.groupMenu.commandPanel, 'non-empty-widget', 'name')
            if (attr_nonEmptyWidget) {
                for (let attr_id in WIDGET.applys_filterChips) {
                    let vueObj =  WIDGET.applys_filterChips[attr_id]
                    vueObj.apply_filterChips(null, false)
                }
                store.check_nonEmptyWidget(WIDGET)
            }
        },
        check_nonEmptyWidget(WIDGET) {
            if (this.check_nonEmptyWidget_timeout) { clearTimeout(this.check_nonEmptyWidget_timeout) }
            this.check_nonEmptyWidget_timeout = setTimeout(() => {
                this.check_nonEmptyWidget_afterTimeout(WIDGET)
                this.check_nonEmptyWidget_timeout = null
            }, 100)
        },
        check_nonEmptyWidget_afterTimeout(WIDGET) {
            const store = this
            let attr_nonEmptyWidget = store.attr_find(WIDGET.groupMenu.commandPanel, 'non-empty-widget', 'name')
            if (attr_nonEmptyWidget) {
                attr_nonEmptyWidget.hide = !Object.keys(WIDGET.applys_filterChips).length

                attr_nonEmptyWidget.countItems = 0
                if (!attr_nonEmptyWidget.hide && attr_nonEmptyWidget.active) {
                    for (let attr_id in WIDGET.applys_filterChips) {
                        let vueObj =  WIDGET.applys_filterChips[attr_id]
                        attr_nonEmptyWidget.countItems += (vueObj.count_empty_rows || 0) + (vueObj.count_empty_columns || 0) + (vueObj.count_empty_tab || 0)
                    }
                }
            }
        },

        // ----------------------------------- isMapped-widget -----------------------------------
        set_isMappedWidget(WIDGET) {
            const store = this
            let attr_isMappedWidget = store.attr_find(WIDGET.groupMenu.commandPanel, 'isMapped-widget', 'name')
            if (attr_isMappedWidget) {
                attr_isMappedWidget.check_binding = ''
                attr_isMappedWidget.mapped_binding = ''

                let WORKSPACE = store.WORKSPACES[WIDGET.workspace],
                PAGE = WORKSPACE.PAGES[WIDGET.page],
                WIDGETS = PAGE?.WIDGETS || []
    
                PAGE.WIDGET_transfer = WIDGETS.find(widget => widget.doc?.segment === 'TASKS-transform') || null;
                if (PAGE.WIDGET_transfer && PAGE.WIDGET_transfer !== WIDGET) {
                    const target_segment = PAGE.WIDGET_transfer.doc?.params?.target_segment,
                    source_segment = PAGE.WIDGET_transfer?.doc.params?.source_segment

                    let check_segment
                    if (WIDGET.widget_class === 'widget_LIST') {
                        check_segment = WIDGET.doc.params.segment
                    } else {
                        check_segment = WIDGET.doc.segment || WIDGET.doc.MDH.segment
                    }

                    if (check_segment === target_segment) {
                        attr_isMappedWidget.check_binding = 'target_field'
                        attr_isMappedWidget.mapped_binding = 'source_field'
                        attr_isMappedWidget.pi = 'pi-angle-double-right'
                        attr_isMappedWidget.pi2 = 'pi-angle-double-up'
                    } else if (check_segment === source_segment) {
                        attr_isMappedWidget.check_binding = 'source_field'
                        attr_isMappedWidget.mapped_binding = 'target_field'
                        attr_isMappedWidget.pi = 'pi-angle-double-left'
                        attr_isMappedWidget.pi2 = 'pi-angle-double-down'
                    }
                }

                store.validateFieldsToAttrs_isMappedWidget(WIDGET)
            }
        },
        validateAll_isMappedWidget(WIDGET0) {
            const store = this
            let WORKSPACE = store.WORKSPACES[WIDGET0.workspace],
            PAGE = WORKSPACE.PAGES[WIDGET0.page],
            WIDGETS = PAGE?.WIDGETS
            for (let WIDGET of WIDGETS) {
                store.validateFieldsToAttrs_isMappedWidget(WIDGET)
                store.validateAttrsToGrid_isMappedWidget(WIDGET)
            }
        },
        validateFieldsToAttrs_isMappedWidget(WIDGET) {
            const store = this
            let attr_isMappedWidget = WIDGET.attr_isMappedWidget = store.attr_find(WIDGET.groupMenu.commandPanel, 'isMapped-widget', 'name')
            if (attr_isMappedWidget) {
                attr_isMappedWidget.hide = !attr_isMappedWidget.check_binding

                attr_isMappedWidget.countItems = 0
                attr_isMappedWidget.countItems_noMapped = 0
                if (!attr_isMappedWidget.hide) {
                    let WORKSPACE = store.WORKSPACES[WIDGET.workspace],
                    PAGE = WORKSPACE.PAGES[WIDGET.page],
                    fields = PAGE.WIDGET_transfer?.doc.params?.fields,
                    attrs = WIDGET.attrs || []

                    if (WIDGET.widget_class === 'widget_LIST') {
                        let attr = store.attr_find_by_keys(WIDGET.attrs, 'dataSheet')
                        if (attr && attr.attrs && Array.isArray(attr.attrs) && attr.attrs.length) {
                            this.validateFieldsToAttrs(PAGE, PAGE.WIDGET_transfer, WIDGET, fields, attr.attrs)
                        }
                    } else {
                        this.validateFieldsToAttrs(PAGE, PAGE.WIDGET_transfer, WIDGET, fields, attrs)
                    }

                    // if (attr_isMappedWidget.active) {
                    //     for (let attr_id in WIDGET.applys_filterChips) {
                    //         let vueObj =  WIDGET.applys_filterChips[attr_id]
                    //         attr_isMappedWidget.countItems += (vueObj.count_empty_rows || 0) + (vueObj.count_empty_columns || 0) + (vueObj.count_empty_tab || 0)
                    //     }
                    // }
                }
            }
        },
        validateAttrsToGrid_isMappedWidget(WIDGET) {
            const store = this
            let attr_isMappedWidget = store.attr_find(WIDGET.groupMenu.commandPanel, 'isMapped-widget', 'name')
            if (attr_isMappedWidget && !attr_isMappedWidget.hide) {
                for (let vueObj of Object.values(WIDGET.attrs_vueObj)) {
                    if (vueObj.isMappedWidget_cols) { // AttrTabGrid
                        let hashChanges = 0
                        for (let row of vueObj.flex.rows) {
                            if (row.dataItem?.attr?.['@@node_svg']) {
                                delete row.dataItem.attr['@@node_svg']
                            }
                            if (row.dataItem?.attr?.binding) {
                                let attr = store.attr_find_by_keys(vueObj.attr.attrsTabGrid, row.dataItem.attr.binding, ['binding'])
                                if (attr && row.dataItem.attr.isMapped !== attr.isMapped) {
                                    row.dataItem.attr.isMapped = attr.isMapped
                                    row.dataItem.attr['@@node_svg'] = attr['@@node_svg']
                                    hashChanges += 1
                                }
                            }
                        }
                        if (hashChanges) {
                            vueObj.flex.refresh()
                        }
                    }
                    if (vueObj.isMappedWidget_rows) {  // AttrGrid
                        vueObj.flex.refresh()
                    }
                }
            }
        },
        validateFieldsToAttrs(PAGE, WIDGET_fields, WIDGET_attrs, fields, attrs, parent_binding='') {
            const attr_isMappedWidget = WIDGET_attrs.attr_isMappedWidget
            for (let attr of attrs) {
                if (attr.binding) {
                    attr.isMapped = 0
                    attr.mapped_binding = ''
                    attr.mapped_title = ''
                    let field = fields.find(field => field[attr_isMappedWidget.check_binding].value === attr.binding) || null;
                    if (field) {
                        attr.isMapped += 1
                        attr.mapped_binding = field[attr_isMappedWidget.mapped_binding]?.value
                        attr.mapped_title = field[attr_isMappedWidget.mapped_binding]?.value // valueStr
                        attr_isMappedWidget.countItems += 1

                        PAGE.vueObj.addEdge(field[attr_isMappedWidget.check_binding], attr, WIDGET_fields, WIDGET_attrs)
                    } else {
                        attr_isMappedWidget.countItems_noMapped += 1
                    }
                    if (attr.attrs && Array.isArray(attr.attrs) && attr.attrs.length) {
                        this.validateFieldsToAttrs(PAGE, WIDGET_fields, WIDGET_attrs, (field||{}).attrs || [], attr.attrs)
                    } 
                    if (attr.attrsTabGrid && Array.isArray(attr.attrsTabGrid) && attr.attrsTabGrid.length) {
                        this.validateFieldsToAttrs(PAGE, WIDGET_fields, WIDGET_attrs, fields, attr.attrsTabGrid)
                    }
                } else {
                    if (attr.attrs && Array.isArray(attr.attrs) && attr.attrs.length) {
                        this.validateFieldsToAttrs(PAGE, WIDGET_fields, WIDGET_attrs, fields, attr.attrs)
                    }
                    if (attr.attrsTabGrid && Array.isArray(attr.attrsTabGrid) && attr.attrsTabGrid.length) {
                        this.validateFieldsToAttrs(PAGE, WIDGET_fields, WIDGET_attrs, fields, attr.attrsTabGrid)
                    }
                }
            }
        },

        // ----------------------------------- childWidgets -----------------------------------
        check_childWidgets(WIDGET, childrenWIDGET=null) {
            const store = this

            if (childrenWIDGET) {
                if ('parentWidget' in childrenWIDGET) {
                    const parent = store.findWidget(childrenWIDGET.parentWidget.id)
                    WIDGET = parent.WIDGET
                }
            }

            if (WIDGET) {
                let attr_childWidgets = store.attr_find(WIDGET.groupMenu?.commandPanel || [], 'child-widget', 'name')
                if (!attr_childWidgets) {
                    attr_childWidgets = { 'name':'child-widget', 'hide':true, 'active':true, 'tooltip': 'Close child widgets', 'command': 'close_childrenWidgets', 'cssClass':'pi p-button-white pi-sign-out'}
                    this.attr_prepare(attr_childWidgets, 99, WIDGET.id, 'commandPanel')
                    if (!WIDGET.groupMenu) WIDGET.groupMenu = {}
                    if (!WIDGET.groupMenu.commandPanel) WIDGET.groupMenu.commandPanel = []
                    WIDGET.groupMenu.commandPanel.push(attr_childWidgets)
                }

                this.update_childrenWidgets(WIDGET)
                attr_childWidgets.countItems = WIDGET.childrenWidgets.length
                attr_childWidgets.hide = !attr_childWidgets.countItems
            }
        },
        update_childrenWidgets(WIDGET) {
            const store = this
            let WORKSPACE = store.WORKSPACES[WIDGET.workspace]
            let PAGE = WORKSPACE.PAGES[WIDGET.page]
            const WIDGETS = PAGE?.WIDGETS || []

            WIDGET.childrenWidgets = []
            for (let i = WIDGETS.length - 1; i >= 0; i--) {
                if (WIDGETS[i] !== WIDGET && WIDGETS[i].parentWidget?.id === WIDGET.id) {
                    WIDGET.childrenWidgets.push(WIDGETS[i].id)
                    // addEdgeWIDGET(WIDGET, WIDGETS[i])
                }
            }

            function addEdgeWIDGET(WIDGET1, WIDGET2) {
                const node_key1 = WIDGET1['@@node_svg'] = '' + WIDGET1.id
                PAGE.nodes_svg[node_key1] = {
                    key: node_key1,
                    widget_id: WIDGET1.id,
                    div: $('#WIDGET'+WIDGET1.id)[0],
                };

                const node_key2 = WIDGET2['@@node_svg'] = '' + WIDGET2.id
                PAGE.nodes_svg[node_key2] = {
                    key: node_key2,
                    widget_id: WIDGET2.id,
                    div: $('#WIDGET'+WIDGET2.id)[0],
                };

                const edge_key = node_key1 + ';' + node_key2;
                PAGE.edges_svg[edge_key] = {
                    edge_key,
                    source_key: node_key1,
                    target_key: node_key2,
                };
            }
        },
        close_childrenWidgets(WIDGET) {
            const store = this

            store.update_childrenWidgets(WIDGET)
            for (let widget_id of WIDGET.childrenWidgets) {
                let result = store.findWidget(widget_id, WIDGET.workspace)
                store.close_childrenWidgets(result.WIDGET)
                store.widget_close(widget_id)
            }
            store.check_childWidgets(WIDGET)
        },

        // -----------------------------------  -----------------------------------
        formatCount(value) {
            if (value) {
                if (value >= 1000000) {
                    return (value / 1000000).toFixed(0) + 'M'
                } else if (value >= 10000) {
                    return (value / 1000).toFixed(0) + 'K'
                } else if (value >= 1000) {
                    return (value / 1000).toFixed(1) + 'K'
                }
                return value
            } else {
                return ''
            }
        },

    },
})
