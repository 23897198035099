export default {
    mounted(el, binding) {
      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1
      };
  
      const callback = (entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            binding?.value(true);
          } else {
            binding?.value(false);
          }
        });
      };
  
      const observer = new IntersectionObserver(callback, options);
      el._observer = observer;
      observer.observe(el);
    },
    unmounted(el) {
      if (el._observer) {
        el._observer.disconnect();
        delete el._observer;
      }
    }
  };