<template>
    <Attr :widget_id="widget_id" :attr_id="attr_id">
        <div class="attr-gantt" ref="attr_container" v-wjTooltip="attr.tooltip" :id="'vueObj'+attr_id" >
            <label class="label-attr" v-if="attr.title && (attr.showTitle !== undefined ? attr.showTitle : true)" :for="attr.html_id">{{ attr.title }}</label>
            <wj-flex-chart 
                class="attr-gantt-1"
                ref="attr_container"
                :id="attr.html_id"
                v-visible="handleVisibility"

                :chart-type="'Bar'" 
                :itemsSource="data" 
                bindingX="indexGantt" 
                :tooltipContent="tooltipContent"
                :itemFormatter="ganttItemFormatter" 
                :rendered="ganttChartRendered"
                plotMargin="auto auto auto 180"

                :initialized="initializeChart" 
                v-wjContextMenu="'ContextWidgetMenuId'"
                @click="chart_onClick"
                >

                <wj-flex-chart-series 
                    binding="date_from,date_to">
                </wj-flex-chart-series>
                <wj-flex-chart-axis wjProperty="axisY"
                    :majorGrid="false"
                    :minorGrid="true"
                    :reversed="true"
                    :itemFormatter="formatterY">
                </wj-flex-chart-axis>
            </wj-flex-chart>
        </div>
    </Attr>
</template>

<script>
import { useMainStore } from '@/stores/mainStore'
import * as wjChart from '@mescius/wijmo.chart';

export default {
    props: [
        'widget_id',
        'attr_id',
    ],
    data: () => ({ 
        WIDGET: {},
        doc: {},
        data: [], // doc[binding]
        binding: '',
        binding_forSelector: '',
        flex: null,

        attrs_by_binding: {},
    }),
    setup() {
        const store = useMainStore();
        return { store }
    },
    created() {
        // console.log(`created ${this.attr_id}`)
        this.WIDGET = this.store.findWidget(this.widget_id).WIDGET
        this.content_Changed()
    },
    mounted() {
        this.WIDGET.attrsResize.push(this.attrResize)
    },
    methods: {
        content_Changed() {
            this.store.widget_attr_set(this)
        
            // this.text = this.store.attr_get(this.WIDGET, this.attr)
            const [doc, binding] = this.store.attr_get_link(this.WIDGET, this.attr)
            this.doc = doc, this.binding = binding

            if(this.flex) {
                this.load_data()
            }
        },
        rangeChanged: function (sender) {
            this.flex.beginUpdate();
            this.flex.axisX.min = sender.min;
            this.flex.axisX.max = sender.max;
            this.flex.endUpdate();
        },
        load_data() {
            // console.log('load_data')
            this.attrResize()
            let chart_data = this.doc[this.binding],
                attrs = this.attr.attrs

            if (!chart_data) return

            this.flex.bindingX = this.attr.chart_bindingX = this.attr.chart_bindingX||'indexGantt'
            if (!this.attr.chart_bindingX.endsWith('.title')) {
                for (let attr of this.attr.attrs) {
                    if (attr['binding'] === this.attr.chart_bindingX) {
                        this.store.get_itemsSource(this, this.doc[this.binding], attr, 'data')
                        this.flex.bindingX = `${attr['binding']}.title`
                        break
                    }
                }
            }

            chart_data = [
                { indexGantt:1, 'workCenter.title': 'Work center 3.1. cooking 1', date_from: new Date(2023, 0, 1), date_to: new Date(2023, 2, 31), parent: null, percent: 100 },
                { indexGantt:2, 'workCenter.title': 'Work center 2.2. bottling 2', date_from: new Date(2023, 3, 1), date_to: new Date(2023, 3, 30), parent: 'Task1', percent: 100 },
                { indexGantt:3, 'workCenter.title': 'Work center 1.1. package 1', date_from: new Date(2023, 4, 1), date_to: new Date(2023, 6, 31), parent: 'Task2', percent: 75 },
                { indexGantt:3, 'workCenter.title': 'Work center 1.1. package 1', date_from: new Date(2023, 8, 1), date_to: new Date(2023, 12, 31), parent: 'Task2', percent: 75 },
                // { indexGantt:4, 'workCenter.title': 'Task4', date_from: new Date(2023, 1, 1), date_to: new Date(2023, 6, 31), parent: 'Task1', percent: 33 },
                // { indexGantt:5, 'workCenter.title': 'Task7', date_from: new Date(2023, 0, 1), date_to: new Date(2023, 11, 31), parent: null, percent: 50 }
                // { indexGantt:6, 'workCenter.title': 'Task5', date_from: new Date(2023, 7, 1), date_to: new Date(2023, 8, 30), parent: 'Task3,Task4', percent: 0 },
                // { indexGantt:7, 'workCenter.title': 'Task6', date_from: new Date(2023, 9, 1), date_to: new Date(2023, 11, 31), parent: 'Task1,Task5', percent: 0 },
            ];

            this.data = chart_data
        },
        initializeChart(flex) {
            this.flex = flex;
            this.load_data()
        },
        attrResize() {
            if (!this.isVisible) return
            // console.log('attrResize')
            const elementForm = document.getElementById(`WIDGET${this.widget_id}`);
            // const elementForm = document.getElementById(`containerResize${this.attr.html_id}`)
            const elementFlex = document.getElementById(this.attr.html_id); // this.$refs.attr_container
            let elementFlex_selector = null
            if (!elementForm || !elementFlex) {
                return
            }
            if (this.attr.height) {
                elementFlex.style.height = `${this.attr.height-60}px`
            } else {
                const elementWidget_Rect = elementForm.getBoundingClientRect()
                const elementFlex_Rect = elementFlex.getBoundingClientRect()
                let elementFlex_selector_Rect = {height:0}
                if (elementFlex_selector) {
                    elementFlex_selector_Rect = elementFlex_selector.getBoundingClientRect()
                }
                if (elementFlex_Rect.height) {
                    let newHeight = Math.floor(elementWidget_Rect.bottom - elementFlex_Rect.top) - 2
                    if (newHeight>200) {
                        newHeight -= elementFlex_selector_Rect.height
                        // this.flex.axisX.labels = true
                        // elementFlex_selector.style.display = ``
                    } else {
                        // newHeight += 25
                        // this.flex.axisX.labels = false
                        // elementFlex.style.padding = `0px`
                        // elementFlex_selector.style.display = `none`
                    }
                    if (this.attr.size) {
                        newHeight = Math.round(newHeight * this.attr.size / 100)
                    }
                    if (elementFlex_Rect.height !== newHeight) {
                        console.log('attrResize' +(elementFlex_Rect.height - newHeight))
                        elementFlex.style.height = `${newHeight}px`
                    }
                }
            }
        },
        refresh() {
            // this.flex.refresh()
            this.flex.itemsSource = []
            this.flex.itemsSource = this.doc[this.binding]
        },
        onClick(actionMenu) {
            let vueObj = this.WIDGET.vueObj
            vueObj.store.executeStoreMethod(vueObj, actionMenu)
        },
        chartRendered(s, e) {
            if (this.attr.chartRendered || this.attr.drawAlarmZone) {
                var xMin = s.axisX.actualMin.valueOf(),
                    xMax = s.axisX.actualMax.valueOf(),
                    yMin = s.axisY.actualMin,
                    yMax = s.axisY.actualMax;
                // console.log(s)
                if(isNaN(xMin) && isNaN(xMax)) {
                    return;
                }
                if (this.attr.chartRendered) {
                    this.drawAlarmZone(s, e.engine, xMin, 0, xMax, yMax, 'green-zone');
                    // this.drawAlarmZone(s, e.engine, xMin, yMin, xMax, 0, 'red-zone');
                }

                if (this.attr.drawAlarmZone) {
                    for (let zone of this.attr.drawAlarmZone) {
                        // if (this.formatX) {
                        //     zone.xMin = wijmo.Globalize.parseDate(zone.xMin, this.formatX)
                        //     zone.xMax = wijmo.Globalize.parseDate(zone.xMax, this.formatX)
                        // }
                        this.drawAlarmZone(s, e.engine, zone.xMin||xMin, zone.yMin||yMin, zone.xMax||xMax, zone.yMax||yMax, zone.className)
                    }
                }
            }
        },
        drawAlarmZone(chart, engine, xMin, yMin, xMax, yMax, className) {
            if (!isNaN(xMin) && !isNaN(yMin) && !isNaN(xMax) && !isNaN(yMax)) {
                var pt1 = chart.dataToPoint(xMin, yMin);
                var pt2 = chart.dataToPoint(xMax, yMax);
                engine.drawRect(
                    Math.min(pt1.x, pt2.x), Math.min(pt1.y, pt2.y), 
                    Math.abs(pt2.x - pt1.x), Math.abs(pt2.y - pt1.y),
                    className)
            }
        },
        setSelection(x1, x2) {
            if (this.flex) {
                var xMin = this.flex.axisX.actualMin.valueOf(),
                    xMax = this.flex.axisX.actualMax.valueOf(),
                    yMin = this.flex.axisY.actualMin,
                    yMax = this.flex.axisY.actualMax;
                this.flex.refresh()
                // console.log(`x1: ${x1}, x2: ${x2}`)
                this.drawAlarmZone(this.flex, this.flex.renderEngine, x1, yMin, x2, yMax, 'chart-zone-selected')
            }
        },
        tooltipContent(hti) {
            let tooltip_attr = {
                'Work center': hti.item['workCenter.title'],
                'From': hti.item.date_from,
                'To': hti.item.date_to,
            }

            return this.store.tooltip_html(tooltip_attr)
        },
        chart_onClick() {
            if (this.dateInChart) {
                if (this.attr.setSelectionInAttr_AfterSelectionChanged) {
                    let attr_vueObj = this.WIDGET.vueObj.active_attr_vueObj(this.attr.setSelectionInAttr_AfterSelectionChanged)
                    attr_vueObj.setSelection(this.dateInChart)
                }
            }
        },


        // show the percentage complete for each task
        ganttItemFormatter(engine, ht, defaultFormatter) {
            // draw the item as usual
            defaultFormatter();

            // // show percentage done
            // let task = ht.item;
            // //
            // if (wijmo.isNumber(task.percent) && task.percent > 0) {
            //     let pct = wijmo.clamp(task.percent, 0, 100) / 100,
            //         rc = this.$_getTaskRect(ht.series.chart, task).inflate(-8, -8);
            //     //
            //     engine.fill = pct === 1 ? 'green' : 'gold';//engine.stroke;
            //     engine.drawRect(rc.left, rc.top, rc.width * pct, rc.height);
            // }
        },
        // show the task dependencies
        ganttChartRendered(chart, e) {
            // chartRendered(...)

            // let tasks = chart.collectionView.items;
            // //
            // tasks.forEach(task => { // for each task
            //     let parents = this.$_getTaskParents(task, tasks); // get the parent tasks
            //     //
            //     parents.forEach(parent => { // for each parent
            //         this.$_drawConnectingLine(e.engine, chart, task, parent); // draw connector
            //     });
            // });
        },
        $_drawConnectingLine(engine, chart, task, parent) {
            let rc1 = this.$_getTaskRect(chart, parent),   // parent rect
                rc2 = this.$_getTaskRect(chart, task),     // task rect
                x1 = rc1.left + rc1.width / 2,      // parent x center
                x2 = rc2.left,                      // task left
                y1 = rc1.bottom,                    // parent bottom
                y2 = rc2.top + rc2.height / 2;      // task y center
            //
            // draw connecting line
            let xs = [x1, x1, x2],
                ys = [y1, y2, y2];
            //
            engine.drawLines(xs, ys, 'connector', {
                stroke: 'black'
            });
            //
            // draw arrow at the end
            let sz = 5;
            //
            xs = [x2 - 2 * sz, x2, x2 - 2 * sz];
            ys = [y2 - sz, y2, y2 + sz];
            //
            engine.drawPolygon(xs, ys, 'arrow', {
                fill: 'black'
            });
        },
        $_getTaskRect(chart, task) {
            let x1 = chart.axisX.convert(task.start.valueOf()),
                x2 = chart.axisX.convert(task.end.valueOf()),
                index = chart.collectionView.items.indexOf(task),
                y1 = chart.axisY.convert(index - .35),
                y2 = chart.axisY.convert(index + .35);
            //
            return new Rect(x1, y1, x2 - x1 + 1, y2 - y1 + 1);
        },
        $_getTaskParents(task, tasks) {
            let parents = [];
            //
            if (task.parent) {
                task.parent.split(',').forEach(name => {
                    for (let i = 0; i < tasks.length; i++) {
                        if (tasks[i].name === name) {
                            parents.push(tasks[i]);
                            break;
                        }
                    }
                });
            }
            //
            return parents;
        },
        // replace item index with item name in Y axis
        formatterY(engine, label) {
            for (var i = 0; i < this.data.length; i++) {
                var item = this.data[i];
                if (item.indexGantt === label.val) {
                    label.text = item['workCenter.title'];
                    break;
                }
            }
            return label;
        },
        handleVisibility(isVisible) {
            this.isVisible = isVisible
            if (isVisible) {
                if (this.attr.getSelectionWorkspace_date){
                    this.WIDGET.getSelectionWorkspace_date = this.getSelectionWorkspace_date
                }
            }
        },
    },
    watch: {
        'store.itemsSource_change_index'(newVal, oldVal) {
            if (!this.attr.chart_bindingX.endsWith('.title')) {
                for (let attr of this.attr.attrs) {
                    if (attr['binding'] === this.attr.chart_bindingX) {
                        if (attr.relation_segment && attr.relation_segment==this.store.itemsSource.change_type) {
                            console.log(`watch ${this.store.itemsSource.change_type} ${newVal}`)
                            this.store.get_itemsSource(this, this.doc[this.binding], attr, 'data')
                            this.flex.bindingX = `${attr['binding']}.title`
                            this.refresh()
                            break
                        }
                    }
                }
            }
        }
    },
}
</script>

<style scoped>
.attr-gantt {
    width:-webkit-fill-available !important;
    display: flex;
    flex-direction: column;
    height: 100%;
    /* padding-bottom: 3px; */
    /* position: relative; */
}
.chart-selector.wj-flexchart {
    /* position: absolute; */
    bottom: 0px;
    height: 60px;
}
.buttons-chart{
    transition: all 250ms;
    opacity: 0;
    display: flex;
    align-items: center;

    position: absolute;
    padding: 24px;
    /* top: 24px; */
    right: 0px;
    color: lightslategray;
    /* visibility: hidden; */
    text-shadow:
            /* many shadows blur out the area around the text */
            0 0 1px white,
            0 0 2px white,
            0 0 3px white,
            0 0 4px white,
            0 0 5px white,
            0 0 6px white,
            0 0 7px white,
            0 0 8px white,
            0 0 9px white,
            0 0 10px white
}
.attr-gantt:hover .buttons-chart {
    opacity: 1;
}
</style>